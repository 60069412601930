import {BpsParametersExtended, Device, NominationExtended, NominationProfile} from '../api/generated'
import {NominationFull} from '../types/nomination'
import {NominationExtendedBase} from '../types/nominationExtended'
import {parseToFixedNumber, transformToKwNumber, transformToMwNumber} from './common'
import {NOMINAITON_TECH_PARAMS_COLORS, NOMINATION_VALUE_TYPE} from './constants'

export const nominationValueTextFieldBorderColor = (
  nominationType: string,
  devicesForBps: undefined | Device[],
  selectedDevices: number[] | undefined,
  bpsParameters: BpsParametersExtended | undefined,
  editedNomination: NominationExtendedBase | NominationProfile,
) => {
  const PRegMin = devicesForBps
    ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
    .reduce(function (prev, current) {
      return prev + current?.p_reg_min_mw
    }, 0)

  const PRegMax = devicesForBps
    ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
    .reduce(function (prev, current) {
      return prev + current?.p_reg_max_mw
    }, 0)

  const regMax = PRegMax === undefined ? 0 : PRegMax
  const regMin = PRegMin === undefined ? 0 : PRegMin
  const tvsMw = bpsParameters?.tvs_mw ? bpsParameters.tvs_mw : 0
  const ovsMwValue = bpsParameters?.ovs_mw ? bpsParameters.ovs_mw : 0
  const tvsVarianceMw = bpsParameters?.tvs_variance_mw ? bpsParameters.tvs_variance_mw : 0

  const valueOom = editedNomination.value_oom ? transformToMwNumber(editedNomination.value_oom) : 0
  const valuePps = editedNomination.value_pps ? transformToMwNumber(editedNomination.value_pps) : 0

  let nominationValue
  let ovsMw

  if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
    nominationValue = valueOom
    ovsMw = ovsMwValue
  } else if (nominationType === NOMINATION_VALUE_TYPE.Pps) {
    nominationValue = valuePps
    ovsMw = 0
  } else {
    nominationValue = 0
    ovsMw = 0
  }

  if (
    parseToFixedNumber(regMin - (tvsMw - 0.5 * tvsVarianceMw) - ovsMw, 3) <= nominationValue &&
    nominationValue <= parseToFixedNumber(regMax - (tvsMw + 0.5 * tvsVarianceMw) - ovsMw, 3)
  ) {
    return {
      color: NOMINAITON_TECH_PARAMS_COLORS.Green,
      isOutOfRange: false,
    }
  } else if (
    parseToFixedNumber(regMin - tvsMw - ovsMw, 3) <= nominationValue &&
    nominationValue <= parseToFixedNumber(regMax - tvsMw - ovsMw, 3)
  ) {
    return {
      color: NOMINAITON_TECH_PARAMS_COLORS.Orange,
      isOutOfRange: false,
    }
  } else if (
    parseToFixedNumber(regMin - (tvsMw + 0.5 * tvsVarianceMw) - ovsMw, 3) <= nominationValue &&
    nominationValue <= parseToFixedNumber(regMax - (tvsMw - 0.5 * tvsVarianceMw) - ovsMw, 3)
  ) {
    return {
      color: NOMINAITON_TECH_PARAMS_COLORS.Red,
      isOutOfRange: false,
    }
  } else {
    return {
      color: NOMINAITON_TECH_PARAMS_COLORS.OutOfRange,
      isOutOfRange: true,
    }
  }
}

export const nominationTechParamBands = (
  nominationTechParamColor: string,
  devicesForBps: undefined | Device[],
  selectedDevices: number[] | undefined,
  tvsMwValue: number | undefined,
  ovsMwValue: number | undefined,
  tvsVarianceMwValue: number | undefined,
) => {
  const pRegMin = devicesForBps
    ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
    .reduce(function (prev, current) {
      return prev + current?.p_reg_min_mw
    }, 0)

  const pRegMax = devicesForBps
    ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
    .reduce(function (prev, current) {
      return prev + current?.p_reg_max_mw
    }, 0)

  const regMax = pRegMax === undefined ? 0 : pRegMax
  const regMin = pRegMin === undefined ? 0 : pRegMin
  const tvsMw = tvsMwValue ? tvsMwValue : 0
  const ovsMw = ovsMwValue ? ovsMwValue : 0
  const tvsVarianceMw = tvsVarianceMwValue ? tvsVarianceMwValue : 0

  if (nominationTechParamColor === NOMINAITON_TECH_PARAMS_COLORS.Green) {
    const greenLeft = regMin - (tvsMw - 0.5 * tvsVarianceMw) - ovsMw
    const greenRight = regMax - (tvsMw + 0.5 * tvsVarianceMw) - ovsMw

    return {
      min: transformToKwNumber(greenLeft < 0 ? 0 : greenLeft),
      max: transformToKwNumber(greenRight < 0 ? 0 : greenRight),
    }
  } else if (nominationTechParamColor === NOMINAITON_TECH_PARAMS_COLORS.Orange) {
    const orangeLeft = regMin - tvsMw - ovsMw
    const orangeRight = regMax - tvsMw - ovsMw

    return {
      min: transformToKwNumber(orangeLeft < 0 ? 0 : orangeLeft),
      max: transformToKwNumber(orangeRight < 0 ? 0 : orangeRight),
    }
  } else if (nominationTechParamColor === NOMINAITON_TECH_PARAMS_COLORS.Red) {
    const redLeft = regMin - (tvsMw + 0.5 * tvsVarianceMw) - ovsMw
    const redRight = regMax - (tvsMw - 0.5 * tvsVarianceMw) - ovsMw

    return {
      min: transformToKwNumber(redLeft < 0 ? 0 : redLeft),
      max: transformToKwNumber(redRight < 0 ? 0 : redRight),
    }
  }
}

const findMinMaxNominationValueFromNominations = (
  existingNominations: NominationExtended[] | undefined,
  nominationType: string,
) => {
  if (!existingNominations || existingNominations.length === 0) {
    return {min: undefined, max: undefined, maxNomination: undefined}
  } else {
    if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
      const oomMin = existingNominations.reduce((min, current) =>
        min && (min.value_oom ? min.value_oom : 0) < (current.value_oom ? current.value_oom : 0) ? min : current,
      ).value_oom
      const oomMax = existingNominations.reduce((max, current) =>
        max && (max.value_oom ? max.value_oom : 0) > (current.value_oom ? current.value_oom : 0) ? max : current,
      ).value_oom
      const oomMaxNomination = existingNominations.reduce((max, current) => {
        return (current.value_oom ?? 0) > (max.value_oom ?? 0) ? current : max
      }, existingNominations[0])

      return {min: transformToKwNumber(oomMin), max: transformToKwNumber(oomMax), maxNomination: oomMaxNomination}
    } else if (nominationType === NOMINATION_VALUE_TYPE.Pps) {
      const ppsMin = existingNominations.reduce((min, current) =>
        min && (min.value_pps ? min.value_pps : 0) < (current.value_pps ? current.value_pps : 0) ? min : current,
      ).value_pps
      const ppsMax = existingNominations.reduce((max, current) =>
        max && (max.value_pps ? max.value_pps : 0) > (current.value_pps ? current.value_pps : 0) ? max : current,
      ).value_pps
      const ppsMaxNomination = existingNominations.reduce((max, current) => {
        return (current.value_pps ?? 0) > (max.value_pps ?? 0) ? current : max
      }, existingNominations[0])

      return {min: transformToKwNumber(ppsMin), max: transformToKwNumber(ppsMax), maxNomination: ppsMaxNomination}
    }
  }
}

const findMinMaxNominationValueFromNominationProfiles = (
  existingNominations: NominationProfile[] | undefined,
  nominationType: string,
) => {
  if (!existingNominations || existingNominations.length === 0) {
    return {min: undefined, max: undefined, maxNomination: undefined}
  } else {
    if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
      const oomMin = existingNominations.reduce((min, current) =>
        min && (min.value_oom ? min.value_oom : 0) < (current.value_oom ? current.value_oom : 0) ? min : current,
      ).value_oom
      const oomMax = existingNominations.reduce((max, current) =>
        max && (max.value_oom ? max.value_oom : 0) > (current.value_oom ? current.value_oom : 0) ? max : current,
      ).value_oom
      const oomMaxNomination = existingNominations.reduce((max, current) =>
        max && (max.value_oom ? max.value_oom : 0) < (current.value_oom ? current.value_oom : 0) ? max : current,
      )

      return {min: transformToKwNumber(oomMin), max: transformToKwNumber(oomMax), maxNomination: oomMaxNomination}
    } else if (nominationType === NOMINATION_VALUE_TYPE.Pps) {
      const ppsMin = existingNominations.reduce((min, current) =>
        min && (min.value_pps ? min.value_pps : 0) < (current.value_pps ? current.value_pps : 0) ? min : current,
      ).value_pps
      const ppsMax = existingNominations.reduce((max, current) =>
        max && (max.value_pps ? max.value_pps : 0) > (current.value_pps ? current.value_pps : 0) ? max : current,
      ).value_pps
      const ppsMaxNomination = existingNominations.reduce((max, current) =>
        max && (max.value_pps ? max.value_pps : 0) < (current.value_pps ? current.value_pps : 0) ? max : current,
      )

      return {min: transformToKwNumber(ppsMin), max: transformToKwNumber(ppsMax), maxNomination: ppsMaxNomination}
    }
  }
}

export const getMinMaxOfNominations = (
  nominations: NominationFull[] | NominationProfile[] | undefined,
  nominationType: string,
  isProfileEdit: boolean,
) => {
  if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
    return isProfileEdit
      ? findMinMaxNominationValueFromNominationProfiles(nominations as NominationProfile[], NOMINATION_VALUE_TYPE.Oom)
      : findMinMaxNominationValueFromNominations(nominations as NominationFull[], NOMINATION_VALUE_TYPE.Oom)
  } else {
    return isProfileEdit
      ? findMinMaxNominationValueFromNominationProfiles(nominations as NominationProfile[], NOMINATION_VALUE_TYPE.Pps)
      : findMinMaxNominationValueFromNominations(nominations as NominationFull[], NOMINATION_VALUE_TYPE.Pps)
  }
}

// max of red band interval
export const calculateMaxSliderValue = (
  selectedDevicesArg: number[],
  nominationType: string,
  bpsParameters: BpsParametersExtended | undefined,
  devicesForBps: Device[] | undefined,
) => {
  let maxValue: number | undefined
  const tvsMw = bpsParameters?.tvs_mw ? bpsParameters.tvs_mw : 0
  const ovsMw = bpsParameters?.ovs_mw ? bpsParameters.ovs_mw : 0
  const tvsVarianceMw = bpsParameters?.tvs_variance_mw ? bpsParameters.tvs_variance_mw : 0

  const maxRegForSelectedDevices = devicesForBps
    ?.filter((item) => selectedDevicesArg?.indexOf(item.id) !== -1)
    .reduce(function (prev, current) {
      return prev + current?.p_reg_max_mw
    }, 0)

  if (nominationType === NOMINATION_VALUE_TYPE.Pps) {
    maxValue = transformToKwNumber(
      (maxRegForSelectedDevices ? maxRegForSelectedDevices : 0) - (tvsMw - 0.5 * tvsVarianceMw),
    )
  } else if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
    maxValue = transformToKwNumber(
      (maxRegForSelectedDevices ? maxRegForSelectedDevices : 0) - (tvsMw - 0.5 * tvsVarianceMw) - ovsMw,
    )
  }

  return !maxValue || maxValue < 0 ? 0 : maxValue
}

// min of red band interval
export const calculateMinSliderValue = (
  selectedDevicesArg: number[],
  nominationType: string,
  bpsParameters: BpsParametersExtended | undefined,
  devicesForBps: Device[] | undefined,
) => {
  let minValue: number | undefined
  const tvsMw = bpsParameters?.tvs_mw ? bpsParameters.tvs_mw : 0
  const ovsMw = bpsParameters?.ovs_mw ? bpsParameters.ovs_mw : 0
  const tvsVarianceMw = bpsParameters?.tvs_variance_mw ? bpsParameters.tvs_variance_mw : 0

  const minRegForSelectedDevices = devicesForBps
    ?.filter((item) => selectedDevicesArg?.indexOf(item.id) !== -1)
    .reduce(function (prev, current) {
      return prev + current?.p_reg_min_mw
    }, 0)

  if (nominationType === NOMINATION_VALUE_TYPE.Pps) {
    minValue = transformToKwNumber(
      (minRegForSelectedDevices ? minRegForSelectedDevices : 0) - (tvsMw + 0.5 * tvsVarianceMw),
    )
  } else if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
    minValue = transformToKwNumber(
      (minRegForSelectedDevices ? minRegForSelectedDevices : 0) - (tvsMw + 0.5 * tvsVarianceMw) - ovsMw,
    )
  }

  return !minValue || minValue < 0 ? 0 : minValue
}

export const getOvsKW = (bpsParameters: BpsParametersExtended | undefined) => {
  const ovsKw = transformToKwNumber(!bpsParameters?.ovs_mw ? 0 : bpsParameters?.ovs_mw)
  return ovsKw !== undefined ? ovsKw : 0
}

export const getDefaultNominationValue = (
  nominationType: string,
  bpsParameters: BpsParametersExtended | undefined,
  devicesForBps: Device[],
  selectedDevices: number[],
  existingNominations: NominationFull[] | NominationProfile[] | undefined,
  isProfileEdit: boolean,
) => {
  const maxOrangeParamBand = nominationTechParamBands(
    NOMINAITON_TECH_PARAMS_COLORS.Orange,
    devicesForBps,
    selectedDevices,
    bpsParameters?.tvs_mw,
    bpsParameters?.ovs_mw,
    bpsParameters?.tvs_variance_mw,
  )?.max

  if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
    const oomMax = getMinMaxOfNominations(existingNominations, NOMINATION_VALUE_TYPE.Oom, isProfileEdit)?.max
    return oomMax ? oomMax : maxOrangeParamBand
  } else if (nominationType === NOMINATION_VALUE_TYPE.Pps) {
    const ppsMax = getMinMaxOfNominations(existingNominations, NOMINATION_VALUE_TYPE.Pps, isProfileEdit)?.max
    return ppsMax ? ppsMax : Number(maxOrangeParamBand) + getOvsKW(bpsParameters)
  }
}

import {NominationProfile} from '../api/generated'
import {formatTimeToDate} from '../utils/format'
import {NominationProfileWithDevices} from '../api/generated/model/nomination-profile-with-devices'

export const checkOverlap = (x: NominationProfile, profileNomination: NominationProfile) => {
  if (!profileNomination.time_from || !profileNomination.time_to) {
    return false
  }
  if (!x.time_to || !x.time_from) {
    return false
  }

  const timeToMidnight = x.time_to == '00:00:00' ? 1 : 0
  const noOverlap =
    formatTimeToDate(x.time_to).add(timeToMidnight, 'day').toISOString() <= profileNomination.time_from ||
    formatTimeToDate(x.time_from).toISOString() >= profileNomination.time_to

  return !noOverlap
}

export const checkOverlapWithDevices = (
  x: NominationProfileWithDevices,
  profileNomination: NominationProfileWithDevices,
) => {
  if (!profileNomination.time_from || !profileNomination.time_to) {
    return false
  }

  if (!x.time_to || !x.time_from) {
    return false
  }

  const timeToMidnight = x.time_to == '00:00:00' ? 1 : 0
  const noOverlap =
    formatTimeToDate(x.time_to).add(timeToMidnight, 'day').toISOString() <= profileNomination.time_from ||
    formatTimeToDate(x.time_from).toISOString() >= profileNomination.time_to

  return !noOverlap
}

export const merge = (profileNominations: NominationProfile[]) => {
  const result: NominationProfile[] = []

  let wip: NominationProfile | undefined = undefined

  profileNominations.forEach((x) => {
    if (wip == null) {
      wip = x
      return
    }

    const canMerge =
      wip.time_to === x.time_from &&
      wip.profile_id === x.profile_id &&
      wip.value_oom === x.value_oom &&
      wip.value_pps === x.value_pps &&
      (wip.note || undefined) === (x.note || undefined)
    if (canMerge) {
      wip = {...wip, time_to: x.time_to}
      // rest of x is ignored
    } else {
      result.push(wip)
      wip = x
    }
  })

  if (wip != null) {
    result.push(wip)
    wip = undefined
  }
  return result
}

export const mergeNominationsWithDevices = (profileNominations: NominationProfileWithDevices[]) => {
  const result: NominationProfileWithDevices[] = []

  let wip: NominationProfileWithDevices | undefined = undefined

  profileNominations.forEach((x) => {
    if (wip == null) {
      wip = x
      return
    }

    const canMerge =
      wip.time_to === x.time_from &&
      wip.profile_id === x.profile_id &&
      wip.value_oom === x.value_oom &&
      wip.value_pps === x.value_pps &&
      (wip.note || undefined) === (x.note || undefined)
    if (canMerge) {
      wip = {...wip, time_to: x.time_to}
      // rest of x is ignored
    } else {
      result.push(wip)
      wip = x
    }
  })

  if (wip != null) {
    result.push(wip)
    wip = undefined
  }
  return result
}

/* tslint:disable */
/* eslint-disable */
/**
 * BPS Group app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetBpsGroupReportResponse } from '../model';
// @ts-ignore
import { GetReportSnapshotResponse } from '../model';
// @ts-ignore
import { SendReportCsvExportResponse } from '../model';
/**
 * ReportControllerApi - axios parameter creator
 * @export
 */
export const ReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsGroupReport: async (dateFrom: string, dateTo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getBpsGroupReport', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getBpsGroupReport', 'dateTo', dateTo)
            const localVarPath = `/api/report/group-imbalance-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationSnapshotReportCsv: async (dateFrom: string, dateTo: string, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getNominationSnapshotReportCsv', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getNominationSnapshotReportCsv', 'dateTo', dateTo)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getNominationSnapshotReportCsv', 'userId', userId)
            const localVarPath = `/api/report/nomination-snapshot/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportCsvExport: async (dateFrom: string, dateTo: string, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getReportCsvExport', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getReportCsvExport', 'dateTo', dateTo)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getReportCsvExport', 'userId', userId)
            const localVarPath = `/api/report/okte-supply/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportSnapshot: async (dateFrom: string, dateTo: string, bpsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getReportSnapshot', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getReportSnapshot', 'dateTo', dateTo)
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getReportSnapshot', 'bpsId', bpsId)
            const localVarPath = `/api/report/snapshot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateReport: async (date: string, bpsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('recalculateReport', 'date', date)
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('recalculateReport', 'bpsId', bpsId)
            const localVarPath = `/api/report/recalculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportControllerApi - functional programming interface
 * @export
 */
export const ReportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBpsGroupReport(dateFrom: string, dateTo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBpsGroupReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBpsGroupReport(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNominationSnapshotReportCsv(dateFrom: string, dateTo: string, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendReportCsvExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNominationSnapshotReportCsv(dateFrom, dateTo, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportCsvExport(dateFrom: string, dateTo: string, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendReportCsvExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportCsvExport(dateFrom, dateTo, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportSnapshot(dateFrom: string, dateTo: string, bpsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReportSnapshotResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportSnapshot(dateFrom, dateTo, bpsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recalculateReport(date: string, bpsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReportSnapshotResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recalculateReport(date, bpsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportControllerApi - factory interface
 * @export
 */
export const ReportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpsGroupReport(dateFrom: string, dateTo: string, options?: any): AxiosPromise<GetBpsGroupReportResponse> {
            return localVarFp.getBpsGroupReport(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationSnapshotReportCsv(dateFrom: string, dateTo: string, userId: number, options?: any): AxiosPromise<SendReportCsvExportResponse> {
            return localVarFp.getNominationSnapshotReportCsv(dateFrom, dateTo, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportCsvExport(dateFrom: string, dateTo: string, userId: number, options?: any): AxiosPromise<SendReportCsvExportResponse> {
            return localVarFp.getReportCsvExport(dateFrom, dateTo, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportSnapshot(dateFrom: string, dateTo: string, bpsId: number, options?: any): AxiosPromise<GetReportSnapshotResponse> {
            return localVarFp.getReportSnapshot(dateFrom, dateTo, bpsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateReport(date: string, bpsId: number, options?: any): AxiosPromise<GetReportSnapshotResponse> {
            return localVarFp.recalculateReport(date, bpsId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getBpsGroupReport operation in ReportControllerApi.
 * @export
 * @interface ReportControllerApiGetBpsGroupReportRequest
 */
export interface ReportControllerApiGetBpsGroupReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportControllerApiGetBpsGroupReport
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ReportControllerApiGetBpsGroupReport
     */
    readonly dateTo: string
}

/**
 * Request parameters for getNominationSnapshotReportCsv operation in ReportControllerApi.
 * @export
 * @interface ReportControllerApiGetNominationSnapshotReportCsvRequest
 */
export interface ReportControllerApiGetNominationSnapshotReportCsvRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportControllerApiGetNominationSnapshotReportCsv
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ReportControllerApiGetNominationSnapshotReportCsv
     */
    readonly dateTo: string

    /**
     * 
     * @type {number}
     * @memberof ReportControllerApiGetNominationSnapshotReportCsv
     */
    readonly userId: number
}

/**
 * Request parameters for getReportCsvExport operation in ReportControllerApi.
 * @export
 * @interface ReportControllerApiGetReportCsvExportRequest
 */
export interface ReportControllerApiGetReportCsvExportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportControllerApiGetReportCsvExport
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ReportControllerApiGetReportCsvExport
     */
    readonly dateTo: string

    /**
     * 
     * @type {number}
     * @memberof ReportControllerApiGetReportCsvExport
     */
    readonly userId: number
}

/**
 * Request parameters for getReportSnapshot operation in ReportControllerApi.
 * @export
 * @interface ReportControllerApiGetReportSnapshotRequest
 */
export interface ReportControllerApiGetReportSnapshotRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportControllerApiGetReportSnapshot
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ReportControllerApiGetReportSnapshot
     */
    readonly dateTo: string

    /**
     * 
     * @type {number}
     * @memberof ReportControllerApiGetReportSnapshot
     */
    readonly bpsId: number
}

/**
 * Request parameters for recalculateReport operation in ReportControllerApi.
 * @export
 * @interface ReportControllerApiRecalculateReportRequest
 */
export interface ReportControllerApiRecalculateReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportControllerApiRecalculateReport
     */
    readonly date: string

    /**
     * 
     * @type {number}
     * @memberof ReportControllerApiRecalculateReport
     */
    readonly bpsId: number
}

/**
 * ReportControllerApi - object-oriented interface
 * @export
 * @class ReportControllerApi
 * @extends {BaseAPI}
 */
export class ReportControllerApi extends BaseAPI {
    /**
     * 
     * @param {ReportControllerApiGetBpsGroupReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public getBpsGroupReport(requestParameters: ReportControllerApiGetBpsGroupReportRequest, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).getBpsGroupReport(requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportControllerApiGetNominationSnapshotReportCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public getNominationSnapshotReportCsv(requestParameters: ReportControllerApiGetNominationSnapshotReportCsvRequest, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).getNominationSnapshotReportCsv(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportControllerApiGetReportCsvExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public getReportCsvExport(requestParameters: ReportControllerApiGetReportCsvExportRequest, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).getReportCsvExport(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportControllerApiGetReportSnapshotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public getReportSnapshot(requestParameters: ReportControllerApiGetReportSnapshotRequest, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).getReportSnapshot(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.bpsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportControllerApiRecalculateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public recalculateReport(requestParameters: ReportControllerApiRecalculateReportRequest, options?: AxiosRequestConfig) {
        return ReportControllerApiFp(this.configuration).recalculateReport(requestParameters.date, requestParameters.bpsId, options).then((request) => request(this.axios, this.basePath));
    }
}

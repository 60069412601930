import {Moving} from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import {Alert, Box, Button, Container, Link, Stack, Tab, Tabs} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useBucketStore, useBucketStoreContext} from '../../utils/BucketStoreContext'
import {AddNominationDialog} from '../AddNominationDialog'
import {CopyDayDialog} from '../CopyDayDialog'
import {UseProfileDialog} from '../UseProfileDialog'
import {BpsSelector} from './BpsSelector'
import {DatePicker} from './DatePicker'
import {GraphView} from './GraphView'
import {GroupNominationsTable} from './GroupNominationsTable'
import {NominationsTable} from './NominationsTable'
import {ProfilesTable} from './ProfilesTable'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {BpsDetail} from './BPSDetail/BpsDetail'
import {useNavigate} from 'react-router'
import {absolutePath} from '../../navigation/utils'
import {isBpsEditable, isUserGroupManagerOrGroupOperator} from '../../utils/validateUserRolesAndBps'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {
  getNominationFailure,
  getNominationsV2,
  getProductionStatusEnum,
  revokeNominationFailure,
} from '../../api/nominationsApi'
import {OperationView} from './OperationView'
import {formatDateTimeToNoZone, formatTime} from '../../utils/format'
import {NominationExtendedFull} from '../../types/nominationExtended'
import {RevokeNominationFailureDialog} from '../RevokeNominationFailureDialog'
import {UnsuccessfulFailureRevokeDialog} from '../UnsuccessfulFailureRevokeDialog'
import {NominationExtended} from '../../api/generated'
import {getCurrentAnnouncements} from '../../api/commonApi'

export const Dashboard: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<
    'graph-view' | 'table-view' | 'profiles-view' | 'group-view' | 'bps-detail-view' | 'operation-view'
  >('table-view')

  const {data: selectedDate, setData: setSelectedDate} = useBucketStore('selectedDate')
  const {data: nominations, setData: setNominations} = useBucketStore('nominationsExtended')
  const {setData: setBpsNominationTableOpen} = useBucketStore('bpsNominationTableOpen')
  const {setData: setProductionStatusEnum} = useBucketStore('productionStatusEnum')
  const {data: announcements, setData: setAnnouncements} = useBucketStore('announcements')
  const {data: userInfo} = useBucketStore('userInfo')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')

  const {run: runGetProductionStatusEnum} = useAsyncMethodWithErrorHandling(getProductionStatusEnum)
  const {run: runGetNominationFailure} = useAsyncMethodWithErrorHandling(getNominationFailure)
  const {run: runGetNominationsV2} = useAsyncMethodWithErrorHandling(getNominationsV2)
  const {run: runGetCurrentAnnouncements} = useAsyncMethodWithErrorHandling(getCurrentAnnouncements)

  const [isAddNominationDialogOpen, setIsAddNominationDialogOpen] = useState<boolean>(false)
  const [isCopyDayDialogOpen, setIsCopyDayDialogOpen] = useState<boolean>(false)
  const [isUseProfileDialogOpen, setIsUseProfileDialogOpen] = useState<boolean>(false)

  const [nominationFailures, setNominationFailures] = useState<NominationExtendedFull[]>([])
  const [isRevokeNominationFailureDialogOpen, setIsRevokeNominationFailureDialogOpen] = useState<boolean>(false)
  const [isUnsuccessfulFailureRevokeDialogOpen, setIsUnsuccessfulFailureRevokeDialogOpen] = useState<boolean>(false)
  const [isNominationFailureNow, setIsNominationFailureNow] = useState<boolean>(false)

  const navigation = useNavigate()
  const {clearAllBuckets} = useBucketStoreContext()

  const handleCloseCopyDayDialog = () => {
    setIsCopyDayDialogOpen(false)
  }

  const handleCloseUseProfileDialogOpen = () => {
    setIsUseProfileDialogOpen(false)
  }

  const logout = () => {
    clearAllBuckets()
    sessionStorage.clear()
    navigation(absolutePath('login'))
  }

  const goToDispatch = () => {
    if (isUserGroupManagerOrGroupOperator(userInfo)) {
      navigation(absolutePath('dispatch'))
    }
  }

  const openNominationTableForBpsAndPeriod = () => {
    setSelectedTab('table-view')
    setBpsNominationTableOpen(true)
  }

  const isCurrentNominationFailure = (nomFailures: NominationExtended[] | undefined) => {
    if (nomFailures) {
      for (const nomFailure of nomFailures) {
        if (
          dayjs(nomFailure.time_from).isBefore(dayjs()) &&
          dayjs(nomFailure.time_to).isAfter(dayjs()) &&
          nomFailure.is_failure === true
        ) {
          setIsNominationFailureNow(true)
          break
        } else {
          setIsNominationFailureNow(false)
        }
      }
    }
  }

  const fetchProductionStatusEnum = React.useCallback(async () => {
    const result = (await runGetProductionStatusEnum()).data
    setProductionStatusEnum(result)
  }, [runGetProductionStatusEnum, setProductionStatusEnum])

  const fetchAnnouncements = React.useCallback(async () => {
    const result = (await runGetCurrentAnnouncements()).data?.announcements
    setAnnouncements(result)
  }, [runGetCurrentAnnouncements, setAnnouncements])

  const fetchNominationFailure = React.useCallback(
    async (dateWithTime: string) => {
      if (selectedBpsId) {
        const result = (await runGetNominationFailure({bpsId: selectedBpsId, timeFrom: dateWithTime})).data
        setNominationFailures(result?.nominations ?? [])
        isCurrentNominationFailure(result?.nominations)
      }
    },
    [runGetNominationFailure, selectedBpsId],
  )

  const fetchNominations = React.useCallback(async () => {
    if (selectedBpsId) {
      const normalizedDate = selectedDate.substring(0, 10)
      const result = (await runGetNominationsV2({dateFrom: normalizedDate, bpsId: selectedBpsId})).data

      setNominations(result?.nominations ?? [])
    }
  }, [selectedBpsId, runGetNominationsV2, setNominations, selectedDate])

  const handleConfirmRevokeNominationFailure = async () => {
    try {
      if (selectedBpsId) {
        await revokeNominationFailure({
          revokeNominationFailureRequest: {
            bps_id: selectedBpsId,
            time_from: formatDateTimeToNoZone(selectedDate),
          },
        })
      }
      fetchNominations()
      fetchNominationFailure(formatDateTimeToNoZone(selectedDate))
      setIsRevokeNominationFailureDialogOpen(false)
    } catch (e) {
      setIsRevokeNominationFailureDialogOpen(false)
      setIsUnsuccessfulFailureRevokeDialogOpen(true)
    }
  }

  dayjs.extend(utc)
  dayjs.extend(timezone)

  const isDateInPast = dayjs(selectedDate).isBefore(dayjs(), 'day')
  const isFailureTime =
    dayjs(selectedDate).isSame(dayjs(), 'day') ||
    (dayjs(selectedDate).isSame(dayjs().add(1, 'day'), 'day') &&
      (dayjs().add(1, 'day').tz('Europe/Bratislava').hour() > 8 ||
        (dayjs().add(1, 'day').tz('Europe/Bratislava').hour() == 8 && dayjs().minute() >= 35)))

  useEffect(() => {
    if (selectedTab != 'table-view') {
      setBpsNominationTableOpen(false)
    }
  }, [selectedTab, setBpsNominationTableOpen])

  useEffect(() => {
    fetchProductionStatusEnum()
  }, [fetchProductionStatusEnum])

  useEffect(() => {
    fetchAnnouncements()
  }, [fetchAnnouncements])

  useEffect(() => {
    if (isFailureTime) {
      fetchNominationFailure(formatDateTimeToNoZone(selectedDate))
    }
  }, [selectedDate, isFailureTime, nominations, fetchNominationFailure])

  const nominationsToBeRevoked = () => {
    if (nominationFailures && nominationFailures.length > 0) {
      const firstNominationFailureTimeFrom = nominationFailures[0].time_from
      let lastNominationFailureTimeTo = nominationFailures[0].time_to

      nominationFailures.sort((a, b) => (dayjs(a.time_from).isAfter(dayjs(b.time_from)) ? 1 : -1))

      let lastIsFailureTimeFrom = dayjs(nominationFailures[0].time_from).subtract(15, 'minute')
      nominationFailures?.forEach((nomination) => {
        if (dayjs(nomination.time_from).subtract(15, 'minute').isSame(lastIsFailureTimeFrom)) {
          lastNominationFailureTimeTo = nomination.time_to
          lastIsFailureTimeFrom = dayjs(nomination.time_from)
        } else {
          return
        }
      })

      return {
        timeFrom: firstNominationFailureTimeFrom,
        timeTo: lastNominationFailureTimeTo,
      }
    }
  }

  const getAddNominationButtonText = () => {
    if (isUserGroupManagerOrGroupOperator(userInfo)) {
      if (nominations && nominations.length == 0) {
        return `Zadať nomináciu`
      } else {
        return `Zmeniť nomináciu`
      }
    } else {
      if (nominations && nominations.length == 0) {
        return isFailureTime ? `Zadať poruchu` : `Zadať nomináciu`
      } else {
        return isFailureTime ? `Zadať poruchu` : `Zmeniť nomináciu`
      }
    }
  }

  return (
    <>
      <Stack>
        {announcements?.map((announcement, index) => (
          <Alert
            key={index}
            severity={announcement.type == 'INFO' ? 'info' : announcement.type == 'WARNING' ? 'warning' : 'info'}
            sx={{marginBottom: '8px', padding: '0px 16px'}}
          >
            {announcement.text}
          </Alert>
        ))}
      </Stack>
      <Stack>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            variant="text"
            sx={{right: 15, display: isUserGroupManagerOrGroupOperator(userInfo) ? null : 'none'}}
            onClick={() => goToDispatch()}
          >
            Dispečing
          </Button>

          <Link component="button" variant="subtitle2" onClick={() => logout()}>
            Odhlásiť sa
          </Link>
        </Box>
      </Stack>
      <Stack>
        <Container maxWidth="xs" sx={{justifyContent: 'center', display: 'flex', marginBottom: '12px'}}>
          <BpsSelector />
        </Container>

        <Container maxWidth="xs" sx={{justifyContent: 'center', display: 'flex'}}>
          <DatePicker value={selectedDate} onChange={setSelectedDate} showToolbar={false} />
        </Container>

        <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: '8px'}}>
          <Tabs variant="scrollable" value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
            <Tab label="Graf" value="graph-view" />
            <Tab label="Plán výroby" value="table-view" />
            <Tab label="Skupina" value="group-view" />
            <Tab label="Profily" value="profiles-view" />
            <Tab label="BPS Detail" value="bps-detail-view" />
            <Tab label="Prevádzka" value="operation-view" />
          </Tabs>
        </Box>
        {selectedTab == 'table-view' && (
          <Stack
            sx={{justifyContent: 'space-between', gap: '12px', flex: 1}}
            direction="row"
            flexWrap="wrap"
            marginTop={3}
            spacing={1}
          >
            <Stack direction="row" flexWrap="wrap" sx={{gap: '12px', flex: 1, alignItems: 'flex-start'}} marginLeft={1}>
              <Button
                endIcon={<Add fontSize="large" />}
                onClick={() => setIsAddNominationDialogOpen(true)}
                disabled={isDateInPast || !isBpsEditable(userInfo, selectedBpsId)}
                color={isFailureTime ? 'error' : 'primary'}
              >
                {getAddNominationButtonText()}
              </Button>

              <Button
                endIcon={<Add fontSize="large" />}
                onClick={() => setIsCopyDayDialogOpen(true)}
                disabled={isDateInPast || !isBpsEditable(userInfo, selectedBpsId)}
              >
                Skopírovať deň
              </Button>

              <Button
                endIcon={<Moving fontSize="large" />}
                onClick={() => setIsUseProfileDialogOpen(true)}
                disabled={isDateInPast || !isBpsEditable(userInfo, selectedBpsId)}
              >
                Použiť profil
              </Button>
            </Stack>
            <Stack>
              {isFailureTime && nominationFailures?.length !== 0 && (
                <Stack direction="row" flexWrap="wrap" sx={{gap: '12px', flex: 1, alignItems: 'flex-start'}}>
                  <Alert
                    severity={isNominationFailureNow ? 'error' : 'warning'}
                    sx={{
                      fontWeight: 'bold',
                      padding: '0px 8px',
                    }}
                  >
                    {isNominationFailureNow
                      ? `Prebieha porucha do ${formatTime(nominationsToBeRevoked()?.timeTo)}`
                      : `Naplánovaná porucha ${formatTime(nominationsToBeRevoked()?.timeFrom)} - ${formatTime(
                          nominationsToBeRevoked()?.timeTo,
                        )}`}
                  </Alert>
                  <Button
                    onClick={() => setIsRevokeNominationFailureDialogOpen(true)}
                    variant="outlined"
                    sx={{
                      backgroundColor: isNominationFailureNow ? 'error.main' : 'orange',
                      color: 'white',
                      borderColor: isNominationFailureNow ? 'error.main' : 'orange',
                      '&:hover': {
                        backgroundColor: isNominationFailureNow ? 'error.dark' : 'darkorange',
                        borderColor: isNominationFailureNow ? 'error.dark' : 'orange',
                      },
                    }}
                  >
                    Zrušiť poruchu
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}

        {selectedTab === 'graph-view' && <GraphView />}
        {selectedTab === 'table-view' && <NominationsTable baseDate={selectedDate} />}
        {selectedTab === 'profiles-view' && <ProfilesTable />}
        {selectedTab === 'group-view' && (
          <GroupNominationsTable openNominationTableForBpsAndPeriod={openNominationTableForBpsAndPeriod} />
        )}
        {selectedTab === 'bps-detail-view' && <BpsDetail />}
        {selectedTab === 'operation-view' && <OperationView />}
      </Stack>

      {isAddNominationDialogOpen && (
        <AddNominationDialog
          open={isAddNominationDialogOpen}
          onClose={() => setIsAddNominationDialogOpen(false)}
          baseDate={selectedDate}
          isFailureTime={isFailureTime}
        />
      )}

      {isCopyDayDialogOpen && <CopyDayDialog open={isCopyDayDialogOpen} onClose={handleCloseCopyDayDialog} />}

      {isUseProfileDialogOpen && (
        <UseProfileDialog open={isUseProfileDialogOpen} onClose={handleCloseUseProfileDialogOpen} />
      )}

      <RevokeNominationFailureDialog
        open={isRevokeNominationFailureDialogOpen}
        onClose={() => setIsRevokeNominationFailureDialogOpen(false)}
        onConfirm={handleConfirmRevokeNominationFailure}
        nominationsToBeRevoked={nominationsToBeRevoked}
        isNominationFailureNow={isNominationFailureNow}
      />

      <UnsuccessfulFailureRevokeDialog
        open={isUnsuccessfulFailureRevokeDialogOpen}
        onClose={() => setIsUnsuccessfulFailureRevokeDialogOpen(false)}
      />
    </>
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * BPS Group app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DuplicateNominationRequest } from '../model';
// @ts-ignore
import { GetAllNominationOverrideResponse } from '../model';
// @ts-ignore
import { GetEnergySupplyGroupResponse } from '../model';
// @ts-ignore
import { GetEnergySupplyResponse } from '../model';
// @ts-ignore
import { GetGroupNominationsPeriodResponse } from '../model';
// @ts-ignore
import { GetGroupNominationsResponse } from '../model';
// @ts-ignore
import { GetNominationsExtendedResponse } from '../model';
// @ts-ignore
import { GetNominationsMinimumAndMaximumResponse } from '../model';
// @ts-ignore
import { GetNominationsResponse } from '../model';
// @ts-ignore
import { Nomination } from '../model';
// @ts-ignore
import { NominationExtended } from '../model';
// @ts-ignore
import { NominationOverride } from '../model';
// @ts-ignore
import { ProductionStatusResponse } from '../model';
// @ts-ignore
import { RevokeNominationFailureRequest } from '../model';
// @ts-ignore
import { SendGroupNominationsExtendedResponse } from '../model';
// @ts-ignore
import { SendGroupNominationsRequest } from '../model';
// @ts-ignore
import { SendGroupNominationsResponse } from '../model';
// @ts-ignore
import { SendNominationsToAggregatorExtendedResponse } from '../model';
// @ts-ignore
import { SendNominationsToAggregatorRequest } from '../model';
// @ts-ignore
import { SuccessfulResponse } from '../model';
// @ts-ignore
import { UpdateAllNominationOverrideRequest } from '../model';
// @ts-ignore
import { UpdateNominationOverrideRequest } from '../model';
/**
 * NominationControllerApi - axios parameter creator
 * @export
 */
export const NominationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Nomination} nomination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNomination: async (nomination: Nomination, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nomination' is not null or undefined
            assertParamExists('createNomination', 'nomination', nomination)
            const localVarPath = `/api/nomination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nomination, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NominationExtended} nominationExtended 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNominationV2: async (nominationExtended: NominationExtended, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nominationExtended' is not null or undefined
            assertParamExists('createNominationV2', 'nominationExtended', nominationExtended)
            const localVarPath = `/api/v2/nomination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nominationExtended, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DuplicateNominationRequest} duplicateNominationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateNominations: async (duplicateNominationRequest: DuplicateNominationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'duplicateNominationRequest' is not null or undefined
            assertParamExists('duplicateNominations', 'duplicateNominationRequest', duplicateNominationRequest)
            const localVarPath = `/api/nomination/duplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(duplicateNominationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DuplicateNominationRequest} duplicateNominationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateNominationsV2: async (duplicateNominationRequest: DuplicateNominationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'duplicateNominationRequest' is not null or undefined
            assertParamExists('duplicateNominationsV2', 'duplicateNominationRequest', duplicateNominationRequest)
            const localVarPath = `/api/v2/nomination/duplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(duplicateNominationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNominationOverride: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/nomination-override/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergySupply: async (bpsId: number, dateFrom: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getEnergySupply', 'bpsId', bpsId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getEnergySupply', 'dateFrom', dateFrom)
            const localVarPath = `/api/energy-supply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergySupplyGroup: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getEnergySupplyGroup', 'date', date)
            const localVarPath = `/api/energy-supply/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupNominations: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getGroupNominations', 'date', date)
            const localVarPath = `/api/nomination/group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupNominationsMinimumAndMaximum: async (dateFrom: string, dateTo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getGroupNominationsMinimumAndMaximum', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getGroupNominationsMinimumAndMaximum', 'dateTo', dateTo)
            const localVarPath = `/api/nomination/group/minmax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} timeFrom 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupNominationsPeriod: async (timeFrom: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeFrom' is not null or undefined
            assertParamExists('getGroupNominationsPeriod', 'timeFrom', timeFrom)
            const localVarPath = `/api/nomination/group/period`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeFrom !== undefined) {
                localVarQueryParameter['time_from'] = timeFrom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationOverride: async (bpsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getNominationOverride', 'bpsId', bpsId)
            const localVarPath = `/api/nomination-override`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bpsId'] = bpsId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominations: async (bpsId: number, dateFrom: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getNominations', 'bpsId', bpsId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getNominations', 'dateFrom', dateFrom)
            const localVarPath = `/api/nomination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} timeFrom 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationsFailure: async (bpsId: number, timeFrom: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getNominationsFailure', 'bpsId', bpsId)
            // verify required parameter 'timeFrom' is not null or undefined
            assertParamExists('getNominationsFailure', 'timeFrom', timeFrom)
            const localVarPath = `/api/nomination/failure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }

            if (timeFrom !== undefined) {
                localVarQueryParameter['time_from'] = timeFrom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationsMinAndMax: async (bpsId: number, dateFrom: string, dateTo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getNominationsMinAndMax', 'bpsId', bpsId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getNominationsMinAndMax', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getNominationsMinAndMax', 'dateTo', dateTo)
            const localVarPath = `/api/nomination/minmax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationsV2: async (bpsId: number, dateFrom: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bpsId' is not null or undefined
            assertParamExists('getNominationsV2', 'bpsId', bpsId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getNominationsV2', 'dateFrom', dateFrom)
            const localVarPath = `/api/v2/nomination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bpsId !== undefined) {
                localVarQueryParameter['bps_id'] = bpsId;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionStatusEnum: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/production-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RevokeNominationFailureRequest} revokeNominationFailureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeNominationFailure: async (revokeNominationFailureRequest: RevokeNominationFailureRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'revokeNominationFailureRequest' is not null or undefined
            assertParamExists('revokeNominationFailure', 'revokeNominationFailureRequest', revokeNominationFailureRequest)
            const localVarPath = `/api/nomination/revoke-failure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeNominationFailureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGroupNominations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/nomination/group/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendGroupNominationsRequest} sendGroupNominationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGroupNominationsV2: async (sendGroupNominationsRequest: SendGroupNominationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendGroupNominationsRequest' is not null or undefined
            assertParamExists('sendGroupNominationsV2', 'sendGroupNominationsRequest', sendGroupNominationsRequest)
            const localVarPath = `/api/v2/nomination/group/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendGroupNominationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendGroupNominationsRequest} sendGroupNominationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGroupNominationsV3: async (sendGroupNominationsRequest: SendGroupNominationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendGroupNominationsRequest' is not null or undefined
            assertParamExists('sendGroupNominationsV3', 'sendGroupNominationsRequest', sendGroupNominationsRequest)
            const localVarPath = `/api/v3/nomination/group/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendGroupNominationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendNominationsToAggregatorRequest} sendNominationsToAggregatorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNominationsToAggregator: async (sendNominationsToAggregatorRequest: SendNominationsToAggregatorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendNominationsToAggregatorRequest' is not null or undefined
            assertParamExists('sendNominationsToAggregator', 'sendNominationsToAggregatorRequest', sendNominationsToAggregatorRequest)
            const localVarPath = `/api/nomination/send/aggregator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendNominationsToAggregatorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAllNominationOverrideRequest} updateAllNominationOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllNominationOverride: async (updateAllNominationOverrideRequest: UpdateAllNominationOverrideRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAllNominationOverrideRequest' is not null or undefined
            assertParamExists('updateAllNominationOverride', 'updateAllNominationOverrideRequest', updateAllNominationOverrideRequest)
            const localVarPath = `/api/nomination-override/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAllNominationOverrideRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateNominationOverrideRequest} updateNominationOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNominationOverride: async (updateNominationOverrideRequest: UpdateNominationOverrideRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNominationOverrideRequest' is not null or undefined
            assertParamExists('updateNominationOverride', 'updateNominationOverrideRequest', updateNominationOverrideRequest)
            const localVarPath = `/api/nomination-override`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer_Authentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNominationOverrideRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NominationControllerApi - functional programming interface
 * @export
 */
export const NominationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NominationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Nomination} nomination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNomination(nomination: Nomination, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNomination(nomination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NominationExtended} nominationExtended 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNominationV2(nominationExtended: NominationExtended, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationsExtendedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNominationV2(nominationExtended, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DuplicateNominationRequest} duplicateNominationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateNominations(duplicateNominationRequest: DuplicateNominationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateNominations(duplicateNominationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DuplicateNominationRequest} duplicateNominationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateNominationsV2(duplicateNominationRequest: DuplicateNominationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationsExtendedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateNominationsV2(duplicateNominationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNominationOverride(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllNominationOverrideResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNominationOverride(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergySupply(bpsId: number, dateFrom: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEnergySupplyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergySupply(bpsId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergySupplyGroup(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEnergySupplyGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergySupplyGroup(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupNominations(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGroupNominationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupNominations(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupNominationsMinimumAndMaximum(dateFrom: string, dateTo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationsMinimumAndMaximumResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupNominationsMinimumAndMaximum(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} timeFrom 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupNominationsPeriod(timeFrom: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGroupNominationsPeriodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupNominationsPeriod(timeFrom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNominationOverride(bpsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NominationOverride>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNominationOverride(bpsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNominations(bpsId: number, dateFrom: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNominations(bpsId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} timeFrom 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNominationsFailure(bpsId: number, timeFrom: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationsExtendedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNominationsFailure(bpsId, timeFrom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNominationsMinAndMax(bpsId: number, dateFrom: string, dateTo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationsMinimumAndMaximumResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNominationsMinAndMax(bpsId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNominationsV2(bpsId: number, dateFrom: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNominationsExtendedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNominationsV2(bpsId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductionStatusEnum(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductionStatusEnum(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RevokeNominationFailureRequest} revokeNominationFailureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeNominationFailure(revokeNominationFailureRequest: RevokeNominationFailureRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessfulResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeNominationFailure(revokeNominationFailureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendGroupNominations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendGroupNominationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendGroupNominations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendGroupNominationsRequest} sendGroupNominationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendGroupNominationsV2(sendGroupNominationsRequest: SendGroupNominationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendGroupNominationsExtendedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendGroupNominationsV2(sendGroupNominationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendGroupNominationsRequest} sendGroupNominationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendGroupNominationsV3(sendGroupNominationsRequest: SendGroupNominationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendGroupNominationsExtendedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendGroupNominationsV3(sendGroupNominationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendNominationsToAggregatorRequest} sendNominationsToAggregatorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNominationsToAggregator(sendNominationsToAggregatorRequest: SendNominationsToAggregatorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendNominationsToAggregatorExtendedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNominationsToAggregator(sendNominationsToAggregatorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateAllNominationOverrideRequest} updateAllNominationOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAllNominationOverride(updateAllNominationOverrideRequest: UpdateAllNominationOverrideRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllNominationOverrideResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAllNominationOverride(updateAllNominationOverrideRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateNominationOverrideRequest} updateNominationOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNominationOverride(updateNominationOverrideRequest: UpdateNominationOverrideRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NominationOverride>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNominationOverride(updateNominationOverrideRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NominationControllerApi - factory interface
 * @export
 */
export const NominationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NominationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Nomination} nomination 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNomination(nomination: Nomination, options?: any): AxiosPromise<GetNominationsResponse> {
            return localVarFp.createNomination(nomination, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NominationExtended} nominationExtended 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNominationV2(nominationExtended: NominationExtended, options?: any): AxiosPromise<GetNominationsExtendedResponse> {
            return localVarFp.createNominationV2(nominationExtended, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DuplicateNominationRequest} duplicateNominationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateNominations(duplicateNominationRequest: DuplicateNominationRequest, options?: any): AxiosPromise<GetNominationsResponse> {
            return localVarFp.duplicateNominations(duplicateNominationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DuplicateNominationRequest} duplicateNominationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateNominationsV2(duplicateNominationRequest: DuplicateNominationRequest, options?: any): AxiosPromise<GetNominationsExtendedResponse> {
            return localVarFp.duplicateNominationsV2(duplicateNominationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNominationOverride(options?: any): AxiosPromise<GetAllNominationOverrideResponse> {
            return localVarFp.getAllNominationOverride(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergySupply(bpsId: number, dateFrom: string, dateTo?: string, options?: any): AxiosPromise<GetEnergySupplyResponse> {
            return localVarFp.getEnergySupply(bpsId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergySupplyGroup(date: string, options?: any): AxiosPromise<GetEnergySupplyGroupResponse> {
            return localVarFp.getEnergySupplyGroup(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupNominations(date: string, options?: any): AxiosPromise<GetGroupNominationsResponse> {
            return localVarFp.getGroupNominations(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupNominationsMinimumAndMaximum(dateFrom: string, dateTo: string, options?: any): AxiosPromise<GetNominationsMinimumAndMaximumResponse> {
            return localVarFp.getGroupNominationsMinimumAndMaximum(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} timeFrom 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupNominationsPeriod(timeFrom: string, options?: any): AxiosPromise<GetGroupNominationsPeriodResponse> {
            return localVarFp.getGroupNominationsPeriod(timeFrom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationOverride(bpsId: number, options?: any): AxiosPromise<NominationOverride> {
            return localVarFp.getNominationOverride(bpsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominations(bpsId: number, dateFrom: string, dateTo?: string, options?: any): AxiosPromise<GetNominationsResponse> {
            return localVarFp.getNominations(bpsId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} timeFrom 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationsFailure(bpsId: number, timeFrom: string, options?: any): AxiosPromise<GetNominationsExtendedResponse> {
            return localVarFp.getNominationsFailure(bpsId, timeFrom, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationsMinAndMax(bpsId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<GetNominationsMinimumAndMaximumResponse> {
            return localVarFp.getNominationsMinAndMax(bpsId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bpsId 
         * @param {string} dateFrom 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNominationsV2(bpsId: number, dateFrom: string, dateTo?: string, options?: any): AxiosPromise<GetNominationsExtendedResponse> {
            return localVarFp.getNominationsV2(bpsId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionStatusEnum(options?: any): AxiosPromise<ProductionStatusResponse> {
            return localVarFp.getProductionStatusEnum(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RevokeNominationFailureRequest} revokeNominationFailureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeNominationFailure(revokeNominationFailureRequest: RevokeNominationFailureRequest, options?: any): AxiosPromise<SuccessfulResponse> {
            return localVarFp.revokeNominationFailure(revokeNominationFailureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGroupNominations(options?: any): AxiosPromise<SendGroupNominationsResponse> {
            return localVarFp.sendGroupNominations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendGroupNominationsRequest} sendGroupNominationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGroupNominationsV2(sendGroupNominationsRequest: SendGroupNominationsRequest, options?: any): AxiosPromise<SendGroupNominationsExtendedResponse> {
            return localVarFp.sendGroupNominationsV2(sendGroupNominationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendGroupNominationsRequest} sendGroupNominationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGroupNominationsV3(sendGroupNominationsRequest: SendGroupNominationsRequest, options?: any): AxiosPromise<SendGroupNominationsExtendedResponse> {
            return localVarFp.sendGroupNominationsV3(sendGroupNominationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendNominationsToAggregatorRequest} sendNominationsToAggregatorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNominationsToAggregator(sendNominationsToAggregatorRequest: SendNominationsToAggregatorRequest, options?: any): AxiosPromise<SendNominationsToAggregatorExtendedResponse> {
            return localVarFp.sendNominationsToAggregator(sendNominationsToAggregatorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAllNominationOverrideRequest} updateAllNominationOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllNominationOverride(updateAllNominationOverrideRequest: UpdateAllNominationOverrideRequest, options?: any): AxiosPromise<GetAllNominationOverrideResponse> {
            return localVarFp.updateAllNominationOverride(updateAllNominationOverrideRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateNominationOverrideRequest} updateNominationOverrideRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNominationOverride(updateNominationOverrideRequest: UpdateNominationOverrideRequest, options?: any): AxiosPromise<NominationOverride> {
            return localVarFp.updateNominationOverride(updateNominationOverrideRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createNomination operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiCreateNominationRequest
 */
export interface NominationControllerApiCreateNominationRequest {
    /**
     * 
     * @type {Nomination}
     * @memberof NominationControllerApiCreateNomination
     */
    readonly nomination: Nomination
}

/**
 * Request parameters for createNominationV2 operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiCreateNominationV2Request
 */
export interface NominationControllerApiCreateNominationV2Request {
    /**
     * 
     * @type {NominationExtended}
     * @memberof NominationControllerApiCreateNominationV2
     */
    readonly nominationExtended: NominationExtended
}

/**
 * Request parameters for duplicateNominations operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiDuplicateNominationsRequest
 */
export interface NominationControllerApiDuplicateNominationsRequest {
    /**
     * 
     * @type {DuplicateNominationRequest}
     * @memberof NominationControllerApiDuplicateNominations
     */
    readonly duplicateNominationRequest: DuplicateNominationRequest
}

/**
 * Request parameters for duplicateNominationsV2 operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiDuplicateNominationsV2Request
 */
export interface NominationControllerApiDuplicateNominationsV2Request {
    /**
     * 
     * @type {DuplicateNominationRequest}
     * @memberof NominationControllerApiDuplicateNominationsV2
     */
    readonly duplicateNominationRequest: DuplicateNominationRequest
}

/**
 * Request parameters for getEnergySupply operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiGetEnergySupplyRequest
 */
export interface NominationControllerApiGetEnergySupplyRequest {
    /**
     * 
     * @type {number}
     * @memberof NominationControllerApiGetEnergySupply
     */
    readonly bpsId: number

    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetEnergySupply
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetEnergySupply
     */
    readonly dateTo?: string
}

/**
 * Request parameters for getEnergySupplyGroup operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiGetEnergySupplyGroupRequest
 */
export interface NominationControllerApiGetEnergySupplyGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetEnergySupplyGroup
     */
    readonly date: string
}

/**
 * Request parameters for getGroupNominations operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiGetGroupNominationsRequest
 */
export interface NominationControllerApiGetGroupNominationsRequest {
    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetGroupNominations
     */
    readonly date: string
}

/**
 * Request parameters for getGroupNominationsMinimumAndMaximum operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiGetGroupNominationsMinimumAndMaximumRequest
 */
export interface NominationControllerApiGetGroupNominationsMinimumAndMaximumRequest {
    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetGroupNominationsMinimumAndMaximum
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetGroupNominationsMinimumAndMaximum
     */
    readonly dateTo: string
}

/**
 * Request parameters for getGroupNominationsPeriod operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiGetGroupNominationsPeriodRequest
 */
export interface NominationControllerApiGetGroupNominationsPeriodRequest {
    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetGroupNominationsPeriod
     */
    readonly timeFrom: string
}

/**
 * Request parameters for getNominationOverride operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiGetNominationOverrideRequest
 */
export interface NominationControllerApiGetNominationOverrideRequest {
    /**
     * 
     * @type {number}
     * @memberof NominationControllerApiGetNominationOverride
     */
    readonly bpsId: number
}

/**
 * Request parameters for getNominations operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiGetNominationsRequest
 */
export interface NominationControllerApiGetNominationsRequest {
    /**
     * 
     * @type {number}
     * @memberof NominationControllerApiGetNominations
     */
    readonly bpsId: number

    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetNominations
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetNominations
     */
    readonly dateTo?: string
}

/**
 * Request parameters for getNominationsFailure operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiGetNominationsFailureRequest
 */
export interface NominationControllerApiGetNominationsFailureRequest {
    /**
     * 
     * @type {number}
     * @memberof NominationControllerApiGetNominationsFailure
     */
    readonly bpsId: number

    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetNominationsFailure
     */
    readonly timeFrom: string
}

/**
 * Request parameters for getNominationsMinAndMax operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiGetNominationsMinAndMaxRequest
 */
export interface NominationControllerApiGetNominationsMinAndMaxRequest {
    /**
     * 
     * @type {number}
     * @memberof NominationControllerApiGetNominationsMinAndMax
     */
    readonly bpsId: number

    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetNominationsMinAndMax
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetNominationsMinAndMax
     */
    readonly dateTo: string
}

/**
 * Request parameters for getNominationsV2 operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiGetNominationsV2Request
 */
export interface NominationControllerApiGetNominationsV2Request {
    /**
     * 
     * @type {number}
     * @memberof NominationControllerApiGetNominationsV2
     */
    readonly bpsId: number

    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetNominationsV2
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof NominationControllerApiGetNominationsV2
     */
    readonly dateTo?: string
}

/**
 * Request parameters for revokeNominationFailure operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiRevokeNominationFailureRequest
 */
export interface NominationControllerApiRevokeNominationFailureRequest {
    /**
     * 
     * @type {RevokeNominationFailureRequest}
     * @memberof NominationControllerApiRevokeNominationFailure
     */
    readonly revokeNominationFailureRequest: RevokeNominationFailureRequest
}

/**
 * Request parameters for sendGroupNominationsV2 operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiSendGroupNominationsV2Request
 */
export interface NominationControllerApiSendGroupNominationsV2Request {
    /**
     * 
     * @type {SendGroupNominationsRequest}
     * @memberof NominationControllerApiSendGroupNominationsV2
     */
    readonly sendGroupNominationsRequest: SendGroupNominationsRequest
}

/**
 * Request parameters for sendGroupNominationsV3 operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiSendGroupNominationsV3Request
 */
export interface NominationControllerApiSendGroupNominationsV3Request {
    /**
     * 
     * @type {SendGroupNominationsRequest}
     * @memberof NominationControllerApiSendGroupNominationsV3
     */
    readonly sendGroupNominationsRequest: SendGroupNominationsRequest
}

/**
 * Request parameters for sendNominationsToAggregator operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiSendNominationsToAggregatorRequest
 */
export interface NominationControllerApiSendNominationsToAggregatorRequest {
    /**
     * 
     * @type {SendNominationsToAggregatorRequest}
     * @memberof NominationControllerApiSendNominationsToAggregator
     */
    readonly sendNominationsToAggregatorRequest: SendNominationsToAggregatorRequest
}

/**
 * Request parameters for updateAllNominationOverride operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiUpdateAllNominationOverrideRequest
 */
export interface NominationControllerApiUpdateAllNominationOverrideRequest {
    /**
     * 
     * @type {UpdateAllNominationOverrideRequest}
     * @memberof NominationControllerApiUpdateAllNominationOverride
     */
    readonly updateAllNominationOverrideRequest: UpdateAllNominationOverrideRequest
}

/**
 * Request parameters for updateNominationOverride operation in NominationControllerApi.
 * @export
 * @interface NominationControllerApiUpdateNominationOverrideRequest
 */
export interface NominationControllerApiUpdateNominationOverrideRequest {
    /**
     * 
     * @type {UpdateNominationOverrideRequest}
     * @memberof NominationControllerApiUpdateNominationOverride
     */
    readonly updateNominationOverrideRequest: UpdateNominationOverrideRequest
}

/**
 * NominationControllerApi - object-oriented interface
 * @export
 * @class NominationControllerApi
 * @extends {BaseAPI}
 */
export class NominationControllerApi extends BaseAPI {
    /**
     * 
     * @param {NominationControllerApiCreateNominationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public createNomination(requestParameters: NominationControllerApiCreateNominationRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).createNomination(requestParameters.nomination, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiCreateNominationV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public createNominationV2(requestParameters: NominationControllerApiCreateNominationV2Request, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).createNominationV2(requestParameters.nominationExtended, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiDuplicateNominationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public duplicateNominations(requestParameters: NominationControllerApiDuplicateNominationsRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).duplicateNominations(requestParameters.duplicateNominationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiDuplicateNominationsV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public duplicateNominationsV2(requestParameters: NominationControllerApiDuplicateNominationsV2Request, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).duplicateNominationsV2(requestParameters.duplicateNominationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getAllNominationOverride(options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getAllNominationOverride(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiGetEnergySupplyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getEnergySupply(requestParameters: NominationControllerApiGetEnergySupplyRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getEnergySupply(requestParameters.bpsId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiGetEnergySupplyGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getEnergySupplyGroup(requestParameters: NominationControllerApiGetEnergySupplyGroupRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getEnergySupplyGroup(requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiGetGroupNominationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getGroupNominations(requestParameters: NominationControllerApiGetGroupNominationsRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getGroupNominations(requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiGetGroupNominationsMinimumAndMaximumRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getGroupNominationsMinimumAndMaximum(requestParameters: NominationControllerApiGetGroupNominationsMinimumAndMaximumRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getGroupNominationsMinimumAndMaximum(requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiGetGroupNominationsPeriodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getGroupNominationsPeriod(requestParameters: NominationControllerApiGetGroupNominationsPeriodRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getGroupNominationsPeriod(requestParameters.timeFrom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiGetNominationOverrideRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getNominationOverride(requestParameters: NominationControllerApiGetNominationOverrideRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getNominationOverride(requestParameters.bpsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiGetNominationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getNominations(requestParameters: NominationControllerApiGetNominationsRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getNominations(requestParameters.bpsId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiGetNominationsFailureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getNominationsFailure(requestParameters: NominationControllerApiGetNominationsFailureRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getNominationsFailure(requestParameters.bpsId, requestParameters.timeFrom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiGetNominationsMinAndMaxRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getNominationsMinAndMax(requestParameters: NominationControllerApiGetNominationsMinAndMaxRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getNominationsMinAndMax(requestParameters.bpsId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiGetNominationsV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getNominationsV2(requestParameters: NominationControllerApiGetNominationsV2Request, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getNominationsV2(requestParameters.bpsId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public getProductionStatusEnum(options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).getProductionStatusEnum(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiRevokeNominationFailureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public revokeNominationFailure(requestParameters: NominationControllerApiRevokeNominationFailureRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).revokeNominationFailure(requestParameters.revokeNominationFailureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public sendGroupNominations(options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).sendGroupNominations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiSendGroupNominationsV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public sendGroupNominationsV2(requestParameters: NominationControllerApiSendGroupNominationsV2Request, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).sendGroupNominationsV2(requestParameters.sendGroupNominationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiSendGroupNominationsV3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public sendGroupNominationsV3(requestParameters: NominationControllerApiSendGroupNominationsV3Request, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).sendGroupNominationsV3(requestParameters.sendGroupNominationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiSendNominationsToAggregatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public sendNominationsToAggregator(requestParameters: NominationControllerApiSendNominationsToAggregatorRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).sendNominationsToAggregator(requestParameters.sendNominationsToAggregatorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiUpdateAllNominationOverrideRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public updateAllNominationOverride(requestParameters: NominationControllerApiUpdateAllNominationOverrideRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).updateAllNominationOverride(requestParameters.updateAllNominationOverrideRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NominationControllerApiUpdateNominationOverrideRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominationControllerApi
     */
    public updateNominationOverride(requestParameters: NominationControllerApiUpdateNominationOverrideRequest, options?: AxiosRequestConfig) {
        return NominationControllerApiFp(this.configuration).updateNominationOverride(requestParameters.updateNominationOverrideRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

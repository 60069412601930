import {FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent, Stack, Typography} from '@mui/material'
import {NominationProfile, ProductionStatusResponse} from '../api/generated'
import {NominationExtendedBase} from '../types/nominationExtended'
import React from 'react'
import {NOT_SELECTED_PRODUCTION_STATUS} from '../utils/constants'

type NominationDialogStatusSelectorProps = {
  productionStatusEnum: ProductionStatusResponse | undefined
  editedNomination: NominationExtendedBase | NominationProfile
  onValueChange: (value: number) => void
}

const productionStatusColor = (status: number | undefined) => {
  switch (status) {
    case NOT_SELECTED_PRODUCTION_STATUS: {
      return 'white'
    }
    case 1: {
      return '#92C937'
    }
    case 2: {
      return 'orange'
    }
    case 3: {
      return 'red'
    }
    default: {
      return 'red'
    }
  }
}

const productionStatusNotSelected = (editedNomination: NominationExtendedBase | NominationProfile) => {
  return editedNomination.production_status === NOT_SELECTED_PRODUCTION_STATUS
}

export const NominationDialogStatusSelector: React.FC<NominationDialogStatusSelectorProps> = ({
  productionStatusEnum,
  editedNomination,
  onValueChange,
}) => {
  return (
    <>
      <Stack>
        <Typography variant="caption">Prevádzková pripravenosť</Typography>
        <FormControl fullWidth sx={{marginBottom: '5px'}} size="small">
          <Select
            value={productionStatusNotSelected(editedNomination) ? '' : editedNomination.production_status?.toString()}
            sx={{
              color: 'white',
              backgroundColor: productionStatusColor(editedNomination.production_status),
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: productionStatusNotSelected(editedNomination)
                  ? 'red'
                  : productionStatusColor(editedNomination.production_status),
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: productionStatusNotSelected(editedNomination)
                  ? 'red'
                  : productionStatusColor(editedNomination.production_status),
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: productionStatusNotSelected(editedNomination)
                  ? 'red'
                  : productionStatusColor(editedNomination.production_status),
              },
              '& .MuiSvgIcon-root': {
                color: productionStatusNotSelected(editedNomination) ? 'primary' : 'white',
              },
            }}
            onChange={(event: SelectChangeEvent) => onValueChange(Number(event.target.value))}
          >
            {productionStatusEnum?.status?.map((e) => (
              <MenuItem key={e.value} value={e.value}>
                {e.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormHelperText error={productionStatusNotSelected(editedNomination)}>
          {productionStatusNotSelected(editedNomination) ? 'Vyberte prevádzkovú pripravenosť' : ''}
        </FormHelperText>
      </Stack>
    </>
  )
}

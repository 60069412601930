import React, {useCallback, useEffect} from 'react'
import {transformToKwNumber, validateNumericValue} from '../utils/common'
import {NominationExtendedBase} from '../types/nominationExtended'
import {TextField} from '@mui/material'
import {NominationProfile} from '../api/generated'
import {NOMINATION_VALUE_TYPE} from '../utils/constants'
import {useBucketStore} from '../utils/BucketStoreContext'

type NominationDialogPpsProps = {
  editedNomination: NominationExtendedBase | NominationProfile
  minValuePps: number
  maxValuePps: number
  mrcMw: number
  onEditProperties: (changes: Partial<NominationExtendedBase>) => void
  onValueChanged: (valid: boolean) => void
  nominationValueBorderColor: (nominationType: string) => {color: string; isOutOfRange: boolean}
}

export const NominationDialogPps: React.FC<NominationDialogPpsProps> = ({
  editedNomination,
  onEditProperties,
  minValuePps,
  maxValuePps,
  mrcMw,
  onValueChanged,
  nominationValueBorderColor,
}) => {
  const {data: userInfo} = useBucketStore('userInfo')

  const minValidPpsToUse =
    userInfo?.roles.includes('GROUP_OPERATOR') || userInfo?.roles.includes('GROUP_MNG') ? 0 : minValuePps
  const maxValidPpsToUse =
    userInfo?.roles.includes('GROUP_OPERATOR') || userInfo?.roles.includes('GROUP_MNG')
      ? transformToKwNumber(mrcMw) ?? 0
      : maxValuePps < minValidPpsToUse
      ? minValidPpsToUse
      : maxValuePps

  const valueIsValid = useCallback(() => {
    return (
      (editedNomination.value_pps ?? 0) >= minValidPpsToUse && (editedNomination.value_pps ?? 0) <= maxValidPpsToUse
    )
  }, [editedNomination.value_pps, minValidPpsToUse, maxValidPpsToUse])

  useEffect(() => {
    onValueChanged(valueIsValid())
  }, [editedNomination.value_pps, onValueChanged, valueIsValid])

  return (
    <>
      <TextField
        margin="normal"
        label={'Nominácia pre PpS'}
        type="text"
        value={editedNomination.value_pps ?? 0}
        onChange={(e) => {
          if (validateNumericValue(e.target.value)) {
            onEditProperties({value_pps: +e.target.value})
          }
          onValueChanged(valueIsValid())
        }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={!userInfo?.roles.includes('GROUP_MNG') && !userInfo?.roles.includes('GROUP_OPERATOR')}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Pps).color,
            },
            '&:hover fieldset': {
              borderColor: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Pps).color,
            },
            '&.Mui-focused fieldset': {
              borderColor: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Pps).color,
            },
          },
          '& .MuiInputLabel-root': {
            color: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Pps).color,
          },
          '& .Mui-focused .MuiInputLabel-root': {
            color: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Pps).color,
          },
        }}
      />
    </>
  )
}

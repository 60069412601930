import React from 'react'
import {transformToKwWithEndKw, transformToMwNumber} from '../utils/common'
import {NominationExtendedBase} from '../types/nominationExtended'
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {Device, NominationProfile} from '../api/generated'
import {nominationTechParamBands} from '../utils/powerUnitControl'
import {NOMINAITON_TECH_PARAMS_COLORS} from '../utils/constants'
import {CauseOfOutage} from '../utils/enums'

type NominationDialogTechParamsProps = {
  tvsMw: number | undefined
  ovsMw: number | undefined
  tvsVarianceMw: number | undefined
  editedNomination: NominationExtendedBase | NominationProfile
  onEditProperties: (changes: Partial<NominationExtendedBase>) => void
  devicesForBps: Device[]
  selectedDevices: number[]
  selectedEnterFault: boolean
  isFailureTime: boolean
  handleSelectedCause: (cause: CauseOfOutage) => void
  selectedCause: CauseOfOutage | string
}

export const NominationDialogTechParams: React.FC<NominationDialogTechParamsProps> = ({
  tvsMw,
  ovsMw,
  tvsVarianceMw,
  editedNomination,
  onEditProperties,
  devicesForBps,
  selectedDevices,
  selectedEnterFault,
  isFailureTime,
  handleSelectedCause,
  selectedCause,
}) => {
  const getNominationTechParamBandIntervals = (color: string) => {
    return `${nominationTechParamBands(color, devicesForBps, selectedDevices, tvsMw, ovsMw, tvsVarianceMw)?.min} -
      ${nominationTechParamBands(color, devicesForBps, selectedDevices, tvsMw, ovsMw, tvsVarianceMw)?.max}
      kW`
  }

  const getOrangeBandIntervalBeforeGreen = () => {
    return `${
      nominationTechParamBands(
        NOMINAITON_TECH_PARAMS_COLORS.Orange,
        devicesForBps,
        selectedDevices,
        tvsMw,
        ovsMw,
        tvsVarianceMw,
      )?.min
    } - 
    ${
      Number(
        nominationTechParamBands(
          NOMINAITON_TECH_PARAMS_COLORS.Green,
          devicesForBps,
          selectedDevices,
          tvsMw,
          ovsMw,
          tvsVarianceMw,
        )?.min,
      ) - 1
    }
    kW`
  }

  const getOrangeBandIntervalAfterGreen = () => {
    return `${
      Number(
        nominationTechParamBands(
          NOMINAITON_TECH_PARAMS_COLORS.Green,
          devicesForBps,
          selectedDevices,
          tvsMw,
          ovsMw,
          tvsVarianceMw,
        )?.max,
      ) + 1
    } - 
    ${
      nominationTechParamBands(
        NOMINAITON_TECH_PARAMS_COLORS.Orange,
        devicesForBps,
        selectedDevices,
        tvsMw,
        ovsMw,
        tvsVarianceMw,
      )?.max
    }
    kW`
  }

  const causeOfOutageNotSelected = () => {
    return selectedCause === ''
  }

  return (
    <>
      {isFailureTime && selectedEnterFault && (
        <Stack>
          <Typography variant="caption">Dôvod výpadku</Typography>
          <FormControl fullWidth sx={{marginBottom: '5px'}} size="small" error={causeOfOutageNotSelected()}>
            <Select
              value={selectedCause || ''}
              onChange={(event: SelectChangeEvent) => handleSelectedCause(event.target.value as CauseOfOutage)}
            >
              {Object.values(CauseOfOutage).map((cause) => (
                <MenuItem key={cause} value={cause}>
                  {cause}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText error={causeOfOutageNotSelected()}>
            {causeOfOutageNotSelected() ? 'Vyberte dôvod výpadku' : ''}
          </FormHelperText>
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="caption">
          Priemerný výkon KGJ:{' '}
          {transformToKwWithEndKw(transformToMwNumber(editedNomination.value_pps) + (tvsMw ? tvsMw : 0))}
        </Typography>
        {/* // BGA-1083 - zakomentovane */}
        {/* <ContextMenu contextMenuKey={'plan-vyroby.zmenit-nom'} /> */}
      </Stack>
      <Typography variant="caption">
        Pásma OOM:{' '}
        <Box component="span" sx={{color: NOMINAITON_TECH_PARAMS_COLORS.Orange}}>
          {getOrangeBandIntervalBeforeGreen()}
        </Box>
        {', '}
        <Box component="span" sx={{color: NOMINAITON_TECH_PARAMS_COLORS.Green}}>
          {getNominationTechParamBandIntervals(NOMINAITON_TECH_PARAMS_COLORS.Green)}
        </Box>
        {', '}
        <Box component="span" sx={{color: NOMINAITON_TECH_PARAMS_COLORS.Orange}}>
          {getOrangeBandIntervalAfterGreen()}
        </Box>
      </Typography>
      <Typography variant="caption">TVS priemer: {transformToKwWithEndKw(tvsMw)}</Typography>
      <Typography variant="caption">
        TVS min - TVS max: {transformToKwWithEndKw((tvsMw ? tvsMw : 0) - 0.5 * (tvsVarianceMw ? tvsVarianceMw : 0))}
        {' - '}
        {transformToKwWithEndKw((tvsMw ? tvsMw : 0) + 0.5 * (tvsVarianceMw ? tvsVarianceMw : 0))}
      </Typography>
      <Typography variant="caption">OVS priemer: {transformToKwWithEndKw(ovsMw)}</Typography>
      <TextField
        margin="normal"
        label="Poznámka"
        multiline
        rows={4}
        value={editedNomination.note}
        onChange={(e) => onEditProperties({note: e.target.value})}
      />
    </>
  )
}

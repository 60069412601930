import {Check, Clear} from '@mui/icons-material'
import {
  Box,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {
  getDevicesForBps,
  updateDeviceForBps,
  getBpsDetailsV2,
  updateBpsV2,
  updateGasForBps,
  getBpsUsers,
} from '../../../api/bpsApi'
import {
  BpsParametersExtended,
  Device,
  DeviceTypeEnum,
  UpdateBpsParametersExtendedRequest,
  UpdateGasRequest,
  User,
} from '../../../api/generated'
import {useAsyncMethodWithErrorHandling} from '../../../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../../../utils/BucketStoreContext'
import {sortDevices, transformToKwWithEndKw} from '../../../utils/common'
import {EditDeviceDialog} from '../../EditDeviceDialog'
import {BpsTable} from './BPSTable'
import {EditBpsDialog} from '../../EditBpsDialog'
import {DeviceTable} from './DeviceTable'
import {ErrorUpdatingBpsDialog} from '../../../../src/components/ErrorUpdatingBpsDialog'
import {UpdateNominationsAfterTvsUpdateDialog} from '../../UpdateNominationsAfterTvsUpdateDialog'
import {UpdateNominationsAfterDeviceUpdateDialog} from '../../UpdateNominationsAfterDeviceUpdateDialog'
import {RecalculatingNominationsDialog} from '../../RecalculatingNominationsDialog'
import {GasTable} from './GasTable'
import {EditGasDialog} from '../../EditGasDialog'
import {UpdateDeviceDosDialog} from '../../UpdateDeviceDosDialog'

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledTableHeadRow = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

const getKgjDevices = (devicesForBps: Device[] | undefined) =>
  devicesForBps?.filter((device) => device.type === DeviceTypeEnum.Chp)

const getDosMinMw = (devicesForBps: Device[] | undefined) =>
  devicesForBps?.reduce((total, item) => total + item.p_dos_min_mw, 0)
const getDosMaxMw = (devicesForBps: Device[] | undefined) =>
  devicesForBps?.reduce((total, item) => total + item.p_dos_max_mw, 0)
const getRegMinMw = (devicesForBps: Device[] | undefined) =>
  devicesForBps?.reduce((total, item) => total + item.p_reg_min_mw, 0)
const getRegMaxMw = (devicesForBps: Device[] | undefined) =>
  devicesForBps?.reduce((total, item) => total + item.p_reg_max_mw, 0)

export const BpsDetail: React.FC = () => {
  const [bpsMembers, setBpsMembers] = useState<User[] | undefined>([])
  const [bpsParameters, setBpsParameters] = useState<BpsParametersExtended>()
  const [devicesForBps, setDevicesForBps] = useState<Device[] | undefined>([])
  const [diviceId, setDeviceId] = useState<number | undefined>(undefined)

  const [isBpsUpdateErrorDialogOpen, setIsBpsUpdateErrorDialogOpen] = useState<boolean>(false)
  const [isEditDeviceDialogOpen, setIsEditDeviceDialogOpen] = useState<boolean>(false)
  const [isEditBpsDialogOpen, setIsEditBpsDialogOpen] = useState<boolean>(false)
  const [isEditGasDialogOpen, setIsEditGasDialogOpen] = useState<boolean>(false)
  const [isUpdateNominationsAfterTvsUpdateDialogOpen, setIsUpdateNominationsAfterTvsUpdateDialogOpen] =
    useState<boolean>(false)
  const [isUpdateDeviceDosDialogOpen, setIsUpdateDeviceDosDialogOpen] = useState<boolean>(false)
  const [isUpdateNominationsAfterDeviceUpdateDialogOpen, setIsUpdateNominationsAfterDeviceUpdateDialogOpen] =
    useState<boolean>(false)
  const [recalculatingNominationsDialogOpen, setRecalculatingNominationsDialogOpen] = useState<boolean>(false)
  const [updateParamsConfirmButtonDisabled, setUpdateParamsConfirmButtonDisabled] = useState<boolean>(false)

  const updateBpsRequest = useRef<UpdateBpsParametersExtendedRequest | undefined>(undefined)
  const updateDeviceRequest = useRef<Device | undefined>(undefined)
  const originalDevices = useRef<Device[] | undefined>(undefined)

  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const selectedIdOld = useRef<number | undefined>(undefined)
  const getDevices = useRef<boolean>(false)

  const {run: runGetBpsDetailsV2} = useAsyncMethodWithErrorHandling(getBpsDetailsV2)
  const {run: runGetBpsUsers} = useAsyncMethodWithErrorHandling(getBpsUsers)
  const {run: runUpdateBpsV2} = useAsyncMethodWithErrorHandling(updateBpsV2)
  const {run: runUpdateDevice} = useAsyncMethodWithErrorHandling(updateDeviceForBps)
  const {run: runUpdateGas} = useAsyncMethodWithErrorHandling(updateGasForBps)

  const {run: runGetDevicesForBps} = useAsyncMethodWithErrorHandling(getDevicesForBps)

  const fetchBpsDetails = React.useCallback(async () => {
    if (selectedBpsId && selectedIdOld.current !== selectedBpsId) {
      selectedIdOld.current = selectedBpsId
      getDevices.current = true
      const result = (await runGetBpsDetailsV2({id: selectedBpsId})).data

      setBpsParameters(result?.parameters)
    }
  }, [runGetBpsDetailsV2, selectedBpsId])

  const fetchBpsUsers = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetBpsUsers({id: selectedBpsId})).data

      setBpsMembers(result?.users)
    }
  }, [runGetBpsUsers, selectedBpsId])

  const fetchDevicesForBps = React.useCallback(async () => {
    if (getDevices.current && selectedBpsId) {
      getDevices.current = false
      const result = (await runGetDevicesForBps({id: selectedBpsId})).data

      const allDevicesSorted = sortDevices(result?.devices ?? [])

      setDevicesForBps(allDevicesSorted)
      originalDevices.current = JSON.parse(JSON.stringify(allDevicesSorted))
    }
  }, [runGetDevicesForBps, selectedBpsId])

  useEffect(() => {
    fetchBpsDetails()
    fetchBpsUsers()
  }, [fetchBpsDetails, fetchBpsUsers])

  useEffect(() => {
    fetchDevicesForBps()
  }, [fetchDevicesForBps])

  const handleCloseEditDeviceDialog = () => {
    setIsEditDeviceDialogOpen(false)
  }

  const handleCloseEditBpsDialog = () => {
    setIsEditBpsDialogOpen(false)
  }

  const handleCloseEditGasDialog = () => {
    setIsEditGasDialogOpen(false)
  }

  const updateDeviceParams = async (request: Device) => {
    setUpdateParamsConfirmButtonDisabled(true)

    if (!selectedBpsId || !bpsParameters?.id) {
      return
    }

    setIsUpdateNominationsAfterDeviceUpdateDialogOpen(false)

    await runUpdateDevice({
      id: selectedBpsId,
      updateDeviceRequest: request,
    })

    setRecalculatingNominationsDialogOpen(false)

    selectedIdOld.current = -1
    fetchBpsDetails()
    fetchDevicesForBps()
    setUpdateParamsConfirmButtonDisabled(false)
    handleCloseEditDeviceDialog()
  }

  const handleUpdateReg = async (updatedDevice: Device) => {
    if (!selectedBpsId) {
      return
    }

    const originalDevice = originalDevices.current?.find((item) => item.id === updatedDevice.id)

    if (
      originalDevice?.p_reg_min_mw !== updatedDevice.p_reg_min_mw ||
      originalDevice?.p_reg_max_mw !== updatedDevice.p_reg_max_mw
    ) {
      updateDeviceRequest.current = updatedDevice
      setIsUpdateNominationsAfterDeviceUpdateDialogOpen(true)
      return
    }

    await updateDeviceParams(updatedDevice)
  }

  const handleUpdateDeviceDos = (updatedDevice: Device) => {
    if (!selectedBpsId) {
      return
    }

    const originalDevice = originalDevices.current?.find((item) => item.id === updatedDevice.id)

    if (
      originalDevice?.p_dos_min_mw !== updatedDevice.p_dos_min_mw ||
      originalDevice?.p_dos_max_mw !== updatedDevice.p_dos_max_mw
    ) {
      updateDeviceRequest.current = updatedDevice
      setIsUpdateDeviceDosDialogOpen(true)
      return
    }

    handleUpdateReg(updatedDevice)
  }

  const updateBpsParams = async (request: UpdateBpsParametersExtendedRequest) => {
    if (!selectedBpsId || !bpsParameters?.id) {
      return
    }

    setUpdateParamsConfirmButtonDisabled(true)
    const result = (await runUpdateBpsV2({id: bpsParameters?.id, updateBpsParametersExtendedRequest: request})).data
    setBpsParameters(result?.parameters)

    updateBpsRequest.current = undefined
    setUpdateParamsConfirmButtonDisabled(false)
    handleCloseEditBpsDialog()
  }

  const handleConfirmBpsUpdate = async (request: UpdateBpsParametersExtendedRequest) => {
    // if (request.tvs_mw !== bpsParameters?.tvs_mw || request.ovs_mw !== bpsParameters?.ovs_mw) {
    //   updateBpsRequest.current = request
    //   setIsUpdateNominationsAfterTvsUpdateDialogOpen(true)
    // } else {
    //   updateBpsParams(request)
    // }

    // disable recalculation of nomination after TSV change -> UpdateNominationsAfterTvsUpdateDialog will not open - BGA-1014
    updateBpsParams(request)
  }

  const updateGas = async (request: UpdateGasRequest) => {
    setUpdateParamsConfirmButtonDisabled(true)
    if (!selectedBpsId || !bpsParameters?.id) {
      return
    }

    const result = (await runUpdateGas({id: bpsParameters?.id, updateGasRequest: request})).data
    setBpsParameters(result?.parameters)

    setUpdateParamsConfirmButtonDisabled(false)
    handleCloseEditGasDialog()
  }

  const handleConfirmGasUpdate = async (request: UpdateGasRequest) => {
    updateGas(request)
  }

  const handleBpsUpdateError = () => {
    setIsBpsUpdateErrorDialogOpen(true)
  }

  const handleBpsUpdateErrorClose = () => {
    setIsBpsUpdateErrorDialogOpen(false)
  }

  const handleUpdateNominationsAfterTvsUpdateDialogConfirm = () => {
    setIsUpdateNominationsAfterTvsUpdateDialogOpen(false)
    updateBpsRequest.current && updateBpsParams(updateBpsRequest.current)
  }

  const handleUpdateNominationsAfterTvsUpdateDialogClose = () => {
    setIsUpdateNominationsAfterTvsUpdateDialogOpen(false)
  }

  const handleUpdateNominationsAfterDeviceUpdateDialogClose = async () => {
    setIsUpdateNominationsAfterDeviceUpdateDialogOpen(false)
  }

  const handleUpdateDosDialogConfirm = () => {
    setIsUpdateDeviceDosDialogOpen(false)

    if (updateDeviceRequest.current) {
      handleUpdateReg(updateDeviceRequest.current)
    }
  }

  const handleUpdateDosDialogClose = () => {
    setIsUpdateDeviceDosDialogOpen(false)
  }

  const handleUpdateNominationsAfterDeviceUpdateDialogConfirm = async () => {
    if (!selectedBpsId || updateDeviceRequest.current === undefined) {
      return
    }

    setRecalculatingNominationsDialogOpen(true)
    await updateDeviceParams(updateDeviceRequest.current)
  }

  return (
    <>
      <Stack style={{marginTop: '10px'}}>
        {bpsParameters && (
          <BpsTable
            bpsParams={bpsParameters}
            onEditBps={() => {
              setIsEditBpsDialogOpen(true)
            }}
            // in MW
            minVykonDos={transformToKwWithEndKw(getDosMinMw(getKgjDevices(devicesForBps)))}
            maxVykonDos={transformToKwWithEndKw(getDosMaxMw(getKgjDevices(devicesForBps)))}
            minVykonReg={transformToKwWithEndKw(getRegMinMw(getKgjDevices(devicesForBps)))}
            maxVykonReg={transformToKwWithEndKw(getRegMaxMw(getKgjDevices(devicesForBps)))}
          />
        )}
      </Stack>
      <Stack style={{marginTop: '30px'}}>
        {bpsParameters && (
          <GasTable
            bpsParams={bpsParameters}
            onEditGas={() => {
              setIsEditGasDialogOpen(true)
            }}
          />
        )}
      </Stack>
      <Stack style={{marginTop: '30px'}}>
        {devicesForBps && (
          <DeviceTable
            devices={devicesForBps}
            onEdit={(id) => {
              setDeviceId(id)
              setIsEditDeviceDialogOpen(true)
            }}
          />
        )}
      </Stack>

      <Typography marginTop={3} sx={{fontSize: '20px', fontWeight: '500'}}>
        Primárny kontakt
      </Typography>
      <Stack direction="column" justifyContent="flex-start" marginTop={1} sx={{gap: '5px'}}>
        <Box>
          <Typography>Manažér: {bpsParameters?.manager?.name ?? 'neuvedený'}</Typography>
        </Box>
        <Box>
          <Typography>Operátor: {bpsParameters?.operator?.name ?? 'neuvedený'}</Typography>
        </Box>
      </Stack>

      <Typography marginTop={5} sx={{fontSize: '20px', fontWeight: '500'}}>
        Členovia
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" sx={{padding: '20px 0'}}>
          <TableHead>
            <StyledTableHeadRow>
              <TableCell>Meno</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Telefónne číslo</TableCell>
              <TableCell>Emailové notifikácie</TableCell>
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {bpsMembers?.map((item) => (
              <StyledTableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.phone_number}</TableCell>
                <TableCell>
                  {item.notify_by_email ? (
                    <Check fontSize="small" color="success" />
                  ) : (
                    <Clear fontSize="small" color="error" />
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isEditBpsDialogOpen && bpsParameters && (
        <EditBpsDialog
          open={isEditBpsDialogOpen}
          onClose={handleCloseEditBpsDialog}
          onConfirm={handleConfirmBpsUpdate}
          bpsParamsExtended={bpsParameters}
          onErrorDefaultMw={handleBpsUpdateError}
          disableConfirmButton={updateParamsConfirmButtonDisabled}
        />
      )}

      {isEditGasDialogOpen && bpsParameters && (
        <EditGasDialog
          open={isEditGasDialogOpen}
          onClose={handleCloseEditGasDialog}
          onConfirm={handleConfirmGasUpdate}
          bpsParamsExtended={bpsParameters}
          disableConfirmButton={updateParamsConfirmButtonDisabled}
        />
      )}

      {isEditDeviceDialogOpen && devicesForBps && (
        <EditDeviceDialog
          open={isEditDeviceDialogOpen}
          onClose={handleCloseEditDeviceDialog}
          onConfirm={handleUpdateDeviceDos}
          device={devicesForBps.find((item) => item.id === diviceId)}
          disableConfirmButton={updateParamsConfirmButtonDisabled}
        />
      )}

      {isBpsUpdateErrorDialogOpen && (
        <ErrorUpdatingBpsDialog
          open={isBpsUpdateErrorDialogOpen}
          onClose={handleBpsUpdateErrorClose}
          pInstMwValue={transformToKwWithEndKw(bpsParameters?.p_inst_mw)}
        />
      )}

      {isUpdateNominationsAfterTvsUpdateDialogOpen && (
        <UpdateNominationsAfterTvsUpdateDialog
          open={isUpdateNominationsAfterTvsUpdateDialogOpen}
          onClose={handleUpdateNominationsAfterTvsUpdateDialogClose}
          onConfirm={handleUpdateNominationsAfterTvsUpdateDialogConfirm}
        />
      )}

      {isUpdateNominationsAfterDeviceUpdateDialogOpen && (
        <UpdateNominationsAfterDeviceUpdateDialog
          open={isUpdateNominationsAfterDeviceUpdateDialogOpen}
          onClose={handleUpdateNominationsAfterDeviceUpdateDialogClose}
          onConfirm={handleUpdateNominationsAfterDeviceUpdateDialogConfirm}
        />
      )}

      {isUpdateDeviceDosDialogOpen && (
        <UpdateDeviceDosDialog
          open={isUpdateDeviceDosDialogOpen}
          onClose={handleUpdateDosDialogClose}
          onConfirm={handleUpdateDosDialogConfirm}
        />
      )}

      {recalculatingNominationsDialogOpen && (
        <RecalculatingNominationsDialog open={recalculatingNominationsDialogOpen} />
      )}
    </>
  )
}

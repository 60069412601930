import React, {useState, useEffect} from 'react'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {getReportSnapshot, recalculateReport} from '../../api/reportApi'
import {BpsReportSnapshot} from '../../api/generated'
import {
  Stack,
  styled,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Alert,
  Container,
  Button,
} from '@mui/material'
import dayjs from 'dayjs'
import {BpsSelector} from '../dashboard/BpsSelector'
import {DatePicker} from '../dashboard/DatePicker'
import {Create} from '@mui/icons-material'
import {ConfirmRecalculateReportDialog} from './ConfirmRecalculateReportDialog'

const StyledTableHeadRow = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

const periodsSummaryRowHeaders = [
  'Denná výroba [MWh]',
  'Max. odchýlka [MW]',
  '15 min. minimum [MW]',
  '15 min. maximum [MW]',
]
const oomRowHeaders = ['Dodávka OOM', 'Odber OOM']
const metricsRowHeaders = ['Výroba na svorkách', 'Výroba z FVE', 'TVS', 'OVS']
const realProductionRowHeaders = ['Predaj elektriny [MWh]', 'Predaj elektriny [EUR]', 'Priemerná cena [EUR]']
const summaryRowHeaders = [
  'Obchodný kontrakt',
  'Odchýlka',
  'Náklad na palivo',
  'Doplatok *',
  'SPOLU',
  'Odchýlka vrátane nákladov na palivo',
  'Odchýlka vrátane nákladov na palivo a doplatku',
]
const rcRowHeaders = [
  'Odchýlka [EUR]',
  'Náklad na palivo [EUR]',
  'Rozdiel doplatok [EUR]',
  'Súčet [EUR]',
  'Súčet [EUR/MWh]',
  'Čas v aktívnom riadení [hod]',
]

export const ReportSnapshotView: React.FC = () => {
  const {run: runGetReportSnapshot} = useAsyncMethodWithErrorHandling(getReportSnapshot)
  const {run: runRecalculateReport} = useAsyncMethodWithErrorHandling(recalculateReport)

  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: selectedDate, setData: setSelectedDate} = useBucketStore('selectedDate')

  const [bpsReport, setBpsReport] = useState<BpsReportSnapshot | null>(null)
  const [isConfirmRecalculateDialogOpen, setIsConfirmRecalculateDialogOpen] = useState<boolean>(false)
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false)

  const fetchReportSnapshot = React.useCallback(async () => {
    const normalizedDate = selectedDate.substring(0, 10)
    const result = (
      await runGetReportSnapshot({
        dateFrom: normalizedDate,
        dateTo: normalizedDate,
        bpsId: selectedBpsId ? selectedBpsId : 0,
      })
    ).data
    setBpsReport(result?.bpsReport || null)
  }, [runGetReportSnapshot, selectedBpsId, selectedDate])

  const initRecalculateReport = React.useCallback(async () => {
    setIsConfirmDisabled(true)
    const normalizedDate = selectedDate.substring(0, 10)
    const result = (
      await runRecalculateReport({
        date: normalizedDate,
        bpsId: selectedBpsId ? selectedBpsId : 0,
      })
    ).data
    setBpsReport(result?.bpsReport || null)
    setIsConfirmRecalculateDialogOpen(false)
    setIsConfirmDisabled(false)
  }, [runRecalculateReport, selectedBpsId, selectedDate])

  const validateButtonVisibility = (): string => {
    const dateInThePast = dayjs(selectedDate).isBefore(dayjs(), 'day')
    const isTodayAfterReportSent =
      dayjs(selectedDate).isSame(dayjs(), 'day') && dayjs().isAfter(dayjs().hour(13).minute(0))
    return dateInThePast || isTodayAfterReportSent ? 'inline-flex' : 'none'
  }

  useEffect(() => {
    fetchReportSnapshot()
  }, [fetchReportSnapshot])

  return (
    <>
      <Container maxWidth="xs" sx={{justifyContent: 'center', display: 'flex', marginBottom: '12px'}}>
        <BpsSelector />
      </Container>
      <Container maxWidth="xs" sx={{justifyContent: 'center', display: 'flex', marginBottom: '12px'}}>
        <DatePicker value={selectedDate} onChange={setSelectedDate} showToolbar={false} />
      </Container>
      <Stack>
        <Stack direction="row" sx={{marginBottom: '1em'}}>
          <Button
            endIcon={<Create fontSize="large" />}
            onClick={() => setIsConfirmRecalculateDialogOpen(true)}
            sx={{display: validateButtonVisibility}}
          >
            Prepočítať report
          </Button>
        </Stack>
        {bpsReport === null && <Alert severity="info">Report pre tento deň nebol vygenerovaný</Alert>}
        {bpsReport && (
          <>
            {bpsReport.hasOkteSupplySubstituteValues && (
              <Alert severity="error" sx={{marginBottom: '8px'}}>
                OKTE dáta pre dodávku obsahujú náhradné hodnoty. Report je generovaný na základe dát z Diris meraní.
              </Alert>
            )}
            {bpsReport.hasOkteOfftakeSubstituteValues && (
              <Alert severity="error" sx={{marginBottom: '8px'}}>
                OKTE dáta pre spotrebu obsahujú náhradné hodnoty.
              </Alert>
            )}
            <div style={{marginBottom: '20px'}}>
              <TableContainer component={Paper} sx={{width: 'fit-content', maxWidth: '100%'}}>
                <Table size="small" sx={{padding: '20px 0', tableLayout: 'auto', width: 'auto'}}>
                  <TableHead>
                    <StyledTableHeadRow>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}} />
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Perióda</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Nominácia</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Dodávka</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Odchýlka *</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Odchýlka [%]</TableCell>
                    </StyledTableHeadRow>
                  </TableHead>
                  <TableBody>
                    {bpsReport.periodsSummary?.map((e, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {index > periodsSummaryRowHeaders.length ? '' : periodsSummaryRowHeaders[index]}
                        </TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>
                          {e.period ? dayjs(e.period.toString(), 'HH:mm:ss').format('HH:mm') : ''}
                        </TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.nomination}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.supply}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.deviation}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.deviationPercentage}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <span>* Odchýlka je vypočítaná ako absolútna odchýlka voči nomináciam cez všetky 15min periódy</span>
            </div>
            <div style={{marginBottom: '20px'}}>
              <TableContainer component={Paper} sx={{width: 'fit-content', maxWidth: '100%'}}>
                <Table size="small" sx={{padding: '20px 0', tableLayout: 'auto', width: 'auto'}}>
                  <TableHead>
                    <StyledTableHeadRow>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}} />
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>OKTE [MWh]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Diris meranie [MWh]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Rozdiel [MWh]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Rozdiel [%]</TableCell>
                    </StyledTableHeadRow>
                  </TableHead>
                  <TableBody>
                    {bpsReport.oom?.map((e, index) => (
                      <TableRow key={index}>
                        <TableCell>{index > oomRowHeaders.length ? '' : oomRowHeaders[index]}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.okte}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.diris}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.difference}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.differencePercentage}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div style={{marginBottom: '20px'}}>
              <TableContainer component={Paper} sx={{width: 'fit-content', maxWidth: '100%'}}>
                <Table size="small" sx={{padding: '20px 0', tableLayout: 'auto', width: 'auto'}}>
                  <TableHead>
                    <StyledTableHeadRow>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}} />
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>[MWh]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>[%]</TableCell>
                    </StyledTableHeadRow>
                  </TableHead>
                  <TableBody>
                    {bpsReport.metrics?.map((e, index) => (
                      <TableRow key={index}>
                        <TableCell>{index > metricsRowHeaders.length ? '' : metricsRowHeaders[index]}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.mwh}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.percentage}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div style={{marginBottom: '20px'}}>
              <TableContainer component={Paper} sx={{width: 'fit-content', maxWidth: '100%'}}>
                <Table size="small" sx={{padding: '20px 0', tableLayout: 'auto', width: 'auto'}}>
                  <TableHead>
                    <StyledTableHeadRow>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Reálna výroba *</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Fixný kontrakt</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>SPOT</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>SPOLU</TableCell>
                    </StyledTableHeadRow>
                  </TableHead>
                  <TableBody>
                    {bpsReport.realProduction?.map((e, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {index > realProductionRowHeaders.length ? '' : realProductionRowHeaders[index]}
                        </TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.fixContract}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.spot}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.overall}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <span>* Výpočet z reálnej výroby, nie podľa nominácie</span>
            </div>
            <div style={{marginBottom: '20px'}}>
              <TableContainer component={Paper} sx={{width: 'fit-content', maxWidth: '100%'}}>
                <Table size="small" sx={{padding: '20px 0', tableLayout: 'auto', width: 'auto'}}>
                  <TableHead>
                    <StyledTableHeadRow>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>SUMARIZÁCIA</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Nominácia [EUR]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Realita [EUR]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Rozdiel [EUR]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Realita [EUR/MWh]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Rozdiel [EUR/MWh]</TableCell>
                    </StyledTableHeadRow>
                  </TableHead>
                  <TableBody>
                    {bpsReport.summary?.map((e, index) => (
                      <TableRow key={index}>
                        <TableCell>{index > summaryRowHeaders.length ? '' : summaryRowHeaders[index]}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.nomination}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.reality}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.difference}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.realityPerMwh}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.differencePerMwh}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <span>*Výpočet doplatku je bez zohľadnenia OVS</span>
            </div>
            <div style={{marginBottom: '20px'}}>
              <TableContainer component={Paper} sx={{width: 'fit-content', maxWidth: '100%'}}>
                <Table size="small" sx={{padding: '20px 0', tableLayout: 'auto', width: 'auto'}}>
                  <TableHead>
                    <StyledTableHeadRow>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}} />
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Celkovo (RBO)</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Bez aktívneho riadenia (RBO)</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Aktívne riadenie (RBO)</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Aktívne riadenie AB1</TableCell>
                    </StyledTableHeadRow>
                  </TableHead>
                  <TableBody>
                    {bpsReport.remoteControlSummary?.map((e, index) => (
                      <TableRow key={index}>
                        <TableCell>{index > rcRowHeaders.length ? '' : rcRowHeaders[index]}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.RBOSum}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.RBOInactive}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.RBOActive}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.AB1Active}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div style={{marginBottom: '20px'}}>
              <p>Zoznam periód s významnou zápornou odchýlkou</p>
              <TableContainer component={Paper} sx={{width: 'fit-content', maxWidth: '100%'}}>
                <Table size="small" sx={{padding: '20px 0', tableLayout: 'auto', width: 'auto'}}>
                  <TableHead>
                    <StyledTableHeadRow>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Perióda</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Nominácia [MW]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Dodávka [MW]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Odchylka [MW]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Odchylka [%]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Odchylka [€] *</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>
                        Po zohľadnení doplatku a ceny paliva [€]
                      </TableCell>
                    </StyledTableHeadRow>
                  </TableHead>
                  <TableBody>
                    {bpsReport.negativeDeviationPeriods?.map((e, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>
                          {e.period ? dayjs(e.period.toString(), 'HH:mm:ss').format('HH:mm') : ''}
                        </TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.nomination}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.supply}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.deviation}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.deviationPercentage}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.deviationPrice}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>
                          {e.deviationPriceIncludedGasAndBackPay}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <span>* Odchýlka [€] vypočítaná ako rozdiel v cene voči predaju za SPOT</span>
            </div>
            <div style={{marginBottom: '20px'}}>
              <p>Zoznam periód s významnou kladnou odchýlkou</p>
              <TableContainer component={Paper} sx={{width: 'fit-content', maxWidth: '100%'}}>
                <Table size="small" sx={{padding: '20px 0', tableLayout: 'auto', width: 'auto'}}>
                  <TableHead>
                    <StyledTableHeadRow>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Perióda</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Nominácia [MW]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Dodávka [MW]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Odchylka [MW]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Odchylka [%]</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>Odchylka [€] *</TableCell>
                      <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>
                        Po zohľadnení doplatku a ceny paliva [€]
                      </TableCell>
                    </StyledTableHeadRow>
                  </TableHead>
                  <TableBody>
                    {bpsReport.positiveDeviationPeriods?.map((e, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>
                          {e.period ? dayjs(e.period.toString(), 'HH:mm:ss').format('HH:mm') : ''}
                        </TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.nomination}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.supply}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.deviation}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.deviationPercentage}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>{e.deviationPrice}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap', width: 'auto'}}>
                          {e.deviationPriceIncludedGasAndBackPay}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <span>* Odchýlka [€] vypočítaná ako rozdiel v cene voči predaju za SPOT</span>
            </div>
          </>
        )}
      </Stack>

      <ConfirmRecalculateReportDialog
        open={isConfirmRecalculateDialogOpen}
        onClose={() => setIsConfirmRecalculateDialogOpen(false)}
        date={selectedDate.substring(0, 10)}
        onConfirm={initRecalculateReport}
        confirmButtonDisabled={isConfirmDisabled}
      />
    </>
  )
}

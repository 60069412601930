import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from '@mui/material'
import dayjs from 'dayjs'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {createNominationV2, getNominationsV2} from '../api/nominationsApi'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore, useBucketStoreContext} from '../utils/BucketStoreContext'
import {ConfirmCreateNominationDialog} from './ConfirmCreateNominationDialog'
import {useNavigate} from 'react-router'
import {absolutePath} from '../navigation/utils'
import {getBpsDetailsV2, getDevicesForBps} from '../api/bpsApi'
import {BpsParametersExtended, Device, DeviceTypeEnum, NominationDevice, NominationExtended} from '../api/generated'
import {NominationExtendedBase} from '../types/nominationExtended'
import {NominationDialogIntervalSelector} from '../components/NominationDialogIntervalSelector'
import {NominationDialogPeriods} from '../components/NominationDialogPeriods'
import {NominationDialogDevicesTable} from './NominationDialogDevicesTable'
import {NominationDialogPps} from './NominationDialogPps'
import {NominationDialogTechParams} from '../components/NominationDialogTechParams'
import {NominationDialogStatusSelector} from './NominationDialogStatusSelector'
import {NominationIsOutOfRegLimitDialog} from './NominationIsOutOfRegLimitDialog'
import {
  getDateWithTimeNow,
  periodToTimestamp,
  timestampToPeriod,
  transformToKwNumber,
  transformToMwNumber,
} from '../utils/common'
import {NominationDialogOom} from './NominationDialogOom'
import {
  NOMINATION_VALUE_TYPE,
  DATE_AND_TIME_FORMAT,
  NOMINAITON_TECH_PARAMS_COLORS,
  NOT_SELECTED_PRODUCTION_STATUS,
} from '../utils/constants'
import {CauseOfOutage, NominationInterval} from '../utils/enums'
import {
  calculateMaxSliderValue,
  calculateMinSliderValue,
  getDefaultNominationValue,
  getMinMaxOfNominations,
  getOvsKW,
  nominationTechParamBands,
  nominationValueTextFieldBorderColor,
} from '../utils/powerUnitControl'
import {checkOverlap, merge} from './nominations-merge-utils'
import {NominationRangeBar} from './NominationRangeBar'

type AddNominationDialogProps = {
  open: boolean
  onClose: () => void
  baseDate: string
  isFailureTime: boolean
}

const getDefaultNominationExtendedData = (baseDate: string, bpsId: number): NominationExtendedBase => ({
  time_from: dayjs(baseDate).startOf('day').format(DATE_AND_TIME_FORMAT).toString(),
  time_to: dayjs(baseDate).add(1, 'day').startOf('day').format(DATE_AND_TIME_FORMAT).toString(),
  value_oom: undefined,
  value_pps: undefined,
  note: '',
  bps_id: bpsId,
  p_reg_min_mw: 0,
  p_reg_max_mw: 0,
  nomination_devices: [],
  production_status: NOT_SELECTED_PRODUCTION_STATUS,
})

export const AddNominationDialog: React.FC<AddNominationDialogProps> = ({open, onClose, baseDate, isFailureTime}) => {
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: nominations, setData: setNominations} = useBucketStore('nominationsExtended')
  const {data: bpsNominationTableOpen, setData: setBpsNominationTableOpen} = useBucketStore('bpsNominationTableOpen')
  const {data: productionStatus} = useBucketStore('productionStatusEnum')

  const [bpsParameters, setBpsParameters] = useState<BpsParametersExtended>()
  const [devicesForBps, setDevicesForBps] = useState<undefined | Device[]>(undefined)
  const [selectedDevices, setSelectedDevices] = useState<number[] | undefined>(undefined)
  const [enableOomSubmit, setEnableOomSubmit] = useState<boolean>(true)
  const [enablePpsSubmit, setEnablePpsSubmit] = useState<boolean>(true)
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState<boolean>(false)

  const [isConfirmCreateDialogOpen, setIsConfirmCreateDialogOpen] = useState<boolean>(false)
  const [isNominationIsOutOfRegLimitDialogOpen, setIsNominationIsOutOfRegLimitDialogOpen] = useState<boolean>(false)

  const [defaultNominationsPropertiesWereSet, setDefaultNominationsPropertiesWereSet] = useState<boolean>(false)

  // used ONLY to get existingRecords for default OOM and PpS values
  const editedNominationRef = useRef<NominationExtendedBase>(
    getDefaultNominationExtendedData(baseDate, selectedBpsId ?? -1),
  )
  const [editedNomination, setEditedNomination] = useState<NominationExtendedBase>(() =>
    // TODO: handle undefined selectedBpsId
    getDefaultNominationExtendedData(baseDate, selectedBpsId ?? -1),
  )

  const bpsInstMw = useRef(-1)

  const [selectedInterval, setSelectedInterval] = useState(
    isFailureTime ? NominationInterval.FifteenMinutes : NominationInterval.AllDay,
  )
  const [selectedEnterFault, setSelectedEnterFault] = useState<boolean>(true)
  const [selectedCause, setSelectedCause] = useState<CauseOfOutage | string>('')

  const {run: runGetNominationsV2} = useAsyncMethodWithErrorHandling(getNominationsV2)
  const {run: runGetBpsDetailsV2} = useAsyncMethodWithErrorHandling(getBpsDetailsV2)
  const {run: runGetDevicesForBps} = useAsyncMethodWithErrorHandling(getDevicesForBps)

  const startOfDayTimestamp = dayjs(baseDate).startOf('day')
  const endOfDayTimestamp = dayjs(baseDate).endOf('day').add(1, 'ms')

  const navigation = useNavigate()
  const {clearAllBuckets} = useBucketStoreContext()

  const date = new Date(baseDate).toLocaleDateString('sk')

  const fetchNominations = React.useCallback(async () => {
    if (selectedBpsId) {
      const normalizedDate = baseDate.substring(0, 10)
      const result = (await runGetNominationsV2({dateFrom: normalizedDate, bpsId: selectedBpsId})).data

      setNominations(result?.nominations ?? [])
    }
  }, [selectedBpsId, baseDate, runGetNominationsV2, setNominations])

  const fetchBpsDetails = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetBpsDetailsV2({id: selectedBpsId})).data
      setBpsParameters(result?.parameters)
    }
  }, [runGetBpsDetailsV2, selectedBpsId])

  const fetchDevicesForBps = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetDevicesForBps({id: selectedBpsId})).data
      setDevicesForBps(
        result?.devices.filter((device) => device.type === DeviceTypeEnum.Chp).sort((a, b) => (a.id > b.id ? 1 : -1)),
      )
    }
  }, [runGetDevicesForBps, selectedBpsId])

  useEffect(() => {
    fetchBpsDetails()
  }, [fetchBpsDetails])

  useEffect(() => {
    fetchDevicesForBps()
  }, [fetchDevicesForBps])

  const editProperties = (changes: Partial<NominationExtendedBase>) => {
    editedNominationRef.current = {...editedNominationRef.current, ...changes}
    setEditedNomination((prev) => ({...prev, ...changes}))
  }

  useEffect(() => {
    if (open) {
      // TODO: handle undefined selectedBpsId
      setEditedNomination(getDefaultNominationExtendedData(baseDate, selectedBpsId ?? -1))
      setSelectedInterval(isFailureTime ? NominationInterval.FifteenMinutes : NominationInterval.AllDay)
      editProperties({is_failure: isFailureTime})

      if (isFailureTime) {
        const baseDateWithTime = getDateWithTimeNow(baseDate)
        const timeFrom = periodToTimestamp(timestampToPeriod(baseDateWithTime, baseDate), baseDate).periodStart

        editProperties({
          time_from: dayjs(timeFrom).format(DATE_AND_TIME_FORMAT).toString(),
          time_to: dayjs(timeFrom).add(2, 'hour').add(15, 'minute').format(DATE_AND_TIME_FORMAT).toString(),
        })
      }
    }
  }, [baseDate, open, selectedBpsId, isFailureTime])

  const getSelectedDevicesInstMw = useCallback(
    (selectedDevicesToUse: number[]) =>
      devicesForBps
        ?.filter((device) => selectedDevicesToUse?.find((item) => item === device.id))
        .reduce(function (prev, current) {
          return prev + current.p_inst_mw
        }, 0),
    [devicesForBps],
  )

  const getCalculatedMaxValue = useCallback(
    (selectedDevicesArg: number[], nominationType: string) =>
      calculateMaxSliderValue(selectedDevicesArg, nominationType, bpsParameters, devicesForBps),
    [bpsParameters, devicesForBps],
  )

  const getCalculatedMinValue = (selectedDevicesArg: number[], nominationType: string) => {
    return calculateMinSliderValue(selectedDevicesArg, nominationType, bpsParameters, devicesForBps)
  }

  const setPRegValues = useCallback(() => {
    if (!devicesForBps || !selectedDevices) {
      return
    }

    const min = devicesForBps
      ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
      .reduce(function (prev, current) {
        return prev + current?.p_reg_min_mw
      }, 0)

    const max = devicesForBps
      ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
      .reduce(function (prev, current) {
        return prev + current?.p_reg_max_mw
      }, 0)

    editProperties({
      p_reg_min_mw: min,
      p_reg_max_mw: max,
    })
  }, [devicesForBps, selectedDevices])

  const createEmptyNominationDevice = (nominationId: number): NominationDevice => {
    return {
      device_id: nominationId,
      time_from: '', // will be set on BE
      time_to: '', // will be set on BE
      p_term_mw: 0,
      p_reg_min_mw: 0,
      p_reg_max_mw: 0,
      in_nomination: false,
    }
  }

  const calculatePTermMw = (deviceCharPInstMw: number) => {
    const sumPInstMwSelectedDevices = selectedDevices ? getSelectedDevicesInstMw(selectedDevices) ?? 0 : 0

    const firstPart = sumPInstMwSelectedDevices - ((editedNomination.value_oom ?? 0) / 100) * bpsInstMw.current
    const secondPart = deviceCharPInstMw / sumPInstMwSelectedDevices

    return (deviceCharPInstMw - firstPart * secondPart).toFixed(3)
  }

  const nominationValueBorderColor = (nominationType: string) => {
    return nominationValueTextFieldBorderColor(
      nominationType,
      devicesForBps,
      selectedDevices,
      bpsParameters,
      editedNomination,
    )
  }

  const createNominationDevice = (
    nominationId: number,
    pRegMinMw: number,
    pRegMaxMw: number,
    pInstMw: number,
  ): NominationDevice => ({
    device_id: nominationId,
    time_from: '',
    time_to: '',
    p_term_mw: Number(calculatePTermMw(pInstMw)),
    p_reg_min_mw: pRegMinMw,
    p_reg_max_mw: pRegMaxMw,
    in_nomination: true,
  })

  const getNominationsForDevices = (): NominationDevice[] | [] => {
    const nominationsDevices: NominationDevice[] = []

    devicesForBps?.forEach((device) => {
      const newNomination =
        selectedDevices?.indexOf(device.id) !== -1
          ? createNominationDevice(device.id, device.p_reg_min_mw, device.p_reg_max_mw, device.p_inst_mw)
          : createEmptyNominationDevice(device.id)

      nominationsDevices.push(newNomination)
    })

    return nominationsDevices
  }

  const handleCreate = async (isOutOfRegLimit: boolean) => {
    try {
      setIsConfirmDisabled(true)

      if (isOutOfRegLimit) {
        editedNomination.production_status = 3
      }

      // a copy of editedNomination to avoid showing changed values in ConfirmCreateNominationDialog
      const editedNominationCopy = {...editedNomination}

      editedNominationCopy.nomination_devices = getNominationsForDevices()
      editedNominationCopy.value_oom = transformToMwNumber(editedNomination.value_oom)
      editedNominationCopy.value_pps = transformToMwNumber(editedNomination.value_pps)
      if (editedNomination.note === '') {
        editedNominationCopy.note = isFailureTime && selectedEnterFault ? selectedCause : ''
      } else {
        editedNominationCopy.note =
          isFailureTime && selectedEnterFault ? selectedCause + ', ' + editedNomination.note : editedNomination.note
      }

      await createNominationV2(editedNominationCopy)

      // re-fetch only when bpsNominationTableOpen is open (when closed it will be fetched in NominationsTable)
      if (selectedBpsId && bpsNominationTableOpen) {
        fetchNominations()
      }
      setIsConfirmDisabled(false)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      // eslint-disable-next-line
      if ((error as any)?.response?.status == 401) {
        sessionStorage.clear()
        clearAllBuckets()
        navigation(absolutePath('login'))
      }
    }

    setBpsNominationTableOpen(true)
    setIsConfirmCreateDialogOpen(false)
    onClose()
  }

  const handleConfirmCreate = async () => {
    selectedDevices?.length === 0 ? handleCreate(true) : handleCreate(false)
  }

  const handleEnterFaultSelection = () => {
    editProperties({is_failure: selectedEnterFault ? false : true})
    setSelectedEnterFault(selectedEnterFault ? false : true)
  }

  const handleAllDaySelected = () => {
    setSelectedInterval(NominationInterval.AllDay)
    editProperties({
      time_from: startOfDayTimestamp.format(DATE_AND_TIME_FORMAT).toString(),
      time_to: endOfDayTimestamp.format(DATE_AND_TIME_FORMAT).toString(),
    })
  }

  const handleHoursIntervalSelected = () => {
    setSelectedInterval(NominationInterval.Hours)
    editProperties({
      time_from:
        dayjs(editedNomination.time_from).startOf('hour').format(DATE_AND_TIME_FORMAT).toString() ??
        startOfDayTimestamp.format(DATE_AND_TIME_FORMAT).toString(),
      time_to:
        dayjs(editedNomination.time_to)
          .add(-1, 'ms')
          .endOf('hour')
          .add(1, 'ms')
          .format(DATE_AND_TIME_FORMAT)
          .toString() ?? endOfDayTimestamp.format(DATE_AND_TIME_FORMAT).toString(),
    })
  }

  const handleFifteenMinutesIntervalSelected = () => {
    setSelectedInterval(NominationInterval.FifteenMinutes)
    editProperties({
      time_from: editedNomination.time_from ?? startOfDayTimestamp.format(DATE_AND_TIME_FORMAT).toString(),
      time_to: editedNomination.time_to ?? endOfDayTimestamp.format(DATE_AND_TIME_FORMAT).toString(),
    })
  }

  const handleSelectedCause = (cause: CauseOfOutage) => {
    setSelectedCause(cause)
  }

  const updateSliderValue = useCallback(
    (selectedDevicesNewValue: number[]) => {
      editProperties({value_pps: getCalculatedMaxValue(selectedDevicesNewValue, NOMINATION_VALUE_TYPE.Pps)})
    },
    [getCalculatedMaxValue],
  )

  const existingRecords = useMemo(() => {
    const overlappingRecords = nominations.filter((x) => checkOverlap(x, editedNominationRef.current))
    return merge(overlappingRecords)
  }, [nominations])

  // set initial selected Kjgs and default value for PpS and OOM
  useEffect(() => {
    if (devicesForBps?.length && selectedDevices === undefined) {
      const newValue = devicesForBps.filter((item) => item.in_service).map((item) => item.id)

      // set devices based on nomination with greatest OOM
      setSelectedDevices(
        (
          getMinMaxOfNominations(nominations, NOMINATION_VALUE_TYPE.Oom, false)?.maxNomination as NominationExtended
        )?.nomination_devices
          ?.filter((device) => device.in_nomination)
          .map((device) => device.device_id) ?? newValue,
      )

      bpsInstMw.current =
        devicesForBps
          ?.filter((item) => item.in_service)
          .reduce(function (prev, current) {
            return prev + current.p_inst_mw
          }, 0) ?? 0

      setPRegValues()
      updateSliderValue(newValue)
    } else if (devicesForBps?.length && !defaultNominationsPropertiesWereSet) {
      setPRegValues()
      setDefaultNominationsPropertiesWereSet(true)

      // set default values for PpS, OOM and production status
      editProperties({
        value_oom: Number(
          getDefaultNominationValue(
            NOMINATION_VALUE_TYPE.Oom,
            bpsParameters,
            devicesForBps ?? [],
            selectedDevices ?? [],
            existingRecords,
            false,
          ),
        ),
        value_pps: Number(
          getDefaultNominationValue(
            NOMINATION_VALUE_TYPE.Pps,
            bpsParameters,
            devicesForBps ?? [],
            selectedDevices ?? [],
            existingRecords,
            false,
          ),
        ),
        production_status:
          getMinMaxOfNominations(nominations, NOMINATION_VALUE_TYPE.Oom, false)?.maxNomination?.production_status ??
          NOT_SELECTED_PRODUCTION_STATUS,
      })
    } else {
      setPRegValues()
    }
  }, [
    devicesForBps,
    selectedDevices,
    bpsParameters,
    existingRecords,
    nominations,
    defaultNominationsPropertiesWereSet,
    setPRegValues,
    updateSliderValue,
  ])

  const handleClickCheckBox = (id: number | undefined, checked: boolean) => {
    if (id === undefined) {
      return
    }

    let filtered
    if (checked) {
      // add to array of selected
      filtered = selectedDevices?.filter((item) => item !== id)
      const updated = filtered ?? []
      updated.push(id)
      setSelectedDevices(updated)
      updateSliderValue(updated)
    } else {
      // remove from array of selected
      filtered = selectedDevices?.filter((item) => item !== id)
      setSelectedDevices(filtered)
      filtered ? updateSliderValue(filtered) : updateSliderValue([])
    }

    const maxOrangeParamBand = nominationTechParamBands(
      NOMINAITON_TECH_PARAMS_COLORS.Orange,
      devicesForBps,
      filtered,
      bpsParameters?.tvs_mw,
      bpsParameters?.ovs_mw,
      bpsParameters?.tvs_variance_mw,
    )?.max

    // set OOM and PpS values as max of orange band
    editProperties({
      value_oom: maxOrangeParamBand,
      value_pps: Number(maxOrangeParamBand) + getOvsKW(bpsParameters),
    })
  }

  const isSelected = (id: number | undefined) => {
    return selectedDevices?.find((item) => item === id) !== undefined
  }

  const valueIsValid = () => {
    return (
      editedNomination.value_pps != undefined &&
      editedNomination.value_pps >= 0 &&
      editedNomination.value_oom != undefined &&
      editedNomination.value_oom >= 0 &&
      (isFailureTime && selectedEnterFault ? selectedCause !== '' : true) && // if it is isFailureTime and selectedEnterFault is true, selectedCause must be selected
      editedNomination.production_status !== NOT_SELECTED_PRODUCTION_STATUS
    )
  }

  const handleValueInPpsSliderChange = (valid: boolean) => {
    setEnablePpsSubmit(valid)
  }

  const handleValueInOomChange = (valid: boolean) => {
    setEnableOomSubmit(valid)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          {isFailureTime && selectedEnterFault ? 'Porucha:' : 'Nová nominácia:'} {date}
        </DialogTitle>
        <DialogContent>
          <Stack>
            <NominationDialogIntervalSelector
              handleEnterFaultSelection={handleEnterFaultSelection}
              handleAllDaySelected={handleAllDaySelected}
              handleFifteenMinutesIntervalSelected={handleFifteenMinutesIntervalSelected}
              handleHoursIntervalSelected={handleHoursIntervalSelected}
              selectedInterval={selectedInterval}
              selectedEnterFault={selectedEnterFault}
              isFailureTime={isFailureTime}
            />

            <NominationDialogPeriods
              editedNomination={editedNomination}
              onEditProperties={editProperties}
              selectedInterval={selectedInterval}
              isProfileEdit={false}
            />

            <NominationDialogStatusSelector
              editedNomination={editedNomination}
              productionStatusEnum={productionStatus}
              onValueChange={(value: number) => editProperties({production_status: value})}
            />

            <NominationDialogDevicesTable
              handleClickCheckBox={handleClickCheckBox}
              isSelected={isSelected}
              devicesForBps={devicesForBps}
            />

            <NominationRangeBar
              tvsVarianceMw={bpsParameters?.tvs_variance_mw}
              devicesForBps={devicesForBps ?? []}
              selectedDevices={selectedDevices ?? []}
            />

            <NominationDialogOom
              editedNomination={editedNomination}
              minValueOom={
                nominationTechParamBands(
                  NOMINAITON_TECH_PARAMS_COLORS.Orange,
                  devicesForBps,
                  selectedDevices,
                  bpsParameters?.tvs_mw,
                  bpsParameters?.ovs_mw,
                  bpsParameters?.tvs_variance_mw,
                )?.min ?? 0
              }
              maxValueOom={
                nominationTechParamBands(
                  NOMINAITON_TECH_PARAMS_COLORS.Orange,
                  devicesForBps,
                  selectedDevices,
                  bpsParameters?.tvs_mw,
                  bpsParameters?.ovs_mw,
                  bpsParameters?.tvs_variance_mw,
                )?.max ?? 0
              }
              minSliderValueOom={getCalculatedMinValue(
                selectedDevices ? selectedDevices : [],
                NOMINATION_VALUE_TYPE.Oom,
              )}
              maxSliderValueOom={getCalculatedMaxValue(
                selectedDevices ? selectedDevices : [],
                NOMINATION_VALUE_TYPE.Oom,
              )}
              onEditProperties={editProperties}
              onValueChanged={handleValueInOomChange}
              nominationValueBorderColor={nominationValueBorderColor}
              existingNominations={existingRecords}
              isProfileEdit={false}
              bpsParameters={bpsParameters ?? undefined}
            />

            <NominationDialogPps
              editedNomination={editedNomination}
              minValuePps={
                (nominationTechParamBands(
                  NOMINAITON_TECH_PARAMS_COLORS.Orange,
                  devicesForBps,
                  selectedDevices,
                  bpsParameters?.tvs_mw,
                  bpsParameters?.ovs_mw,
                  bpsParameters?.tvs_variance_mw,
                )?.min ?? 0) + (transformToKwNumber(bpsParameters?.ovs_mw) ?? 0)
              }
              maxValuePps={
                (nominationTechParamBands(
                  NOMINAITON_TECH_PARAMS_COLORS.Orange,
                  devicesForBps,
                  selectedDevices,
                  bpsParameters?.tvs_mw,
                  bpsParameters?.ovs_mw,
                  bpsParameters?.tvs_variance_mw,
                )?.max ?? 0) + (transformToKwNumber(bpsParameters?.ovs_mw) ?? 0)
              }
              mrcMw={bpsParameters?.max_reserved_capacity_mw ?? 0}
              onEditProperties={editProperties}
              onValueChanged={handleValueInPpsSliderChange}
              nominationValueBorderColor={nominationValueBorderColor}
            />

            <NominationDialogTechParams
              editedNomination={editedNomination}
              onEditProperties={editProperties}
              tvsMw={bpsParameters?.tvs_mw}
              ovsMw={bpsParameters?.ovs_mw}
              tvsVarianceMw={bpsParameters?.tvs_variance_mw}
              devicesForBps={devicesForBps ?? []}
              selectedDevices={selectedDevices ?? []}
              selectedEnterFault={selectedEnterFault}
              isFailureTime={isFailureTime}
              handleSelectedCause={(cause: CauseOfOutage) => handleSelectedCause(cause)}
              selectedCause={selectedCause}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button onClick={onClose} variant="outlined">
              Zrušiť
            </Button>
            <Button
              onClick={() => setIsConfirmCreateDialogOpen(true)}
              variant="contained"
              disabled={!valueIsValid() || !enablePpsSubmit || !enableOomSubmit || isConfirmDisabled}
              color={isFailureTime && selectedEnterFault ? 'error' : 'primary'}
            >
              Potvrdiť
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <ConfirmCreateNominationDialog
        open={isConfirmCreateDialogOpen}
        onClose={() => setIsConfirmCreateDialogOpen(false)}
        bpsNominationValuePps={editedNomination.value_pps ? editedNomination.value_pps : 0}
        bpsNominationValueOom={editedNomination.value_oom ? editedNomination.value_oom : 0}
        nomination={editedNomination}
        onConfirm={handleConfirmCreate}
        selectedEnterFault={selectedEnterFault}
        isFailureTime={isFailureTime}
        confirmButtonDisabled={isConfirmDisabled}
        selectedCause={selectedCause}
      />

      <NominationIsOutOfRegLimitDialog
        open={isNominationIsOutOfRegLimitDialogOpen}
        onClose={() => setIsNominationIsOutOfRegLimitDialogOpen(false)}
        onConfirm={() => handleCreate(true)}
        confirmButtonDisabled={isConfirmDisabled}
      />
    </>
  )
}

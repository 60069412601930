import React, {useEffect, useState} from 'react'
import {
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  IconButton,
  Collapse,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {useBucketStore} from '../../utils/BucketStoreContext'
import {Add, Close, Create} from '@mui/icons-material'
import {ProfileSelector} from './ProfileSelector'
import {CreateProfileDialog} from '../CreateProfileDialog'
import {getProfileNominationsV2} from '../../api/profilesApi'
import {calculatePeriodFromTime} from '../../utils/format'
import {DeleteProfileDialog} from '../DeleteProfileDialog'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {UpdateProfileDialog} from '../UpdateProfileDialog'
import {isBpsEditable} from '../../utils/validateUserRolesAndBps'
import {transformToKwNumber} from '../../utils/common'

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(4n+1)': {
    backgroundColor: theme.palette.grey[100],
  },
}))

const StyledTableHeadRow = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.grey[300],
}))

export const ProfilesTable: React.FC = () => {
  const {data: userInfo} = useBucketStore('userInfo')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: selectedProfile} = useBucketStore('selectedProfile')
  const {data: profileNominationsWithDevices, setData: setProfileNominationsWithDevices} = useBucketStore(
    'profileNominationsWithDevices',
  )

  const [isCreateProfileDialogOpen, setIsCreateProfileDialogOpen] = useState<boolean>(false)
  const [isDeleteProfileDialogOpen, setIsDeleteProfileDialogOpen] = useState<boolean>(false)
  const [isUpdateProfileDialogOpen, setIsUpdateProfileDialogOpen] = useState<boolean>(false)
  const [open, setOpen] = useState<number | undefined>(-1)

  const {run: runGetProfileNominationsV2} = useAsyncMethodWithErrorHandling(getProfileNominationsV2)

  const fetchProfileNominationsWithDevices = React.useCallback(async () => {
    if (selectedBpsId) {
      if (selectedProfile?.id && selectedProfile.bps_id === selectedBpsId) {
        const fetchNominationsResult = (
          await runGetProfileNominationsV2({bpsId: selectedBpsId, profileId: selectedProfile.id})
        ).data
        setProfileNominationsWithDevices(fetchNominationsResult?.nomination_profiles ?? [])
      } else {
        setProfileNominationsWithDevices([])
      }
    }
  }, [runGetProfileNominationsV2, selectedBpsId, selectedProfile, setProfileNominationsWithDevices])

  useEffect(() => {
    fetchProfileNominationsWithDevices()
  }, [fetchProfileNominationsWithDevices])

  const handleCloseCreateProfileDialog = () => {
    setIsCreateProfileDialogOpen(false)
  }

  const handleCloseDeleteProfileDialog = () => {
    setIsDeleteProfileDialogOpen(false)
  }

  const handleCloseUpdateProfileDialog = () => {
    setIsUpdateProfileDialogOpen(false)
  }

  const formatTimeToHourMinutes = (time: string) => {
    return time.substring(0, time.lastIndexOf(':'))
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="start"
        marginTop={3}
        marginBottom={2}
        sx={{gap: '12px'}}
      >
        <Stack direction="row" flexWrap="wrap" sx={{gap: '12px', flex: 1}}>
          <Button
            endIcon={<Add fontSize="large" />}
            onClick={() => setIsCreateProfileDialogOpen(true)}
            disabled={!isBpsEditable(userInfo, selectedBpsId)}
          >
            Vytvoriť profil
          </Button>

          {selectedProfile && (
            <Button
              endIcon={<Close fontSize="large" />}
              onClick={() => setIsDeleteProfileDialogOpen(true)}
              color="error"
              disabled={!isBpsEditable(userInfo, selectedBpsId)}
            >
              Vymazať profil
            </Button>
          )}

          {selectedProfile && (
            <Button
              endIcon={<Create fontSize="large" />}
              onClick={() => setIsUpdateProfileDialogOpen(true)}
              disabled={!isBpsEditable(userInfo, selectedBpsId)}
            >
              Upraviť profil
            </Button>
          )}
        </Stack>

        <Box sx={{width: 'auto'}}>
          <ProfileSelector />
        </Box>
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small" sx={{padding: '20px 0'}}>
          <TableHead>
            <StyledTableHeadRow>
              <TableCell>#</TableCell>
              <TableCell>Čas</TableCell>
              <TableCell align="right">Nominácia OOM [kW]</TableCell>
              <TableCell align="right">Nominácia PpS [kW]</TableCell>
              <TableCell />
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {profileNominationsWithDevices.map((item, i) => (
              <>
                <StyledTableRow key={i}>
                  <TableCell>{calculatePeriodFromTime(item.time_from)}</TableCell>
                  <TableCell>
                    {formatTimeToHourMinutes(item.time_from)} - {formatTimeToHourMinutes(item.time_to)}
                  </TableCell>
                  <TableCell align="right">{transformToKwNumber(item.value_oom)}</TableCell>
                  <TableCell align="right">{transformToKwNumber(item.value_pps)}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => (open == i ? setOpen(-1) : setOpen(i))}
                      disabled={item.note == undefined || item.note.length == 0}
                    >
                      {open == i ? (
                        <KeyboardArrowUpIcon fontSize="small" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
                <TableRow>
                  <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open == i} timeout="auto" unmountOnExit>
                      <Box sx={{margin: 1}}>
                        <b>Poznámka:</b> {item.note}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isCreateProfileDialogOpen && (
        <CreateProfileDialog open={isCreateProfileDialogOpen} onClose={handleCloseCreateProfileDialog} />
      )}

      {isDeleteProfileDialogOpen && (
        <DeleteProfileDialog open={isDeleteProfileDialogOpen} onClose={handleCloseDeleteProfileDialog} />
      )}

      {isUpdateProfileDialogOpen && (
        <UpdateProfileDialog
          open={isUpdateProfileDialogOpen}
          onClose={handleCloseUpdateProfileDialog}
          fetchProfileNominations={fetchProfileNominationsWithDevices}
        />
      )}
    </>
  )
}

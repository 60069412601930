import dayjs from 'dayjs'
import {TIME_INTERVAL_MS} from '../utils/constants'

export const formatTime = (timestamp: string | undefined) => {
  if (!timestamp) return ''
  return dayjs(timestamp).format('HH:mm')
}

export const formatDateAndYear = (timestamp: string | undefined) => {
  if (!timestamp) return ''
  return dayjs(timestamp).format('DD.MM.YYYY')
}

export const formatTimeRange = (timeFrom: string | undefined, timeTo: string | undefined) => {
  return `${formatTime(timeFrom)} - ${formatTime(timeTo)}`
}

export const calculatePeriodFromIsoDate = (timeFrom: string) => {
  const dayStart = dayjs(timeFrom).startOf('day')
  const diff = dayjs(timeFrom).diff(dayStart)
  const period = Math.floor(diff / TIME_INTERVAL_MS)
  return period + 1
}

export const calculatePeriodFromTime = (time: string) => {
  const timeParts = time.split(':')
  const hours = Number(timeParts[0])
  const minutes = Number(timeParts[1])
  const sumMs = (hours * 60 + minutes) * 60 * 1000
  const period = Math.floor(sumMs / TIME_INTERVAL_MS)
  return period + 1
}

export const formatTimeToDate = (time: string) => {
  const splittedTime = time.split(':')
  return dayjs().set('hour', Number(splittedTime[0])).set('minute', Number(splittedTime[1])).startOf('minute')
}

export const formatDateTimeToNoZone = (time: string) => {
  return dayjs(time).format('YYYY-MM-DDTHH:mm:ss')
}

export const getFormattedTextOfLastSlcReset = (dateTime: string) => {
  const dTime = dayjs(dateTime)
  const nowTime = dayjs()

  if (dTime.isAfter(nowTime.subtract(1, 'minute'))) {
    const secondsAgo = nowTime.diff(dTime, 'second')
    return secondsAgo == 1
      ? `Naposledy vyžiadané: pred ${secondsAgo} sekundou`
      : `Naposledy vyžiadané: pred ${secondsAgo} sekundami`
  } else if (dTime.isAfter(nowTime.subtract(1, 'hour'))) {
    const minutesAgo = nowTime.diff(dTime, 'minute')
    return minutesAgo == 1
      ? `Naposledy vyžiadané: pred ${minutesAgo} minútou`
      : `Naposledy vyžiadané: pred ${minutesAgo} minútami`
  } else if (dTime.isAfter(nowTime.subtract(1, 'day'))) {
    const hoursAgo = nowTime.diff(dTime, 'hour')
    return hoursAgo == 1
      ? `Naposledy vyžiadané: pred ${hoursAgo} hodinou`
      : `Naposledy vyžiadané: pred ${hoursAgo} hodinami`
  } else if (dTime.isAfter(nowTime.subtract(1, 'month'))) {
    const daysAgo = nowTime.diff(dTime, 'day')
    return daysAgo == 1 ? `Naposledy vyžiadané: pred ${daysAgo} dňom` : `Naposledy vyžiadané: pred ${daysAgo} dňami`
  } else {
    return 'Naposledy vyžiadané: ' + dateTime
  }
}

import dayjs from 'dayjs'
import React, {useState, useEffect} from 'react'
import {
  EnergySupply,
  EnergySupplyGroup,
  NominationSnapshot,
  EnergyPrice,
  BpsCalculatedNomination,
} from '../../api/generated'
import {getEnergySupply, getGroupEnergySupply, getGroupNominations, getNominationsV2} from '../../api/nominationsApi'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../../utils/BucketStoreContext'
import Chart from 'chart.js/auto'
import {Line} from 'react-chartjs-2'
import {CategoryScale} from 'chart.js'
import {GRAPH_LABELS} from '../../utils/constants'
import {getEnergyPrice} from '../../api/financeApi'
import {getNominationSnapshots, getGroupNominationSnapshots} from '../../api/nominationSnapshotApi'
import {formatTooltipTitle} from '../../utils/common'

Chart.register(CategoryScale)

export const GraphView: React.FC = () => {
  const {data: userInfo} = useBucketStore('userInfo')
  const {data: nominationsExtended, setData: setNominationsExtended} = useBucketStore('nominationsExtended')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: selectedDate} = useBucketStore('selectedDate')
  const {data: groupNominations, setData: setGroupNominations} = useBucketStore('groupNominations')

  const [energySupply, setEnergySupply] = useState<EnergySupply[]>([])
  const [energySupplyGroup, setEnergySupplyGroup] = useState<EnergySupplyGroup[]>([])
  const [nominationSnapshots, setNominationSnapshots] = useState<NominationSnapshot[]>([])
  const [energyPrice, setEnergyPrice] = useState<EnergyPrice[]>([])
  const [groupNominationSnapshots, setGroupNominationSnapshots] = useState<BpsCalculatedNomination[]>([])

  const {run: runGetNominationsV2} = useAsyncMethodWithErrorHandling(getNominationsV2)
  const {run: runGetGroupNominations} = useAsyncMethodWithErrorHandling(getGroupNominations)
  const {run: runGetEnergySupply} = useAsyncMethodWithErrorHandling(getEnergySupply)
  const {run: runGetEnergySupplyGroup} = useAsyncMethodWithErrorHandling(getGroupEnergySupply)
  const {run: runGetNominationSnapshots} = useAsyncMethodWithErrorHandling(getNominationSnapshots)
  const {run: runGetEnergyPrice} = useAsyncMethodWithErrorHandling(getEnergyPrice)
  const {run: runGetGroupNominationSnapshots} = useAsyncMethodWithErrorHandling(getGroupNominationSnapshots)

  const fetchNominations = React.useCallback(async () => {
    if (selectedBpsId) {
      const normalizedDate = selectedDate.substring(0, 10)
      const result = (await runGetNominationsV2({dateFrom: normalizedDate, bpsId: selectedBpsId})).data

      setNominationsExtended(result?.nominations ?? [])
    }
  }, [selectedBpsId, selectedDate, runGetNominationsV2, setNominationsExtended])

  const fetchGroupNominations = React.useCallback(async () => {
    if (selectedBpsId) {
      const normalizedDate = selectedDate.substring(0, 10)
      const result = (await runGetGroupNominations({date: normalizedDate})).data

      setGroupNominations(result?.calculated ?? [])
    }
  }, [selectedBpsId, selectedDate, runGetGroupNominations, setGroupNominations])

  const fetchEnergySupply = React.useCallback(async () => {
    if (selectedBpsId) {
      const normalizedDate = selectedDate.substring(0, 10)
      const result = (await runGetEnergySupply({bpsId: selectedBpsId, dateFrom: normalizedDate})).data

      setEnergySupply(result?.energy_supply ?? [])
    }
  }, [selectedBpsId, selectedDate, runGetEnergySupply])

  const fetchEnergySupplyGroup = React.useCallback(async () => {
    const normalizedDate = selectedDate.substring(0, 10)
    const result = (await runGetEnergySupplyGroup({date: normalizedDate})).data

    setEnergySupplyGroup(result?.result ?? [])
  }, [selectedDate, runGetEnergySupplyGroup])

  const fetchNominationSnapshots = React.useCallback(async () => {
    if (selectedBpsId) {
      const normalizedDate = selectedDate.substring(0, 10)
      const result = (await runGetNominationSnapshots({date: normalizedDate, bpsId: selectedBpsId})).data

      setNominationSnapshots(result?.nomination_snapshots ?? [])
    }
  }, [selectedBpsId, selectedDate, runGetNominationSnapshots, setNominationSnapshots])

  const fetchEnergyPrice = React.useCallback(async () => {
    const normalizedDate = selectedDate.substring(0, 10)
    const result = (await runGetEnergyPrice({date: normalizedDate})).data

    setEnergyPrice(result?.energy_prices ?? [])
  }, [selectedDate, runGetEnergyPrice])

  const fetchGroupNominationSnapshots = React.useCallback(async () => {
    if (selectedBpsId) {
      const normalizedDate = selectedDate.substring(0, 10)
      const result = (await runGetGroupNominationSnapshots({date: normalizedDate})).data

      setGroupNominationSnapshots(result?.calculated ?? [])
    }
  }, [selectedBpsId, selectedDate, runGetGroupNominationSnapshots, setGroupNominationSnapshots])

  const isDateInPast = dayjs(selectedDate).isBefore(dayjs(), 'day')
  const isDateToday = dayjs(selectedDate).isSame(dayjs(), 'day')
  const isDateTomorrow = dayjs(selectedDate).isSame(dayjs().add(1, 'day'), 'day')
  const isDateOneDayAfterTomorrow = dayjs(selectedDate).isSame(dayjs().add(2, 'day'), 'day')
  const isDateTwoDaysAfterTomorrow = dayjs(selectedDate).isSame(dayjs().add(3, 'day'), 'day')

  const parseDataForGraph = (data: {timeFrom: string | undefined; value: number | undefined}[]) => {
    const parsedData: (number | undefined)[] = []
    GRAPH_LABELS.forEach((label) => {
      const dataForSpecificTime = data.filter((item) => dayjs(item.timeFrom).format('HH:mm') === label)
      if (dataForSpecificTime.length > 0) {
        parsedData.push(dataForSpecificTime[0].value)
      } else {
        parsedData.push(undefined)
      }
    })
    return parsedData
  }

  useEffect(() => {
    fetchNominations()
    fetchGroupNominations()
    fetchEnergyPrice()

    if (isDateInPast) {
      fetchEnergySupply()
      fetchEnergySupplyGroup()
    } else {
      setEnergySupply([])
      setEnergySupplyGroup([])
    }

    if (isDateInPast || isDateToday || isDateTomorrow || isDateOneDayAfterTomorrow || isDateTwoDaysAfterTomorrow) {
      fetchNominationSnapshots()
      fetchGroupNominationSnapshots()
    } else {
      setNominationSnapshots([])
      setGroupNominationSnapshots([])
    }
  }, [
    fetchNominations,
    fetchGroupNominations,
    fetchEnergySupply,
    fetchEnergySupplyGroup,
    fetchNominationSnapshots,
    fetchEnergyPrice,
    fetchGroupNominationSnapshots,
    isDateInPast,
    isDateToday,
    isDateTomorrow,
    isDateOneDayAfterTomorrow,
    isDateTwoDaysAfterTomorrow,
  ])

  return (
    <>
      <Line
        data={{
          labels: GRAPH_LABELS,
          datasets: [
            {
              label: 'Nominácie OOM',
              backgroundColor: 'rgba(151, 187, 205, 0.2)',
              borderColor: '#92C937',
              pointBackgroundColor: '#92C937',
              pointBorderColor: '#fff',
              data: parseDataForGraph(
                nominationsExtended.map((nomination) => {
                  return {
                    timeFrom: nomination.time_from,
                    value: nomination.value_oom,
                  }
                }),
              ),
              stepped: 'before',
              yAxisID: 'y',
            },
            {
              label: 'Nominácie PpS',
              backgroundColor: 'rgba(151, 187, 205, 0.2)',
              borderColor: '#92C937',
              pointBackgroundColor: '#92C937',
              pointBorderColor: '#fff',
              data: parseDataForGraph(
                nominationsExtended.map((nomination) => {
                  return {
                    timeFrom: nomination.time_from,
                    value: nomination.value_pps,
                  }
                }),
              ),
              stepped: 'before',
              hidden: true,
              yAxisID: 'y',
            },
            {
              label: 'Nom. odoslané',
              backgroundColor: 'rgba(151, 187, 205, 0.2)',
              borderColor: '#009ce9',
              pointBackgroundColor: '#009ce9',
              pointBorderColor: '#fff',
              data: parseDataForGraph(
                nominationSnapshots.map((nominationSnapshot) => {
                  return {
                    timeFrom: nominationSnapshot.time_from.slice(0, -1),
                    value: nominationSnapshot.value_mw,
                  }
                }),
              ),
              stepped: 'before',
              yAxisID: 'y',
            },
            {
              label: 'OKTE Priebehy',
              backgroundColor: 'rgba(151, 187, 205, 0.2)',
              borderColor: '#d14808',
              pointBackgroundColor: '#d14808',
              pointBorderColor: '#d14808',
              data: parseDataForGraph(
                energySupply.map((supply) => {
                  return {
                    timeFrom: supply.time_from,
                    value: supply.value_mw,
                  }
                }),
              ),
              segment: {
                borderDash: (ctx): number[] => {
                  return energySupply[ctx.p0DataIndex]?.okte_quantity_qualifier == '136' ? [] : [2, 2]
                },
              },
              stepped: 'before',
              yAxisID: 'y',
            },
            {
              label: 'Skupina: Nominácie',
              backgroundColor: 'rgba(151, 187, 205, 0.2)',
              borderColor: 'green',
              pointBackgroundColor: groupNominations.map((groupNomination) => groupNomination.value_color),
              pointBorderColor: groupNominations.map((groupNomination) => groupNomination.value_color),
              data: parseDataForGraph(
                groupNominations.map((groupNomination) => {
                  return {
                    timeFrom: groupNomination.time_from,
                    value: groupNomination.value_oom,
                  }
                }),
              ),
              segment: {
                borderColor: (ctx) => {
                  return (
                    groupNominations.map((groupNomination) => groupNomination.value_color)[ctx.p0DataIndex] ?? 'green'
                  )
                },
              },
              stepped: 'before',
              hidden: true,
              yAxisID: 'y',
            },
            {
              label: 'Skupina: Nom. odoslané',
              backgroundColor: 'rgba(151, 187, 205, 0.2)',
              borderColor: '#1e53fc',
              pointBackgroundColor: '#1e53fc',
              pointBorderColor: '#1e53fc',
              data: parseDataForGraph(
                groupNominationSnapshots.map((groupNominationSnapshot) => {
                  return {
                    timeFrom: groupNominationSnapshot.time_from,
                    value: groupNominationSnapshot.value_oom,
                  }
                }),
              ),
              stepped: 'before',
              hidden: true,
              yAxisID: 'y',
            },
            {
              label: 'Skupina: OKTE Priebehy',
              backgroundColor: 'rgba(151, 187, 205, 0.2)',
              borderColor: '#70410a',
              pointBackgroundColor: '#70410a',
              pointBorderColor: '#70410a',
              data: parseDataForGraph(
                energySupplyGroup.map((supply) => {
                  return {
                    timeFrom: supply.time_from,
                    value: supply.value,
                  }
                }),
              ),
              segment: {
                borderDash: (ctx): number[] => {
                  return energySupplyGroup[ctx.p0DataIndex]?.group_value_valid == true ? [] : [2, 2]
                },
              },
              stepped: 'before',
              yAxisID: 'y',
              hidden: true,
            },
            {
              label: 'SPOT',
              backgroundColor: 'rgba(151, 187, 205, 0.2)',
              borderColor: '#7F7F7F',
              pointBackgroundColor: '#7F7F7F',
              pointBorderColor: '#7F7F7F',
              data: parseDataForGraph(
                energyPrice.map((prices) => {
                  return {
                    timeFrom: prices.time_from,
                    value: Number(prices.price_mwh),
                  }
                }),
              ),
              stepped: 'before',
              spanGaps: true,
              hidden: true,
              yAxisID: 'y1',
            },
          ],
        }}
        options={{
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            y: {
              type: 'linear',
              display: true,
              position: 'left',
              beginAtZero: true,
              title: {
                display: true,
                text: 'MW',
                font: {
                  size: 16,
                  weight: 'bold',
                },
              },
            },
            y1: {
              type: 'linear',
              display: !!(userInfo?.roles.includes('GROUP_MNG') || userInfo?.roles.includes('BPS_MNG')),
              position: 'right',
              beginAtZero: true,
              grid: {
                drawOnChartArea: false,
              },
              title: {
                display: true,
                text: '\u20AC/MWh',
                font: {
                  size: 16,
                  weight: 'bold',
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  let quantityQualifier
                  const unit = context.dataset.yAxisID === 'y' ? 'MW' : '\u20AC/MWh'

                  if (context.dataset.label === 'OKTE Priebehy') {
                    const energySupplyElement = energySupply[context.dataIndex]
                    quantityQualifier =
                      energySupplyElement?.okte_quantity_qualifier == null
                        ? '(nedefinované)'
                        : energySupplyElement.okte_quantity_qualifier == '136'
                        ? '(merané)'
                        : '(náhradné)'
                  }

                  if (context.dataset.label === 'Skupina: OKTE Priebehy') {
                    const energySupplyGroupElement = energySupplyGroup[context.dataIndex]
                    quantityQualifier = energySupplyGroupElement?.group_value_valid ? '(merané)' : '(náhradné)'
                  }

                  return `${context.dataset.label}: ${context.formattedValue} ${unit} ${quantityQualifier ?? ''}`
                },
                title: formatTooltipTitle,
              },
            },
            legend: {
              labels: {
                filter: (item) => {
                  if (item.text.includes('Finančné rozpočítanie')) {
                    return !!(userInfo?.roles.includes('GROUP_MNG') || userInfo?.roles.includes('BPS_MNG'))
                  }
                  return true
                },
              },
            },
          },
        }}
      />
    </>
  )
}

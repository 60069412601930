import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React, {useMemo} from 'react'
import {useBucketStore} from '../utils/BucketStoreContext'
import {formatTimeRange} from '../utils/format'
import {NominationExtendedBase} from '../types/nominationExtended'
import {transformToKwWithEndKw} from '../utils/common'
import {NominationExtended} from '../../src/api/generated'
import {checkOverlap, merge} from '../../src/components/nominations-merge-utils'
import {NOMINATION_VALUE_TYPE} from '../utils/constants'
import {CauseOfOutage} from '../utils/enums'

type ConfirmCreateNominationDialogProps = {
  open: boolean
  onClose: () => void
  nomination: NominationExtendedBase
  bpsNominationValuePps: number
  bpsNominationValueOom: number
  onConfirm: () => void
  selectedEnterFault: boolean
  isFailureTime: boolean
  confirmButtonDisabled: boolean
  selectedCause: string | CauseOfOutage
}

export const ConfirmCreateNominationDialog: React.FC<ConfirmCreateNominationDialogProps> = ({
  open,
  nomination,
  bpsNominationValuePps,
  bpsNominationValueOom,
  onClose,
  onConfirm,
  selectedEnterFault,
  isFailureTime,
  confirmButtonDisabled,
  selectedCause,
}) => {
  const {data: nominations} = useBucketStore('nominationsExtended')

  const existingRecords = useMemo(() => {
    const overlappingRecords = nominations.filter((x) => checkOverlap(x, nomination))
    return merge(overlappingRecords)
  }, [nominations, nomination])

  const newNominationText = (nominationType: string) => {
    if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
      return formatTimeRange(nomination.time_from, nomination.time_to) + ', ' + bpsNominationValueOom + ' kW'
    } else if (nominationType === NOMINATION_VALUE_TYPE.Pps) {
      return formatTimeRange(nomination.time_from, nomination.time_to) + ', ' + bpsNominationValuePps + ' kW'
    }
  }

  const existingNominationText = (existingNomination: NominationExtended, nominationType: string) => {
    if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
      return (
        formatTimeRange(existingNomination.time_from, existingNomination.time_to) +
        ', ' +
        transformToKwWithEndKw(existingNomination.value_oom)
      )
    } else if (nominationType === NOMINATION_VALUE_TYPE.Pps) {
      return (
        formatTimeRange(existingNomination.time_from, existingNomination.time_to) +
        ', ' +
        transformToKwWithEndKw(existingNomination.value_pps)
      )
    }
  }

  const getNoteText = () => {
    if (selectedEnterFault && isFailureTime) {
      return (
        <Typography variant="body2">
          {`Poznámka: ${selectedCause}${nomination.note ? ',' : ''} ${nomination.note}`.trim()}
        </Typography>
      )
    } else {
      return <Typography variant="body2">{`Poznámka: ${nomination.note}`.trim()}</Typography>
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{isFailureTime && selectedEnterFault ? 'Zadať poruchu' : 'Vytvoriť nomináciu'}</DialogTitle>
      <DialogContent>
        {isFailureTime && selectedEnterFault ? (
          <Alert severity="error" sx={{marginBottom: '8px'}}>
            Zadávate poruchu
          </Alert>
        ) : null}
        {existingRecords.length > 0 && (
          <Box marginBottom={2}>
            <Typography fontWeight={500}>Existujúce nominácie OOM:</Typography>
            {existingRecords.map((existingNomination) => (
              <Box key={existingNomination.id} marginTop="4px">
                <Typography>{existingNominationText(existingNomination, NOMINATION_VALUE_TYPE.Oom)}</Typography>
              </Box>
            ))}
            <Typography fontWeight={500}>Existujúce nominácie PpS:</Typography>
            {existingRecords.map((existingNomination) => (
              <Box key={existingNomination.id} marginTop="4px">
                <Typography>{existingNominationText(existingNomination, NOMINATION_VALUE_TYPE.Pps)}</Typography>
                {existingNomination.note && (
                  <Typography variant="body2">Poznámka: {existingNomination.note}</Typography>
                )}
              </Box>
            ))}
          </Box>
        )}

        <Typography fontWeight={500}>
          {isFailureTime && selectedEnterFault ? 'Porucha OOM' : 'Nová nominácia OOM'}:
        </Typography>
        <Typography>{newNominationText(NOMINATION_VALUE_TYPE.Oom)}</Typography>
        <Typography fontWeight={500}>
          {isFailureTime && selectedEnterFault ? 'Porucha PpS' : 'Nová nominácia PpS'}:
        </Typography>
        <Typography>{newNominationText(NOMINATION_VALUE_TYPE.Pps)}</Typography>
        {getNoteText()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zrušiť
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color={isFailureTime && selectedEnterFault ? 'error' : 'primary'}
          disabled={confirmButtonDisabled}
        >
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import {Box, Divider} from '@mui/material'
import React from 'react'
import {Device} from '../api/generated'

type NominationRangeBarProps = {
  tvsVarianceMw: number | undefined
  devicesForBps: Device[]
  selectedDevices: number[]
}

export const NominationRangeBar: React.FC<NominationRangeBarProps> = ({
  tvsVarianceMw,
  devicesForBps,
  selectedDevices,
}) => {
  const pRegMinSum = devicesForBps
    ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
    .reduce(function (prev, current) {
      return prev + current?.p_reg_min_mw
    }, 0)

  const pRegMaxSum = devicesForBps
    ?.filter((item) => selectedDevices?.indexOf(item.id) !== -1)
    .reduce(function (prev, current) {
      return prev + current?.p_reg_max_mw
    }, 0)

  tvsVarianceMw = tvsVarianceMw ?? 0
  const dividerHeight = 12

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" marginTop={2}>
      <Divider
        sx={{
          flex: (0.5 * tvsVarianceMw) / (pRegMaxSum - pRegMinSum + tvsVarianceMw),
          backgroundColor: 'red',
          height: dividerHeight,
        }}
      />
      <Divider
        sx={{
          flex: (0.5 * tvsVarianceMw) / (pRegMaxSum - pRegMinSum + tvsVarianceMw),
          backgroundColor: 'orange',
          height: dividerHeight,
        }}
      />
      <Divider
        sx={{
          flex: (pRegMaxSum - pRegMinSum - tvsVarianceMw) / (pRegMaxSum - pRegMinSum + tvsVarianceMw),
          backgroundColor: 'green',
          height: dividerHeight,
        }}
      />
      <Divider
        sx={{
          flex: (0.5 * tvsVarianceMw) / (pRegMaxSum - pRegMinSum + tvsVarianceMw),
          backgroundColor: 'orange',
          height: dividerHeight,
        }}
      />
      <Divider
        sx={{
          flex: (0.5 * tvsVarianceMw) / (pRegMaxSum - pRegMinSum + tvsVarianceMw),
          backgroundColor: 'red',
          height: dividerHeight,
        }}
      />
    </Box>
  )
}

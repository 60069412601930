import {Alert, Box, Button, Link, Stack, Tab, Tabs} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useBucketStore, useBucketStoreContext} from '../../utils/BucketStoreContext'
import {ReportSnapshotView} from './ReportSnapshotView'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {useNavigate} from 'react-router'
import {absolutePath} from '../../navigation/utils'
import {isUserGroupManagerOrGroupOperator} from '../../utils/validateUserRolesAndBps'
import {DownloadNominationSnapshotCsvDialog} from '../DownloadNominationSnapshotCsvDialog'
import {ReportExportDialog} from '../ReportExportDialog'
import {getCurrentAnnouncements} from '../../api/commonApi'
import {useAsyncMethodWithErrorHandling} from '../../hooks/useAsyncMethodWithErrorHandling'

export const Dispatch: React.FC = () => {
  const [selectedTab, setselectedTab] = useState<'production-bps-view' | 'report-snapshot-view'>('report-snapshot-view')

  const [isReportExportDialogOpen, setIsReportExportDialogOpen] = useState<boolean>(false)
  const [isDownloadNominationSnapshotCsvDialogOpen, setIsDownloadNominationSnapshotCsvDialogOpen] =
    useState<boolean>(false)

  const {data: userInfo} = useBucketStore('userInfo')
  const {data: announcements, setData: setAnnouncements} = useBucketStore('announcements')

  const {run: runGetCurrentAnnouncements} = useAsyncMethodWithErrorHandling(getCurrentAnnouncements)

  const navigation = useNavigate()
  const {clearAllBuckets} = useBucketStoreContext()

  const logout = () => {
    clearAllBuckets()
    sessionStorage.clear()
    navigation(absolutePath('login'))
  }

  const goToDashboard = () => {
    navigation(absolutePath('dashboard'))
  }

  const fetchAnnouncements = React.useCallback(async () => {
    const result = (await runGetCurrentAnnouncements()).data?.announcements
    setAnnouncements(result)
  }, [runGetCurrentAnnouncements, setAnnouncements])

  useEffect(() => {
    fetchAnnouncements()
  }, [fetchAnnouncements])

  dayjs.extend(utc)
  dayjs.extend(timezone)

  return (
    <>
      <Stack>
        {announcements?.map((announcement, index) => (
          <Alert
            key={index}
            severity={announcement.type == 'INFO' ? 'info' : announcement.type == 'WARNING' ? 'warning' : 'info'}
            sx={{marginBottom: '8px', padding: '0px 16px'}}
          >
            {announcement.text}
          </Alert>
        ))}
      </Stack>
      <Stack>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button variant="text" sx={{right: 15}} onClick={() => goToDashboard()}>
            Nominácie
          </Button>
          <Link component="button" variant="subtitle2" onClick={() => logout()}>
            Odhlásiť sa
          </Link>
        </Box>
      </Stack>
      <Stack>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-start">
          <Button
            onClick={() => setIsReportExportDialogOpen(true)}
            sx={{right: 15, display: isUserGroupManagerOrGroupOperator(userInfo) ? null : 'none'}}
          >
            OKTE dáta na môj email
          </Button>
          <Button
            onClick={() => setIsDownloadNominationSnapshotCsvDialogOpen(true)}
            sx={{right: 15, display: isUserGroupManagerOrGroupOperator(userInfo) ? null : 'none'}}
          >
            Odoslané nom. na môj email
          </Button>
        </Stack>
        <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: '8px'}}>
          <Tabs variant="scrollable" value={selectedTab} onChange={(_, newValue) => setselectedTab(newValue)}>
            <Tab label="BPS Report" value="report-snapshot-view" />
          </Tabs>
        </Box>

        {selectedTab === 'report-snapshot-view' && <ReportSnapshotView />}
      </Stack>

      {isReportExportDialogOpen && (
        <ReportExportDialog open={isReportExportDialogOpen} onClose={() => setIsReportExportDialogOpen(false)} />
      )}

      {isDownloadNominationSnapshotCsvDialogOpen && (
        <DownloadNominationSnapshotCsvDialog
          open={isDownloadNominationSnapshotCsvDialogOpen}
          onClose={() => setIsDownloadNominationSnapshotCsvDialogOpen(false)}
        />
      )}
    </>
  )
}

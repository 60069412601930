import dayjs from 'dayjs'
import {DATE_AND_TIME_FORMAT, TIME_INTERVAL_ALL_DAY_HOURS, TIME_INTERVAL_MS} from './constants'
import {TooltipItem} from 'chart.js'
import {Device, DeviceRoleEnum} from '../api/generated'

export const timestampToPeriod = (timestamp: string, date: string) => {
  const dayStart = dayjs(date).startOf('day')
  const diffMs = dayjs(timestamp).diff(dayStart)
  const periodsSinceStart = diffMs / TIME_INTERVAL_MS
  // human-friendly periods start at 1
  return Math.floor(periodsSinceStart + 1)
}

export const timestampToHourPeriod = (timestamp: string, date: string) => {
  const dayStart = dayjs(date).startOf('day')
  const diffMs = dayjs(timestamp).diff(dayStart)
  const periodsSinceStart = diffMs / TIME_INTERVAL_ALL_DAY_HOURS
  // human-friendly periods start at 1
  return periodsSinceStart + 1
}

export const periodToTimestamp = (period: number, date: string) => {
  const dayStart = dayjs(date).startOf('day')
  // human-friendly periods start at 1
  const diffMs = (period - 1) * TIME_INTERVAL_MS

  const periodStart = dayStart.add(diffMs, 'ms')
  const periodEnd = periodStart.add(TIME_INTERVAL_MS, 'ms')

  return {
    periodStart: periodStart.format(DATE_AND_TIME_FORMAT).toString(),
    periodEnd: periodEnd.format(DATE_AND_TIME_FORMAT).toString(),
  }
}

export const hourPeriodToTimestamp = (period: number, date: string) => {
  const dayStart = dayjs(date).startOf('day')
  // human-friendly periods start at 1
  const diffMs = (period - 1) * TIME_INTERVAL_ALL_DAY_HOURS

  const periodStart = dayStart.add(diffMs, 'ms')
  const periodEnd = periodStart.add(TIME_INTERVAL_ALL_DAY_HOURS, 'ms')

  return {
    periodStart: periodStart.format(DATE_AND_TIME_FORMAT).toString(),
    periodEnd: periodEnd.format(DATE_AND_TIME_FORMAT).toString(),
  }
}

export const validateNumericValue = (value: string) => {
  const regex = /^\d+$/
  if (value === '' || regex.test(value)) {
    return true
  }
}

export const parseToFixedNumber = (value: number, precision: number) => Number(value.toFixed(precision))

export const transformToKwWithEndKw = (valueToConvert: number | undefined) =>
  (valueToConvert !== undefined ? parseToFixedNumber(valueToConvert * 1000, 3) : '-') + ' kW'

export const transformToKwWithEndKwWithoutDecimals = (valueToConvert: number | undefined) =>
  (valueToConvert !== undefined ? parseToFixedNumber(valueToConvert * 1000, 0) : '-') + ' kW'

export const transformToKwNumber = (valueToConvert: number | undefined) =>
  valueToConvert !== undefined ? parseToFixedNumber(valueToConvert * 1000, 3) : undefined

export const transformToKwWithEndKwEvenIfNull = (valueToConvert: number | undefined) =>
  (valueToConvert !== undefined && valueToConvert !== null ? parseToFixedNumber(valueToConvert * 1000, 3) : '-') + ' kW'

export const transformToKwNumberEvenIfNull = (valueToConvert: number | undefined) =>
  valueToConvert !== undefined && valueToConvert !== null ? parseToFixedNumber(valueToConvert * 1000, 3) : undefined

export const transformToMwNumber = (valueToConvert: number | undefined) => (valueToConvert ? valueToConvert / 1000 : 0)

export const formatTooltipTitle = (ctx: TooltipItem<'line'>[]) => `${ctx[0].label} - ${ctx[0].dataIndex + 1}`

export const sortDevices = (devices: Device[]): Device[] => {
  const DEVICE_ROLES_IN_ORDER = [
    DeviceRoleEnum.Generator,
    DeviceRoleEnum.Consumption,
    DeviceRoleEnum.Storage,
    DeviceRoleEnum.Other,
  ]

  return devices.sort((a, b) => {
    //sort by role
    if (a.role === undefined) return 1
    if (b.role === undefined) return -1
    const roleComparison = DEVICE_ROLES_IN_ORDER.indexOf(a.role) - DEVICE_ROLES_IN_ORDER.indexOf(b.role)

    if (roleComparison !== 0) return roleComparison

    //sort by name
    const nameA = a.name ?? ''
    const nameB = b.name ?? ''
    return nameA.localeCompare(nameB)
  })
}

export const getDateWithTimeNow = (baseDate: string) => {
  return dayjs(baseDate).hour(dayjs().hour()).minute(dayjs().minute()).format('YYYY-MM-DDTHH:mm:ss')
}

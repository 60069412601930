import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React, {useMemo} from 'react'
import {Device, NominationDevice, NominationProfile} from '../api/generated'
import {useBucketStore} from '../utils/BucketStoreContext'
import {formatTimeRange} from '../utils/format'
import {checkOverlapWithDevices, mergeNominationsWithDevices} from './profile-nominations-merge-utils'
import {transformToKwWithEndKw} from '../utils/common'
import {NominationProfileWithDevices} from '../api/generated/model/nomination-profile-with-devices'
import {NOMINATION_VALUE_TYPE} from '../utils/constants'

type ConfirmUpdateProfileDialogProps = {
  open: boolean
  onClose: () => void
  profileNomination: NominationProfile
  bpsNominationValuePps: number
  bpsNominationValueOom: number
  onConfirm: () => void
  nominationDevices: NominationDevice[]
  allDevices: Device[] | undefined
  confirmButtonDisabled: boolean
}

export const ConfirmUpdateProfileDialog: React.FC<ConfirmUpdateProfileDialogProps> = ({
  open,
  profileNomination,
  bpsNominationValuePps,
  bpsNominationValueOom,
  onClose,
  onConfirm,
  confirmButtonDisabled,
}) => {
  const {data: profileNominationsWithDevices} = useBucketStore('profileNominationsWithDevices')
  const {data: selectedProfile} = useBucketStore('selectedProfile')

  const existingRecords = useMemo(() => {
    const overlappingRecords = profileNominationsWithDevices.filter((x) =>
      checkOverlapWithDevices(x, profileNomination),
    )
    return mergeNominationsWithDevices(overlappingRecords)
  }, [profileNominationsWithDevices, profileNomination])

  const formatTimeToHourMinutes = (time: string) => {
    return time.substring(0, time.lastIndexOf(':'))
  }

  const newNominationText = (nominationType: string) => {
    if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
      return (
        formatTimeRange(profileNomination.time_from, profileNomination.time_to) + ', ' + bpsNominationValueOom + ' kW'
      )
    } else if (nominationType === NOMINATION_VALUE_TYPE.Pps) {
      return (
        formatTimeRange(profileNomination.time_from, profileNomination.time_to) + ', ' + bpsNominationValuePps + ' kW'
      )
    }
  }

  const existingNominationText = (existingNomination: NominationProfileWithDevices, nominationType: string) => {
    if (nominationType === NOMINATION_VALUE_TYPE.Oom) {
      return (
        formatTimeToHourMinutes(existingNomination.time_from) +
        ' - ' +
        formatTimeToHourMinutes(existingNomination.time_to) +
        ', ' +
        transformToKwWithEndKw(existingNomination.value_oom)
      )
    } else if (nominationType === NOMINATION_VALUE_TYPE.Pps) {
      return (
        formatTimeToHourMinutes(existingNomination.time_from) +
        ' - ' +
        formatTimeToHourMinutes(existingNomination.time_to) +
        ', ' +
        transformToKwWithEndKw(existingNomination.value_pps)
      )
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Upraviť profil: {selectedProfile?.name}</DialogTitle>
      <DialogContent>
        {existingRecords.length > 0 && (
          <Box marginBottom={2}>
            <Typography fontWeight={500}>Existujúce OOM nominácie profilu:</Typography>
            {existingRecords.map((nomination: NominationProfileWithDevices, i) => (
              <Box key={i} marginTop="4px">
                <Typography>{existingNominationText(nomination, NOMINATION_VALUE_TYPE.Oom)}</Typography>
              </Box>
            ))}
            <Typography fontWeight={500}>Existujúce PpS nominácie profilu:</Typography>
            {existingRecords.map((nomination: NominationProfileWithDevices, i) => (
              <Box key={i} marginTop="4px">
                <Typography>{existingNominationText(nomination, NOMINATION_VALUE_TYPE.Pps)}</Typography>
                {nomination.note && <Typography variant="body2">Poznámka: {nomination.note}</Typography>}
              </Box>
            ))}
          </Box>
        )}

        <Typography fontWeight={500}>Nové OOM nominácie profilu:</Typography>
        <Typography>{newNominationText(NOMINATION_VALUE_TYPE.Oom)}</Typography>
        <Typography fontWeight={500}>Nové PpS nominácie profilu:</Typography>
        <Typography>{newNominationText(NOMINATION_VALUE_TYPE.Pps)}</Typography>
        {profileNomination.note && <Typography variant="body2">Poznámka: {profileNomination.note}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zrušiť
        </Button>
        <Button onClick={onConfirm} variant="contained" disabled={confirmButtonDisabled}>
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}

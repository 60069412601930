import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React from 'react'

type UnsuccessfulFailureRevokeDialogProps = {
  open: boolean
  onClose: () => void
}

export const UnsuccessfulFailureRevokeDialog: React.FC<UnsuccessfulFailureRevokeDialogProps> = ({open, onClose}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Neúspešné zrušenie poruchy</DialogTitle>
      <DialogContent>
        <Typography>
          Zrušenie poruchy pre dané periódy bolo neúspešné, pretože nominácie pre periódy poruchy ešte neboli odoslané.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained">
          Zavrieť
        </Button>
      </DialogActions>
    </Dialog>
  )
}

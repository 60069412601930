import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Menu,
  styled,
} from '@mui/material'
import {PickersDay, StaticDatePicker} from '@mui/x-date-pickers'
import dayjs, {Dayjs} from 'dayjs'
import React, {useState, useEffect} from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../utils/BucketStoreContext'
import {Profile} from '../api/generated'
import {getNominationsV2} from '../api/nominationsApi'
import {getProfiles, applyProfileV2} from '../api/profilesApi'
import {ConfirmUseProfileDialog} from './ConfirmUseProfileDialog'
import {UnsuccessfulUseProfileDialog} from './UnsuccessfulUseProfileDialog'

const StyledStaticDatePicker = styled(StaticDatePicker<string, Dayjs>)(() => ({
  '.MuiDayPicker-slideTransition': {
    minHeight: '200px',
  },
}))

type UseProfileDialogProps = {
  open: boolean
  onClose: () => void
}

export const UseProfileDialog: React.FC<UseProfileDialogProps> = ({open, onClose}) => {
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: selectedDate, setData: setSelectedDate} = useBucketStore('selectedDate')
  const {setData: setNominationsExtended} = useBucketStore('nominationsExtended')
  const {setData: setBpsNominationTableOpen} = useBucketStore('bpsNominationTableOpen')

  const [profilesMenuAnchorEl, setProfilesMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [dates, setDates] = useState<string[]>([])
  const [profiles, setProfiles] = useState<Profile[]>([])
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null)
  const [isConfirmUseProfileDialogOpen, setIsConfirmUseProfileDialogOpen] = useState<boolean>(false)
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState<boolean>(false)
  const [isUnsuccessfulUseProfileDialogOpen, setIsUnsuccessfulUseProfileDialogOpen] = React.useState<boolean>(false)

  const {run: runGetProfiles} = useAsyncMethodWithErrorHandling(getProfiles)
  const {run: runGetNominationsV2} = useAsyncMethodWithErrorHandling(getNominationsV2)
  const {run: runApplyProfileV2} = useAsyncMethodWithErrorHandling(applyProfileV2)

  const fetchNominations = React.useCallback(
    async (date: string) => {
      if (selectedBpsId) {
        const normalizedDate = date.substring(0, 10)
        const result = (await runGetNominationsV2({dateFrom: normalizedDate, bpsId: selectedBpsId})).data

        setNominationsExtended(result?.nominations ?? [])
      }
    },
    [selectedBpsId, runGetNominationsV2, setNominationsExtended],
  )

  const handleConfirm = async (confirmed: boolean) => {
    setIsConfirmDisabled(true)

    if (selectedBpsId && selectedProfile?.id && dates) {
      const response = await runApplyProfileV2({
        applyProfileRequest: {
          bps_id: selectedBpsId,
          profile_id: selectedProfile.id,
          dates_to_apply: dates,
          confirmed: confirmed,
        },
      })

      if (response.error) {
        setIsUnsuccessfulUseProfileDialogOpen(true)
        return
      }

      if (!confirmed && !response.data?.applied) {
        setIsConfirmUseProfileDialogOpen(true)
      } else {
        // refetch nominations when using profile for day also selected in DatePicker, and just once
        if (dates.length > 0 && dates[0] == selectedDate) {
          fetchNominations(dates[0])
        }
        setBpsNominationTableOpen(true)
        setSelectedDate(dates.length > 0 ? dates[0] : selectedDate)
        onClose()
      }
    }
    setIsConfirmDisabled(false)
  }

  const handleCloseProfilesMenu = () => {
    setProfilesMenuAnchorEl(null)
  }

  const handleProfilesMenuItemClick = (profile: Profile) => () => {
    setSelectedProfile(profile)
    setProfilesMenuAnchorEl(null)
  }

  const fetchData = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetProfiles({bpsId: selectedBpsId})).data

      setProfiles(result?.profiles ?? [])
    }
  }, [selectedBpsId, runGetProfiles])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Použiť profil</DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-start" marginY={2}>
            <Typography>Profil:</Typography>
            <Button
              onClick={(e) => setProfilesMenuAnchorEl(e.currentTarget)}
              variant="contained"
              endIcon={<KeyboardArrowDownIcon fontSize="inherit" />}
              sx={{width: '70%'}}
            >
              {selectedProfile ? selectedProfile.name : 'Vybrať profil'}
            </Button>
            <Menu anchorEl={profilesMenuAnchorEl} open={!!profilesMenuAnchorEl} onClose={handleCloseProfilesMenu}>
              {profiles.map((profile) => (
                <MenuItem key={profile.id} onClick={handleProfilesMenuItemClick(profile)}>
                  {profile.name}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
          <Stack direction="column" alignItems="flex-start" justifyContent="center">
            <Typography>Použiť na:</Typography>
            <StyledStaticDatePicker
              onChange={(newValue) => {
                if (newValue) {
                  if (dates.includes(dayjs(newValue).format('YYYY-MM-DD'))) {
                    setDates([...dates.filter((date) => date !== dayjs(newValue).format('YYYY-MM-DD')).sort()])
                  } else {
                    setDates([...dates, dayjs(newValue).format('YYYY-MM-DD')].sort())
                  }
                }
              }}
              value={null}
              renderInput={(params) => <TextField size="medium" {...params} />}
              componentsProps={{actionBar: {actions: []}}}
              showToolbar={false}
              disablePast
              renderDay={(day, selectedDays, pickersDayProps) => {
                let selectedMuiClass = ''

                if (dates.includes(dayjs(day).format('YYYY-MM-DD'))) {
                  selectedMuiClass = 'Mui-selected'
                }

                return <PickersDay className={selectedMuiClass} {...pickersDayProps} />
              }}
              disableHighlightToday={true}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button
            onClick={() => handleConfirm(false)}
            variant="contained"
            disabled={dates.length == 0 || !selectedProfile || isConfirmDisabled}
          >
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>

      {isConfirmUseProfileDialogOpen && (
        <ConfirmUseProfileDialog
          open={isConfirmUseProfileDialogOpen}
          onClose={() => setIsConfirmUseProfileDialogOpen(false)}
          dates={dates}
          profile={selectedProfile}
          confirm={handleConfirm}
          confirmButtonDisabled={isConfirmDisabled}
        />
      )}

      <UnsuccessfulUseProfileDialog
        open={isUnsuccessfulUseProfileDialogOpen}
        onClose={() => {
          setIsUnsuccessfulUseProfileDialogOpen(false)
          setIsConfirmUseProfileDialogOpen(false)
          onClose()
        }}
        dates={dates}
        profile={selectedProfile}
      />
    </>
  )
}

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React from 'react'
import dayjs from 'dayjs'

type ConfirmRecalculateReportDialogProps = {
  open: boolean
  onClose: () => void
  date: string | null
  onConfirm: () => void
  confirmButtonDisabled: boolean
}

export const ConfirmRecalculateReportDialog: React.FC<ConfirmRecalculateReportDialogProps> = ({
  open,
  onClose,
  date,
  onConfirm,
  confirmButtonDisabled,
}) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Prepočítať report</DialogTitle>
        <DialogContent>
          <Typography>
            Report pre vybranú BPS na deň&nbsp;
            <strong>{dayjs(date).format('DD.MM.YYYY')}</strong> bude prepočítaný.&nbsp;Želáte si pokračovať?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button onClick={onConfirm} variant="contained" disabled={confirmButtonDisabled}>
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export enum NominationInterval {
  FifteenMinutes,
  Hours,
  AllDay,
}

export enum CauseOfOutage {
  VnOutage = 'Výpadok VN',
  KgjFault = 'Porucha KGJ',
  LowGas = 'Znížena plynotvorba',
  Other = 'Iné',
}

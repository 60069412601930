import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from '@mui/material'
import dayjs from 'dayjs'
import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react'
import {BpsParametersExtended, Device, DeviceTypeEnum, NominationDevice, NominationProfile} from '../api/generated'
import {updateProfileV2} from '../api/profilesApi'
import {useAsyncMethodWithErrorHandling} from '../hooks/useAsyncMethodWithErrorHandling'
import {useBucketStore} from '../utils/BucketStoreContext'
import {ConfirmUpdateProfileDialog} from './ConfirmUpdateProfileDialog'
import {NominationDialogIntervalSelector} from '../components/NominationDialogIntervalSelector'
import {NominationDialogPeriods} from '../components/NominationDialogPeriods'
import {NominationDialogDevicesTable} from './NominationDialogDevicesTable'
import {getBpsDetailsV2, getDevicesForBps} from '../api/bpsApi'
import {NominationDialogPps} from '../components/NominationDialogPps'
import {NominationDialogTechParams} from '../components/NominationDialogTechParams'
import {NominationDialogStatusSelector} from './NominationDialogStatusSelector'
import {NominationIsOutOfRegLimitDialog} from './NominationIsOutOfRegLimitDialog'
import {transformToMwNumber} from '../utils/common'
import {NominationDialogOom} from './NominationDialogOom'
import {NOMINAITON_TECH_PARAMS_COLORS, NOMINATION_VALUE_TYPE} from '../utils/constants'
import {CauseOfOutage, NominationInterval} from '../utils/enums'
import {
  calculateMaxSliderValue,
  calculateMinSliderValue,
  nominationTechParamBands,
  nominationValueTextFieldBorderColor,
} from '../utils/powerUnitControl'
import {checkOverlapWithDevices, mergeNominationsWithDevices} from './profile-nominations-merge-utils'

type UpdateProfileDialogProps = {
  open: boolean
  onClose: () => void
  fetchProfileNominations: () => void
}

const getDefaultProfileNominationData = (profileId: number): NominationProfile => ({
  time_from: dayjs().startOf('day').toISOString(),
  time_to: dayjs().add(1, 'day').startOf('day').toISOString(),
  value_oom: undefined,
  value_pps: undefined,
  note: '',
  profile_id: profileId,
  production_status: 2,
})

export const UpdateProfileDialog: React.FC<UpdateProfileDialogProps> = ({open, onClose, fetchProfileNominations}) => {
  const {data: selectedProfile} = useBucketStore('selectedProfile')
  const {data: selectedBpsId} = useBucketStore('selectedBpsId')
  const {data: productionStatus} = useBucketStore('productionStatusEnum')
  const {data: profileNominationsWithDevices} = useBucketStore('profileNominationsWithDevices')

  const [editedProfileNomination, setEditedProfileNomination] = useState<NominationProfile>(() =>
    getDefaultProfileNominationData(selectedProfile?.id ?? -1),
  )

  const [isConfirmUpdateProfileDialogOpen, setIsConfirmUpdateProfileDialogOpen] = useState<boolean>(false)
  const [selectedInterval, setSelectedInterval] = useState(NominationInterval.AllDay)
  const [selectedDevices, setSelectedDevices] = useState<number[] | undefined>(undefined)
  const startOfDayTimestamp = dayjs().startOf('day')
  const endOfDayTimestamp = dayjs().endOf('day').add(1, 'ms')
  const [devicesForBps, setDevicesForBps] = useState<undefined | Device[]>(undefined)
  const [bpsParameters, setBpsParameters] = useState<BpsParametersExtended>()
  const [enableOomSubmit, setEnableOomSubmit] = useState<boolean>(true)
  const [enablePpsSubmit, setEnablePpsSubmit] = useState<boolean>(true)
  const [isNominationIsOutOfRegLimitDialogOpen, setIsNominationIsOutOfRegLimitDialogOpen] = useState<boolean>(false)
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState<boolean>(false)

  const {run: runUpdateProfileV2} = useAsyncMethodWithErrorHandling(updateProfileV2)
  const {run: runGetDevicesForBps} = useAsyncMethodWithErrorHandling(getDevicesForBps)
  const {run: runGetBpsDetailsV2} = useAsyncMethodWithErrorHandling(getBpsDetailsV2)

  const bpsInstMw = useRef(-1)

  const fetchBpsDetails = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetBpsDetailsV2({id: selectedBpsId})).data
      setBpsParameters(result?.parameters)
    }
  }, [runGetBpsDetailsV2, selectedBpsId])

  useEffect(() => {
    fetchBpsDetails()
  }, [fetchBpsDetails])

  const fetchDevicesForBps = React.useCallback(async () => {
    if (selectedBpsId) {
      const result = (await runGetDevicesForBps({id: selectedBpsId})).data
      setDevicesForBps(
        result?.devices.filter((device) => device.type === DeviceTypeEnum.Chp).sort((a, b) => (a.id > b.id ? 1 : -1)),
      )
    }
  }, [runGetDevicesForBps, selectedBpsId])

  useEffect(() => {
    fetchDevicesForBps()
  }, [fetchDevicesForBps])

  useEffect(() => {
    if (open) {
      setEditedProfileNomination(getDefaultProfileNominationData(selectedProfile?.id ?? -1))
      setSelectedInterval(NominationInterval.AllDay)
    }
  }, [open, selectedProfile])

  const editProperties = (changes: Partial<NominationProfile>) => {
    setEditedProfileNomination((prev) => ({...prev, ...changes}))
  }

  const getSelectedDevicesInstMw = useCallback(
    (selectedDevicesToUse: number[]) =>
      devicesForBps
        ?.filter((device) => selectedDevicesToUse?.find((item) => item === device.id))
        .reduce(function (prev, current) {
          return prev + current.p_inst_mw
        }, 0),
    [devicesForBps],
  )

  const getCalculatedMaxValue = useCallback(
    (selectedDevicesArg: number[], nominationType: string) =>
      calculateMaxSliderValue(selectedDevicesArg, nominationType, bpsParameters, devicesForBps),
    [bpsParameters, devicesForBps],
  )

  const getCalculatedMinValue = (selectedDevicesArg: number[], nominationType: string) => {
    return calculateMinSliderValue(selectedDevicesArg, nominationType, bpsParameters, devicesForBps)
  }

  const createEmptyNominationDevice = (nominationId: number): NominationDevice => {
    return {
      device_id: nominationId,
      time_from: '', // will be set on BEExistujúci profil nominácií:
      time_to: '', // will be set on BE
      p_term_mw: 0,
      p_reg_min_mw: 0,
      p_reg_max_mw: 0,
      in_nomination: false,
    }
  }

  const calculatePTermMw = (deviceCharPInstMw: number) => {
    const sumPInstMwSelectedDevices = selectedDevices ? getSelectedDevicesInstMw(selectedDevices) ?? 0 : 0

    const firstPart = sumPInstMwSelectedDevices - ((editedProfileNomination.value_oom ?? 0) / 100) * bpsInstMw.current
    const secondPart = deviceCharPInstMw / sumPInstMwSelectedDevices

    return (deviceCharPInstMw - firstPart * secondPart).toFixed(3)
  }

  const createNominationDevice = (
    nominationId: number,
    pRegMinMw: number,
    pRegMaxMw: number,
    pInstMw: number,
  ): NominationDevice => ({
    device_id: nominationId,
    time_from: '',
    time_to: '',
    p_term_mw: Number(calculatePTermMw(pInstMw)),
    p_reg_min_mw: pRegMinMw,
    p_reg_max_mw: pRegMaxMw,
    in_nomination: true,
  })

  const getNominationsForDevices = (): NominationDevice[] | [] => {
    const nominationsDevices: NominationDevice[] = []

    devicesForBps?.forEach((device) => {
      const newNomination =
        selectedDevices?.indexOf(device.id) !== -1
          ? createNominationDevice(device.id, device.p_reg_min_mw, device.p_reg_max_mw, device.p_inst_mw)
          : createEmptyNominationDevice(device.id)

      nominationsDevices.push(newNomination)
    })

    return nominationsDevices
  }

  const updateSliderValue = useCallback(
    (selectedDevicesNewValue: number[]) => {
      editProperties({value_pps: getCalculatedMaxValue(selectedDevicesNewValue, NOMINATION_VALUE_TYPE.Pps)})
    },
    [getCalculatedMaxValue],
  )

  const existingRecords = useMemo(() => {
    const overlappingRecords = profileNominationsWithDevices.filter((x) =>
      checkOverlapWithDevices(x, editedProfileNomination),
    )
    return mergeNominationsWithDevices(overlappingRecords)
  }, [profileNominationsWithDevices, editedProfileNomination])

  // set initial selected Kjgs and default value for PpS and OOM
  useEffect(() => {
    if (devicesForBps?.length && selectedDevices === undefined) {
      const newValue = devicesForBps.filter((item) => item.in_service).map((item) => item.id)
      setSelectedDevices(newValue)

      bpsInstMw.current =
        devicesForBps
          ?.filter((item) => item.in_service)
          .reduce(function (prev, current) {
            return prev + current.p_inst_mw
          }, 0) ?? 0

      updateSliderValue(newValue)
    } else {
      // set default value for PpS and OOM
      editProperties({
        value_pps: getCalculatedMaxValue(selectedDevices ? selectedDevices : [], NOMINATION_VALUE_TYPE.Pps),
        value_oom: getCalculatedMaxValue(selectedDevices ? selectedDevices : [], NOMINATION_VALUE_TYPE.Oom),
      })
    }
  }, [devicesForBps, selectedDevices, updateSliderValue, getCalculatedMaxValue])

  const handleUpdate = async (isOutOfRegLimit: boolean) => {
    setIsConfirmDisabled(true)
    if (isOutOfRegLimit) {
      editedProfileNomination.production_status = 3
    }

    if (selectedBpsId && selectedProfile?.id) {
      await runUpdateProfileV2({
        updateProfileWithDevicesRequest: {
          bps_id: selectedBpsId,
          profile_id: selectedProfile.id,
          time_from: dayjs(editedProfileNomination.time_from).format('HH:mm:ss'),
          time_to: dayjs(editedProfileNomination.time_to).format('HH:mm:ss'),
          value_oom: transformToMwNumber(editedProfileNomination.value_oom),
          value_pps: transformToMwNumber(editedProfileNomination.value_pps),
          note: editedProfileNomination.note,
          nomination_devices: getNominationsForDevices(),
          production_status: editedProfileNomination.production_status,
        },
      })
    }
    setIsConfirmUpdateProfileDialogOpen(false)
    onClose()
    fetchProfileNominations()
    setIsConfirmDisabled(false)
  }

  const handleConfirmUpdate = async () => {
    selectedDevices?.length === 0 ? handleUpdate(true) : handleUpdate(false)
  }

  const valueIsValid = () => {
    return (
      editedProfileNomination.value_pps != undefined &&
      editedProfileNomination.value_pps >= 0 &&
      editedProfileNomination.value_oom != undefined &&
      editedProfileNomination.value_oom >= 0
    )
  }

  const isSelected = (id: number | undefined) => {
    return selectedDevices?.find((item) => item === id) !== undefined
  }

  const nominationValueBorderColor = (nominationType: string) => {
    return nominationValueTextFieldBorderColor(
      nominationType,
      devicesForBps,
      selectedDevices,
      bpsParameters,
      editedProfileNomination,
    )
  }

  const handleClickCheckBox = (id: number | undefined, checked: boolean) => {
    if (id === undefined) {
      return
    }

    if (checked) {
      // add to array of selected
      const filtered = selectedDevices?.filter((item) => item !== id)
      const updated = filtered ?? []
      updated.push(id)
      setSelectedDevices(updated)
      updateSliderValue(updated)
    } else {
      // remove from array of selected
      const filtered = selectedDevices?.filter((item) => item !== id)
      setSelectedDevices(filtered)
      filtered ? updateSliderValue(filtered) : updateSliderValue([])
    }
  }

  const handleAllDaySelected = () => {
    setSelectedInterval(NominationInterval.AllDay)
    editProperties({
      time_from: startOfDayTimestamp.toISOString(),
      time_to: endOfDayTimestamp.toISOString(),
    })
  }

  const handleHoursIntervalSelected = () => {
    setSelectedInterval(NominationInterval.Hours)
    editProperties({
      time_from: startOfDayTimestamp.toISOString(),
      time_to: endOfDayTimestamp.toISOString(),
    })
  }

  const handleFifteenMinutesIntervalSelected = () => {
    setSelectedInterval(NominationInterval.FifteenMinutes)
    editProperties({
      time_from: startOfDayTimestamp.toISOString(),
      time_to: endOfDayTimestamp.toISOString(),
    })
  }

  const handleValueInPpsSliderChange = (valid: boolean) => {
    setEnablePpsSubmit(valid)
  }

  const handleValueInOomChange = (valid: boolean) => {
    setEnableOomSubmit(valid)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Upraviť profil: {selectedProfile?.name}</DialogTitle>
        <DialogContent>
          <Stack>
            <NominationDialogIntervalSelector
              handleEnterFaultSelection={() => false} // used only in add nomination
              handleAllDaySelected={handleAllDaySelected}
              handleFifteenMinutesIntervalSelected={handleFifteenMinutesIntervalSelected}
              handleHoursIntervalSelected={handleHoursIntervalSelected}
              selectedInterval={selectedInterval}
              selectedEnterFault={false} // used only in add nomination
              isFailureTime={false} // used only in add nomination
            />

            <NominationDialogPeriods
              editedNomination={editedProfileNomination}
              onEditProperties={editProperties}
              selectedInterval={selectedInterval}
              isProfileEdit={true}
            />

            <NominationDialogStatusSelector
              editedNomination={editedProfileNomination}
              productionStatusEnum={productionStatus}
              onValueChange={(value: number) => editProperties({production_status: value})}
            />

            <NominationDialogDevicesTable
              handleClickCheckBox={handleClickCheckBox}
              isSelected={isSelected}
              devicesForBps={devicesForBps}
            />

            <NominationDialogOom
              editedNomination={editedProfileNomination}
              minValueOom={
                nominationTechParamBands(
                  NOMINAITON_TECH_PARAMS_COLORS.Orange,
                  devicesForBps,
                  selectedDevices,
                  bpsParameters?.tvs_mw,
                  bpsParameters?.ovs_mw,
                  bpsParameters?.tvs_variance_mw,
                )?.min ?? 0
              }
              maxValueOom={
                nominationTechParamBands(
                  NOMINAITON_TECH_PARAMS_COLORS.Orange,
                  devicesForBps,
                  selectedDevices,
                  bpsParameters?.tvs_mw,
                  bpsParameters?.ovs_mw,
                  bpsParameters?.tvs_variance_mw,
                )?.max ?? 0
              }
              minSliderValueOom={getCalculatedMinValue(
                selectedDevices ? selectedDevices : [],
                NOMINATION_VALUE_TYPE.Oom,
              )}
              maxSliderValueOom={getCalculatedMaxValue(
                selectedDevices ? selectedDevices : [],
                NOMINATION_VALUE_TYPE.Oom,
              )}
              onEditProperties={editProperties}
              onValueChanged={handleValueInOomChange}
              nominationValueBorderColor={nominationValueBorderColor}
              existingNominations={existingRecords}
              isProfileEdit={true}
              bpsParameters={bpsParameters ?? undefined}
            />

            <NominationDialogPps
              editedNomination={editedProfileNomination}
              minValuePps={
                nominationTechParamBands(
                  NOMINAITON_TECH_PARAMS_COLORS.Orange,
                  devicesForBps,
                  selectedDevices,
                  bpsParameters?.tvs_mw,
                  bpsParameters?.ovs_mw,
                  bpsParameters?.tvs_variance_mw,
                )?.min ?? 0 + (bpsParameters?.ovs_mw ?? 0)
              }
              maxValuePps={
                nominationTechParamBands(
                  NOMINAITON_TECH_PARAMS_COLORS.Orange,
                  devicesForBps,
                  selectedDevices,
                  bpsParameters?.tvs_mw,
                  bpsParameters?.ovs_mw,
                  bpsParameters?.tvs_variance_mw,
                )?.max ?? 0 + (bpsParameters?.ovs_mw ?? 0)
              }
              mrcMw={bpsParameters?.max_reserved_capacity_mw ?? 0}
              onEditProperties={editProperties}
              onValueChanged={handleValueInPpsSliderChange}
              nominationValueBorderColor={nominationValueBorderColor}
            />

            <NominationDialogTechParams
              editedNomination={editedProfileNomination}
              onEditProperties={editProperties}
              tvsMw={bpsParameters?.tvs_mw}
              ovsMw={bpsParameters?.ovs_mw}
              tvsVarianceMw={bpsParameters?.tvs_variance_mw}
              devicesForBps={devicesForBps ?? []}
              selectedDevices={selectedDevices ?? []}
              selectedEnterFault={false} // used only in add nomination
              isFailureTime={false} // used only in add nomination
              handleSelectedCause={() => null} // used only in add nomination
              selectedCause={CauseOfOutage.Other} // used only in add nomination
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Zrušiť
          </Button>
          <Button
            onClick={() => setIsConfirmUpdateProfileDialogOpen(true)}
            variant="contained"
            disabled={!valueIsValid() || !enablePpsSubmit || !enableOomSubmit || isConfirmDisabled}
          >
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmUpdateProfileDialog
        open={isConfirmUpdateProfileDialogOpen}
        onClose={() => setIsConfirmUpdateProfileDialogOpen(false)}
        bpsNominationValuePps={editedProfileNomination.value_pps ? editedProfileNomination.value_pps : 0}
        bpsNominationValueOom={editedProfileNomination.value_oom ? editedProfileNomination.value_oom : 0}
        profileNomination={editedProfileNomination}
        onConfirm={handleConfirmUpdate}
        nominationDevices={getNominationsForDevices()}
        allDevices={devicesForBps}
        confirmButtonDisabled={isConfirmDisabled}
      />

      <NominationIsOutOfRegLimitDialog
        open={isNominationIsOutOfRegLimitDialogOpen}
        onClose={() => setIsNominationIsOutOfRegLimitDialogOpen(false)}
        onConfirm={() => handleUpdate(true)}
        confirmButtonDisabled={isConfirmDisabled}
      />
    </>
  )
}

import {Alert, Checkbox, FormControlLabel, Stack} from '@mui/material'
import React from 'react'
import {NominationInterval} from '../utils/enums'
import {useBucketStore} from '../utils/BucketStoreContext'

type NominationDialogIntervalSelectorProps = {
  selectedInterval: NominationInterval
  handleEnterFaultSelection: () => void
  handleFifteenMinutesIntervalSelected: () => void
  handleHoursIntervalSelected: () => void
  handleAllDaySelected: () => void
  isFailureTime: boolean
  selectedEnterFault: boolean
}

export const NominationDialogIntervalSelector: React.FC<NominationDialogIntervalSelectorProps> = ({
  selectedInterval,
  handleEnterFaultSelection,
  handleFifteenMinutesIntervalSelected,
  handleHoursIntervalSelected,
  handleAllDaySelected,
  isFailureTime,
  selectedEnterFault,
}) => {
  const {data: userInfo} = useBucketStore('userInfo')

  return (
    <>
      {isFailureTime && (
        <Alert
          icon={false}
          severity="error"
          sx={{
            padding: '0 16px',
            '& .MuiAlert-message': {padding: '0px'},
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={selectedEnterFault} onChange={handleEnterFaultSelection} />}
            label="Zadávanie poruchy"
            labelPlacement="end"
            disabled={!(userInfo?.roles.includes('GROUP_MNG') || userInfo?.roles.includes('GROUP_OPERATOR'))}
          />
        </Alert>
      )}

      <Stack direction="row" alignItems="flex-start" justifyContent="space-evenly">
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedInterval === NominationInterval.FifteenMinutes}
              onChange={handleFifteenMinutesIntervalSelected}
            />
          }
          label="15-min."
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Checkbox checked={selectedInterval === NominationInterval.Hours} onChange={handleHoursIntervalSelected} />
          }
          label="Hodiny"
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Checkbox checked={selectedInterval === NominationInterval.AllDay} onChange={handleAllDaySelected} />
          }
          label="Celý deň"
          labelPlacement="end"
        />
      </Stack>
    </>
  )
}

import React, {useCallback, useEffect, useState} from 'react'
import {NominationExtendedBase} from '../types/nominationExtended'
import {Alert, Box, Slider, TextField} from '@mui/material'
import {BpsParametersExtended, NominationProfile} from '../api/generated'
import {parseToFixedNumber, transformToKwNumber, validateNumericValue} from '../utils/common'
import {NOMINATION_VALUE_TYPE} from '../utils/constants'
import {getMinMaxOfNominations, getOvsKW} from '../utils/powerUnitControl'
import {useBucketStore} from '../utils/BucketStoreContext'
import {NominationFull} from '../types/nomination'

type NominationDialogOomProps = {
  editedNomination: NominationExtendedBase | NominationProfile
  minValueOom: number
  maxValueOom: number
  minSliderValueOom: number
  maxSliderValueOom: number
  onEditProperties: (changes: Partial<NominationExtendedBase>) => void
  onValueChanged: (valid: boolean) => void
  nominationValueBorderColor: (nominationType: string) => {color: string; isOutOfRange: boolean}
  existingNominations: NominationFull[] | NominationProfile[]
  isProfileEdit: boolean
  bpsParameters: BpsParametersExtended | undefined
}

export const NominationDialogOom: React.FC<NominationDialogOomProps> = ({
  editedNomination,
  minValueOom,
  maxValueOom,
  minSliderValueOom,
  maxSliderValueOom,
  onEditProperties,
  onValueChanged,
  nominationValueBorderColor,
  existingNominations,
  isProfileEdit,
  bpsParameters,
}) => {
  const {data: userInfo} = useBucketStore('userInfo')

  const minValidOomToUse =
    userInfo?.roles.includes('GROUP_OPERATOR') || userInfo?.roles.includes('GROUP_MNG') ? 0 : minValueOom
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(true)
  const maxValidOomToUse =
    userInfo?.roles.includes('GROUP_OPERATOR') || userInfo?.roles.includes('GROUP_MNG')
      ? transformToKwNumber(bpsParameters?.max_reserved_capacity_mw ?? 0) ?? 0
      : maxValueOom < minValidOomToUse
      ? minValidOomToUse
      : maxValueOom

  const valueIsValid = useCallback(() => {
    return (
      (editedNomination.value_oom ?? 0) >= minValidOomToUse && (editedNomination.value_oom ?? 0) <= maxValidOomToUse
    )
  }, [editedNomination.value_oom, minValidOomToUse, maxValidOomToUse])

  const getMinMaxForExistingNominations = () => {
    const oomMinMax = getMinMaxOfNominations(existingNominations, NOMINATION_VALUE_TYPE.Oom, isProfileEdit)
    const ppsMinMax = getMinMaxOfNominations(existingNominations, NOMINATION_VALUE_TYPE.Pps, isProfileEdit)

    if (!oomMinMax?.min || !oomMinMax?.max || !ppsMinMax?.min || !ppsMinMax?.max) {
      setIsAlertVisible(false)
      return null
    } else {
      return `Existújuce nominácie: OOM ${oomMinMax.min} - ${oomMinMax.max} kW, PpS  ${ppsMinMax.min} - ${ppsMinMax.max} kW`
    }
  }

  const getOutOfRangeText = nominationValueBorderColor(NOMINATION_VALUE_TYPE.Oom).isOutOfRange
    ? 'Zadaná nominácia je mimo pásiem'
    : ''

  const deltaInterval = parseToFixedNumber(transformToKwNumber(0.5 * (bpsParameters?.tvs_variance_mw ?? 0)) ?? 0, 1)
  const NOMINATION_VALUE_SLIDER_MARKS = [
    {
      value: minSliderValueOom,
      label: minSliderValueOom,
    },
    {
      value: minSliderValueOom + deltaInterval,
      label: minSliderValueOom + deltaInterval,
    },
    {
      value: minSliderValueOom + deltaInterval * 2,
      label: minSliderValueOom + deltaInterval * 2,
    },
    {
      value: maxSliderValueOom - deltaInterval * 2,
      label: maxSliderValueOom - deltaInterval * 2,
    },
    {
      value: maxSliderValueOom - deltaInterval,
      label: maxSliderValueOom - deltaInterval,
    },
    {
      value: maxSliderValueOom,
      label: maxSliderValueOom,
    },
  ]

  useEffect(() => {
    onValueChanged(valueIsValid())
  }, [editedNomination.value_oom, onValueChanged, valueIsValid, onEditProperties])

  return (
    <>
      <Box>
        <Slider
          value={editedNomination.value_oom ?? 0}
          step={1}
          min={minSliderValueOom}
          max={maxSliderValueOom}
          onChange={(event, value) => {
            if (validateNumericValue(value.toString())) {
              onEditProperties({
                value_oom: +value,
                value_pps: +value + getOvsKW(bpsParameters),
              })
            }
            onValueChanged(valueIsValid())
          }}
          sx={{
            color: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Oom).color,
            '& .MuiSlider-markLabel': {
              transform: 'rotate(-40deg)',
              transformOrigin: 'bottom left',
              fontSize: '12px',
            },
          }}
          marks={NOMINATION_VALUE_SLIDER_MARKS}
          valueLabelDisplay={'on'}
        />
      </Box>

      {isAlertVisible && (
        <Alert icon={false} severity="warning" sx={{fontSize: '8pt'}}>
          {getMinMaxForExistingNominations()}
        </Alert>
      )}

      <TextField
        FormHelperTextProps={{sx: {color: '#a60505'}}}
        margin="normal"
        label={'Nominácia pre OOM'}
        type="text"
        value={editedNomination.value_oom ?? 0}
        onChange={(e) => {
          if (validateNumericValue(e.target.value)) {
            onEditProperties({
              value_oom: +e.target.value,
              value_pps: +e.target.value + getOvsKW(bpsParameters),
            })
          }
          onValueChanged(valueIsValid())
        }}
        helperText={getOutOfRangeText}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Oom).color,
            },
            '&:hover fieldset': {
              borderColor: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Oom).color,
            },
            '&.Mui-focused fieldset': {
              borderColor: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Oom).color,
            },
          },
          '& .MuiInputLabel-root': {
            color: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Oom).color,
          },
          '& .Mui-focused .MuiInputLabel-root': {
            color: nominationValueBorderColor(NOMINATION_VALUE_TYPE.Oom).color,
          },
        }}
      />
    </>
  )
}

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {Button, TextField} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers'
import dayjs, {Dayjs} from 'dayjs'
import React, {useEffect, useState} from 'react'

type DatePickerProps = {
  value: string | undefined
  onChange: (date: string) => void
  showToolbar: boolean
}

export const DatePicker: React.FC<DatePickerProps> = ({value, onChange, showToolbar}) => {
  const [pickedDate, setPickedDate] = useState<string>(value ?? '')

  const changePickedDate = (newPickedDate: string) => {
    onChange(newPickedDate)
    setPickedDate(newPickedDate)
  }

  useEffect(() => {
    setPickedDate(value ?? '')
  }, [value])

  return (
    <>
      <Button
        variant="contained"
        sx={{padding: 0, minWidth: '48px'}}
        onClick={() => {
          changePickedDate(dayjs(pickedDate).subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss'))
        }}
      >
        <ArrowLeftIcon fontSize="large" />
      </Button>
      <MobileDatePicker<string, Dayjs>
        toolbarTitle=""
        value={pickedDate ?? ''}
        onChange={(newValue) => {
          if (newValue) {
            setPickedDate(newValue.format('YYYY-MM-DDTHH:mm:ss'))
          }
        }}
        onAccept={(newValue) => {
          if (newValue) {
            onChange(newValue.format('YYYY-MM-DDTHH:mm:ss'))
          }
        }}
        renderInput={(params) => <TextField size="small" {...params} />}
        componentsProps={{actionBar: {actions: ['today', 'cancel', 'accept']}}}
        showToolbar={showToolbar}
      />
      <Button
        variant="contained"
        sx={{padding: 0, minWidth: '48px'}}
        onClick={() => {
          changePickedDate(dayjs(pickedDate).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'))
        }}
      >
        <ArrowRightIcon fontSize="large" />
      </Button>
    </>
  )
}

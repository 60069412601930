import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import dayjs from 'dayjs'
import {Profile} from '../api/generated'
import React from 'react'

type UnsuccessfulUseProfileDialogProps = {
  open: boolean
  onClose: () => void
  dates: string[]
  profile: Profile | null
}

export const UnsuccessfulUseProfileDialog: React.FC<UnsuccessfulUseProfileDialogProps> = ({
  open,
  onClose,
  dates,
  profile,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Neúspešné použitie profilu</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>
            <p>
              Použitie profilu <strong>{profile?.name}</strong> na dni&nbsp;
            </p>
            <p>
              <strong>{dates.map((date) => dayjs(date).format('DD.MM.YYYY')).join(', ')}</strong>&nbsp;
            </p>
            <p>bolo neúspešné</p>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained">
          Zavrieť
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React from 'react'
import {formatDateAndYear, formatTime} from '../utils/format'

type RevokeNominationFailureDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  nominationsToBeRevoked: () => {timeFrom: string; timeTo: string} | undefined
  isNominationFailureNow: boolean
}

export const RevokeNominationFailureDialog: React.FC<RevokeNominationFailureDialogProps> = ({
  open,
  onClose,
  onConfirm,
  nominationsToBeRevoked,
  isNominationFailureNow,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Zrušenie poruchy</DialogTitle>
      <DialogContent>
        <Typography fontWeight={500}>Uporoznenie:</Typography>
        <Typography>
          {isNominationFailureNow
            ? `Porucha prebiehajúca ${formatDateAndYear(nominationsToBeRevoked()?.timeTo)} do ${formatTime(
                nominationsToBeRevoked()?.timeTo,
              )} bude zrušená a nahradená Odoslanými nomináciami.`
            : `Naplánovaná porucha ${formatDateAndYear(nominationsToBeRevoked()?.timeFrom)} ${formatTime(
                nominationsToBeRevoked()?.timeFrom,
              )} - ${formatTime(nominationsToBeRevoked()?.timeTo)} bude zrušená a nahradená Odoslanými nomináciami.`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zrušiť
        </Button>
        <Button onClick={onConfirm} variant="contained" color={'error'}>
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}

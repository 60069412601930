import React from 'react'
import {Device} from '../api/generated'
import {Checkbox, FormControlLabel, styled, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material'
import {transformToKwWithEndKw} from '../utils/common'

type NominationDialogDevicesTableProps = {
  devicesForBps: undefined | Device[]
  isSelected: (id: number | undefined) => boolean
  handleClickCheckBox: (id: number | undefined, checked: boolean) => void
}

const StyledTableCell = styled(TableCell)(() => ({
  width: '55%',
}))

const StyledTableRow = styled(TableRow)(() => ({
  '& .MuiTableCell-root': {
    padding: '0px 20px',
  },
}))

export const NominationDialogDevicesTable: React.FC<NominationDialogDevicesTableProps> = ({
  devicesForBps,
  isSelected,
  handleClickCheckBox,
}) => {
  return (
    <>
      <Typography variant="caption">Zariadenia v prevádzke</Typography>

      <Table size="small" sx={{padding: '20px 0'}}>
        <TableBody>
          {devicesForBps
            ?.sort((a, b) => {
              const nameA = a.name ?? ''
              const nameB = b.name ?? ''
              return nameA.localeCompare(nameB)
            })
            .map((device, deviceIndex) => {
              return (
                <StyledTableRow key={device.id}>
                  <TableCell key={devicesForBps?.at(deviceIndex)?.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={isSelected(devicesForBps?.at(deviceIndex)?.id)}
                          onChange={(_, checked) => {
                            handleClickCheckBox(devicesForBps?.at(deviceIndex)?.id, checked)
                          }}
                          disabled={!devicesForBps?.at(deviceIndex)?.in_service}
                        />
                      }
                      label={devicesForBps?.at(deviceIndex)?.name}
                      labelPlacement="end"
                      key={devicesForBps?.at(deviceIndex)?.id}
                    />
                    {!isSelected(devicesForBps?.at(deviceIndex)?.id) && (
                      <Typography variant="caption">Nevyrába</Typography>
                    )}
                  </TableCell>
                  <StyledTableCell>
                    <Typography variant="caption">
                      {transformToKwWithEndKw(device.p_reg_min_mw)} - {transformToKwWithEndKw(device.p_reg_max_mw)}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
        </TableBody>
      </Table>
    </>
  )
}
